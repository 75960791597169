
export const festivalYinList = [
    { date: "01-01", name: "Tahun Baru", name1: '元旦' },
    { date: "01-27", name: "Isra Mikraj Nabi Muhammad", name1: '登宵节' },
    { date: "01-28", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "01-29", name: "Tahun Baru Imlek ", name1: '衣历新年/春节' },
    { date: "03-28", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "03-29", name: "Hari Raya Nyepi  ", name1: '兴都教静居日' },
    { date: "03-31", name: "Hari Raya Idul Fitri  ", name1: '开斋节' },
    { date: "04-02", name: "Cuti Bersama ", name1: '共同假期' },
    { date: "04-18", name: "Jumat Agung   ", name1: '耶稣受难日' },
    { date: "04-20", name: "Paskah   ", name1: '复活节' },
    { date: "05-01", name: "Hari Buruh   ", name1: '劳动节' },
    { date: "05-12", name: "Hari Raya Waisak      ", name1: '卫塞节' },
    { date: "05-13", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "05-29", name: "Kenaikan Yesus Kristus ", name1: '耶稣基督升天日' },
    { date: "05-30", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "06-01", name: "Hari Lahir Pancasila   ", name1: '建国五基诞生日' },
    { date: "06-06", name: "Hari Raya Idul Adha  ", name1: '宰牲节' },
    { date: "06-09", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "06-27", name: "Tahun Baru Islam   ", name1: '伊斯兰新年' },
    { date: "08-17", name: "Hari Kemerdekaan  ", name1: '国庆日' },
    { date: "09-05", name: "Maulid Nabi Muhammad   ", name1: '先知穆罕默德诞辰' },
    { date: "12-25", name: "Hari Natal ", name1: '圣诞节' },
    { date: "12-26", name: "Cuti Bersama", name1: '共同假期' }
]
export const festivalYinListCurrent = [
    { date: "01-01", name: "Tahun Baru", name1: '元旦' },
    { date: "01-27", name: "Isra Mikraj Nabi Muhammad", name1: '登宵节' },
    { date: "01-28", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "01-29", name: "Tahun Baru Imlek ", name1: '衣历新年/春节' },
    { date: "03-28", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "03-29", name: "Hari Raya Nyepi  ", name1: '兴都教静居日' },
    { date: "03-31", name: "Hari Raya Idul Fitri  ", name1: '开斋节' },
    { date: "04-01", name: "Hari Raya Idul Fitri  ", name1: '开斋节' },
    { date: "04-02", name: "Cuti Bersama ", name1: '共同假期' },
    { date: "04-03", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "04-04", name: "Cuti Bersama ", name1: '共同假期' },
    { date: "04-05", name: "Cuti Bersama ", name1: '共同假期' },
    { date: "04-06", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "04-07", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "04-18", name: "Jumat Agung   ", name1: '耶稣受难日' },
    { date: "04-20", name: "Paskah   ", name1: '复活节' },
    { date: "05-01", name: "Hari Buruh   ", name1: '劳动节' },
    { date: "05-12", name: "Hari Raya Waisak      ", name1: '卫塞节' },
    { date: "05-13", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "05-29", name: "Kenaikan Yesus Kristus ", name1: '耶稣基督升天日' },
    { date: "05-30", name: "Cuti Bersama  ", name1: '共同假期' },
    { date: "06-01", name: "Hari Lahir Pancasila   ", name1: '建国五基诞生日' },
    { date: "06-06", name: "Hari Raya Idul Adha  ", name1: '宰牲节' },
    { date: "06-09", name: "Cuti Bersama   ", name1: '共同假期' },
    { date: "06-27", name: "Tahun Baru Islam   ", name1: '伊斯兰新年' },
    { date: "08-17", name: "Hari Kemerdekaan  ", name1: '国庆日' },
    { date: "09-05", name: "Maulid Nabi Muhammad   ", name1: '先知穆罕默德诞辰' },
    { date: "12-25", name: "Hari Natal ", name1: '圣诞节' },
    { date: "12-26", name: "Cuti Bersama", name1: '共同假期' }
]
export const festivalXiList = [
    { date: "01-01", name: "Año Nuevo", name1: '元旦' },
    { date: "01-06", name: "Epifanía", name1: '主显节' },
    { date: "04-18", name: "Viernes Santo", name1: '耶稣受难日' },
    { date: "05-01", name: "Día del Trabajo", name1: '劳动节' },
    { date: "08-15", name: "Asunción de María", name1: '圣母升天节' },
    { date: "10-12", name: "Día de la Hispanidad", name1: '国庆节' },
    { date: "11-01", name: "Día de Todos los Santos", name1: '诸圣日' },
    { date: "12-06", name: "Día de la Constitución", name1: '宪法日' },
    { date: "12-08", name: "Inmaculada Concepción", name1: '圣母无染原罪节' },
    { date: "12-25", name: "Día de Navidad", name1: '圣诞节' }
]
export const starList1 = {
    "白羊座": { start: "03-21", end: "04-19" },
    "金牛座": { start: "04-20", end: "05-20" },
    "双子座": { start: "05-21", end: "06-21" },
    "巨蟹座": { start: "06-22", end: "07-22" },
    "狮子座": { start: "07-23", end: "08-22" },
    "处女座": { start: "08-23", end: "09-22" },
    "天秤座": { start: "09-23", end: "10-23" },
    "天蝎座": { start: "10-24", end: "11-22" },
    "射手座": { start: "11-23", end: "12-21" },
    "摩羯座1": { start: "12-22", end: "12-31" },
    "摩羯座2": { start: "01-01", end: "01-19" },
    "水瓶座": { start: "01-20", end: "02-18" },
    "双鱼座": { start: "02-19", end: "03-20" }
};
export const starList = [
    { start: "03-21", end: "04-19", nameYin: "Aries", nameXi: "Aries", nameEn: "Aries", img: "Aries.png" },
    { start: "04-20", end: "05-20", nameYin: "Taurus", nameXi: "Tauro", nameEn: "Taurus", img: "Taurus.png" },
    { start: "05-21", end: "06-21", nameYin: "Gemini", nameXi: "Géminis", nameEn: "Gemini", img: "Gemini.png" },
    { start: "06-22", end: "07-22", nameYin: "Kanker", nameXi: "Cáncer", nameEn: "Cancer", img: "Kanker.png" },
    { start: "07-23", end: "08-22", nameYin: "Leo", nameXi: "Leo", nameEn: "Leo", img: "Leo.png" },
    { start: "08-23", end: "09-22", nameYin: "Virgo", nameXi: "Virgo", nameEn: "Virgo", img: "Virgo.png" },
    { start: "09-23", end: "10-23", nameYin: "Libra", nameXi: "Escorpio", nameEn: "Libra", img: "Libra.png" },
    { start: "10-24", end: "11-22", nameYin: "Scorpio", nameXi: "Escorpión", nameEn: "Scorpio", img: "Scorpio.png" },
    { start: "11-23", end: "12-21", nameYin: "Sagittarius", nameXi: "Sagitario", nameEn: "Sagittarius", img: "Sagittarius.png" },
    { start: "12-22", end: "12-31", nameYin: "Capricorn1", nameXi: "Capricornio1", nameEn: "Capricorn1", img: "Capricorn.png" },
    { start: "01-01", end: "01-19", nameYin: "Capricorn2", nameXi: "Capricornio2", nameEn: "Capricorn2", img: "Capricorn.png" },
    { start: "01-20", end: "02-18", nameYin: "Aquarius", nameXi: "Acuario", nameEn: "Aquarius", img: "Aquarius.png" },
    { start: "02-19", end: "03-20", nameYin: "Pisces", nameXi: "Piscis", nameEn: "Pisces", img: "Pisces.png" },
]
export const dateXiList = {
    "2023-01-01": {
        "content": "Suspiró y después sonrió con la misma sonrisa que, sin importar cuántas veces la viese, siempre le robaba el corazón. Cada vez que sonreía volvía a ser la primera vez. –Neil Gaiman\t"
    },
    "2023-01-02": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2023-01-03": {
        "content": " El secreto para disfrutar el trabajo esta contenido en una palabra –excelencia. Saber cómo hacer algo bien es disfrutarlo. –Pearl S. Buck.\t"
    },
    "2023-01-04": {
        "content": "El hombre está condenado a ser libre –Jean  Paul Sartre.\t"
    },
    "2023-01-05": {
        "content": " Alguien que elige sonreír antes que enfadarse, es una persona realmente fuerte –David Schary\t"
    },
    "2023-01-06": {
        "content": "La mayoría de las grandes hazañas logradas por el hombre fueron consideradas imposibles antes de que alguien las llevara a cabo”. –Louis D. Brandeis.\t"
    },
    "2023-01-07": {
        "content": " El cambio está en el aire. Nos recuerda que fuimos esculpidos por el mismo poder que orquesta el cambio de las estaciones. Que esta sea la estación en la que aceptes el cambio y te alinees con él. –Steve Maraboli\t"
    },
    "2023-01-08": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2023-01-09": {
        "content": "Si un hombre sonríe todo el tiempo, probablemente vende algo que no funciona. –George Carlin\t"
    },
    "2023-01-10": {
        "content": " El futuro pertenece a los que creen en la belleza de sus sueños. –Eleanor Roosevelt\t"
    },
    "2023-01-11": {
        "content": "Nunca te rindas. Establece una meta y no te rindas hasta que la consigas. Cuando la consigas, establece otro objetivo y no te rindas hasta que lo alcances. Nunca te rindas. –Bear Bryant\t"
    },
    "2023-01-12": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2023-01-13": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2023-01-14": {
        "content": "No puedo darte una fórmula segura para el éxito, pero puedo darte una para el fracaso: intenta complacer a todo el mundo todo el tiempo. –Herbert Bayard Swope\t"
    },
    "2023-01-15": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2023-01-16": {
        "content": "El corazón alegre hermosea el rostro. –Rey Salomón\t"
    },
    "2023-01-17": {
        "content": "Deja de mentirte. Cuando negamos nuestra verdad, negamos nuestro verdadero potencial. –Steve Maraboli\t"
    },
    "2023-01-18": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2023-01-19": {
        "content": "No quiero dejar de sonreír, bajo ninguna circunstancia, ni perder la libertad para pensar, y quiero enfrentarme a cualquier dificultad con valentía. –Banana Yashimoto\t"
    },
    "2023-01-20": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2023-01-21": {
        "content": " El éxito es un mal maestro. –Robert T. Kiyosaki\t"
    },
    "2023-01-22": {
        "content": " No hay razón para tener un plan B, porque te distrae del plan A. –Will Smith\t"
    },
    "2023-01-23": {
        "content": "Eres mi sol nocturno, mi luna de día, mi mejor sonrisa. –Federico Moccia\t"
    },
    "2023-01-24": {
        "content": "La muerte nos sonríe a todos, devolvámosle la sonrisa. –Gladiator\t"
    },
    "2023-01-25": {
        "content": "Me sonrió como no podría hacerlo un hombre, como si las mujeres lo supieran todo unas de las otras desde la eternidad. –Marcela serrano\t"
    },
    "2023-01-26": {
        "content": "La lógica te llevará de la A a la Z, pero la imaginación te llevará a cualquier lugar”. –Albert Einstein\t"
    },
    "2023-01-27": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2023-01-28": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2023-01-29": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2023-01-30": {
        "content": " No importa lo bueno que seas, siempre puedes ser mejor, esa es la parte emocionante. –Tiger Woods\t"
    },
    "2023-01-31": {
        "content": " El secreto del éxito es mantener una imagen de un resultado exitoso en la mente. –Henry David Thoreau\t"
    },
    "2023-02-01": {
        "content": "No dejes de sonreír… Porque es muy poco el tiempo que te dan para la alegría –Zenaida Bacardí de Argamasilla\t"
    },
    "2023-02-02": {
        "content": "Estaba sonriendo ayer, estoy sonriendo hoy y sonreiré mañana. Simplemente porque la vida es muy corta para llorar por cualquier cosa. –Santosh Kalwar\t"
    },
    "2023-02-03": {
        "content": " Son necesarios cuarenta músculos para arrugar una frente, pero sólo quince para sonreír –Swami Sivananda\t"
    },
    "2023-02-04": {
        "content": "Sonreír es definitivamente una de los mejores y más bellos remedios. Si tienes un buen sentido de humor y un buen enfoque hacia la vida, eso es hermoso –Rashida Jones\t"
    },
    "2023-02-05": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2023-02-06": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2023-02-07": {
        "content": "Y luego me da una sonrisa que parece tan genuinamente dulce con el toque justo de timidez que el calor inesperado corre a través de mí. –Suzanne Collins\t"
    },
    "2023-02-08": {
        "content": "Tenía los labios inmóviles, pero cuando lo miré parecieron sonreír sin hacer el más mínimo movimiento. –Anne Rice\t"
    },
    "2023-02-09": {
        "content": "El secreto de la existencia humana no solo está en vivir, sino también en saber para qué se vive –Fiódor Dostoievski\t"
    },
    "2023-02-10": {
        "content": "Pienso, luego existo –René Descartes.\t"
    },
    "2023-02-11": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2023-02-12": {
        "content": "Si dicen mal de ti con fundamento, corrígete; de lo contrario, échate a reír. –Epicteto de Frigia\t"
    },
    "2023-02-13": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2023-02-14": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2023-02-15": {
        "content": " Nunca dejes de sonreír, ni siquiera cuando estés triste, porque nunca sabes quien se puede enamorar de tu sonrisa –Gabriel García Márquez\t"
    },
    "2023-02-16": {
        "content": "Las limitaciones solo habitan en nuestra mente. Pero si usamos nuestra imaginación, nuestras posibilidades son ilimitadas –Jamie Paolinetti.\t"
    },
    "2023-02-17": {
        "content": " Nunca te sorprendas por tu éxito. –Steve Maraboli\t"
    },
    "2023-02-18": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2023-02-19": {
        "content": "Si estás leyendo esto… Felicitaciones, estás vivo. Si eso no es algo para sonreír, entonces no sé qué es. –Chad Sugg\t"
    },
    "2023-02-20": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2023-02-21": {
        "content": "La sonrisa es la llave que abre el corazón de todo el mundo. –Anthony J. D’Angelo\t"
    },
    "2023-02-22": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2023-02-23": {
        "content": "De humanos es errar y de necios permanecer en el error –Marco Tulio Cicerón\t"
    },
    "2023-02-24": {
        "content": "Hay un solo rincón del universo que puedes estar seguro de poder mejorar, y eres tú mismo –Aldous Huxley.\t"
    },
    "2023-02-25": {
        "content": " No dejes que los bloqueos mentales te controlen. Libérate. Enfrenta tus miedos y transforma los bloqueos mentales en bloques de construcción. –Roopleen\t"
    },
    "2023-02-26": {
        "content": "No se trata de si te derriban, se trata de si te levantas –Vince Lombardi.\t"
    },
    "2023-02-27": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2023-02-28": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2023-03-01": {
        "content": "El único hombre que nunca comete un error es el hombre que nunca hace nada. –Theodore Roosevelt\t"
    },
    "2023-03-02": {
        "content": "El éxito y la felicidad dependen de ti. –Helen Keller\t"
    },
    "2023-03-03": {
        "content": "A veces, cuando innovas, cometes errores. Lo mejor es admitirlos rápidamente y seguir mejorando otras innovaciones. –Steve Jobs\t"
    },
    "2023-03-04": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2023-03-05": {
        "content": " Es mejor olvidarse y sonreír que recordar y entristecerse –Cristina Rossetti\t"
    },
    "2023-03-06": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2023-03-07": {
        "content": " Prende tu sonrisa, ríe sin razón. Tú controlas el interruptor de la felicidad, eres tú quien cambia el mundo –Roel van Sleeuwen\t"
    },
    "2023-03-08": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2023-03-09": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2023-03-10": {
        "content": "El no querer es la causa, el no poder el pretexto –Seneca.\t"
    },
    "2023-03-11": {
        "content": "«No importa cuántas veces te caigas, sino cuántas veces te levantes». –Vince Lombardi\t"
    },
    "2023-03-12": {
        "content": "Por una mirada, un mundo –por una sonrisa, un cielo –por un beso… yo no sé –qué te diera por un beso. –Gustavo Adolfo Bécquer\t"
    },
    "2023-03-13": {
        "content": "Tu trabajo va a llenar una gran parte de tu vida; la única manera de estar verdaderamente satisfecho es hacer lo que crees que es un gran trabajo y la única manera de hacer un gran trabajo es amar lo que haces. Si todavía no lo has encontrado, sigue buscando. No te conformes. Como con todos los asuntos del corazón, sabrás cuando lo encuentres. –Steve Jobs\t"
    },
    "2023-03-14": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2023-03-15": {
        "content": "Quienes no saben llorar con todo el corazón, tampoco saben sonreír. –Golda Meir\t"
    },
    "2023-03-16": {
        "content": " La única batalla real en la vida es la que se da entre elegir aferrarse o dejar que se vaya. –Shannon L. Alder\t"
    },
    "2023-03-17": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2023-03-18": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2023-03-19": {
        "content": "Su sonrisa podía parar el corazón de un hombre. –El nombre del viento\t"
    },
    "2023-03-20": {
        "content": "No importa si te derriban, lo importante es si te levantas cuando sucede”. –Vince Lombardi\t"
    },
    "2023-03-21": {
        "content": " Sin importar lo fácil o difícil que sea la vida, siempre encontraré la manera de disfrutar. Incluso cuando las circunstancias sean adversas. –Termitope Owosela\t"
    },
    "2023-03-22": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2023-03-23": {
        "content": " La risa cura, es la obra social más barata y efectiva del mundo –Roberto Pettinato\t"
    },
    "2023-03-24": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2023-03-25": {
        "content": " Empieza cada día con una sonrisa y mantenla todo el día –W. C. Fields\t"
    },
    "2023-03-26": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2023-03-27": {
        "content": "La curva mas hermosa de una mujer es su sonrisa. –Bob Marley\t"
    },
    "2023-03-28": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2023-03-29": {
        "content": "Una gran sonrisa es un bello rostro de gigante –Charles Baudelaire\t"
    },
    "2023-03-30": {
        "content": "Una sonrisa es la felicidad que encontrarás justo debajo te tu nariz. –Tom Wilson\t"
    },
    "2023-03-31": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2023-04-01": {
        "content": "Creo que podría hacerme sonreír aunque estuviera colgada de una horca. –Jandy Nelson\t"
    },
    "2023-04-02": {
        "content": "La vida se encoge o se expande en proporción al coraje de uno –Anais Nin.\t"
    },
    "2023-04-03": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2023-04-04": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2023-04-05": {
        "content": "No llores porque ya se terminó, sonríe porque sucedió. –Gabriel García Marquez\t"
    },
    "2023-04-06": {
        "content": "La voluntad de ganar, el deseo de triunfar, el ímpetu de alcanzar tu pleno potencial…estas son las llaves que abrirán la puerta a la excelencia personal. –Confucio\t"
    },
    "2023-04-07": {
        "content": "Estar preparado es importante, saber esperarlo es aún más, pero aprovechar el momento adecuado es la clave de la vida –Arthur Schnitzler.\t"
    },
    "2023-04-08": {
        "content": "Esa sonrisa me ha salvado de llantos y dolores. –Salvatore Quasimodo\t"
    },
    "2023-04-09": {
        "content": "No puedo mantenerme enojado cuando me sonríes. –M.F. Moonzajer\t"
    },
    "2023-04-10": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2023-04-11": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2023-04-12": {
        "content": "Rodéate de personas que crean en tus sueños, alienten tus ideas, apoyen tus ambiciones y saquen lo mejor de ti”. –Roy T. Bennett\t"
    },
    "2023-04-13": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2023-04-14": {
        "content": "Los seres humanos no nacen para siempre el día en que sus madres los alumbran, sino que la vida los obliga a parirse a sí mismos una y otra vez –Gabriel García Márquez.\t"
    },
    "2023-04-15": {
        "content": "Sabes lo que es realmente contagioso? Una gran sonrisa. –Scrubs\t"
    },
    "2023-04-16": {
        "content": "Comienza y termina el día con una sonrisa. –W.C. Fields\t"
    },
    "2023-04-17": {
        "content": "«El éxito es la capacidad de ir de fracaso en fracaso sin perder el entusiasmo.» –Winston Churchill.\t"
    },
    "2023-04-18": {
        "content": "No cuentes los días, haz que los días cuenten –Muhammad Ali.\t"
    },
    "2023-04-19": {
        "content": "La sonrisa es el trapo que limpia las telarañas del corazón. –Mort Walker\t"
    },
    "2023-04-20": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2023-04-21": {
        "content": "Permanece firme a tus principios, pero siempre abierto a aprender. Trabaja duro, nunca renuncies a tus sueños, incluso cuando nadie crea que puedan hacerse realidad excepto tu. Estos no son clichés, sino que son herramientas auténticas que necesitas para estar concentrado en tu trayectoria, no importa a lo que te dediques en tu vida. –Phillip Sweet\t"
    },
    "2023-04-22": {
        "content": "Sin sonrisa de mujer no hay gloria completa de hombre. –José Marti\t"
    },
    "2023-04-23": {
        "content": "Las guerras seguirán mientras el color de la piel siga siendo más importante que el de los ojos –Bob Marley.\t"
    },
    "2023-04-24": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2023-04-25": {
        "content": "El primer paso es el más importante. Es el más crucial y más efectivo, ya que iniciará la dirección que has elegido. –Steve Backley\t"
    },
    "2023-04-26": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2023-04-27": {
        "content": "La forma de empezar es dejar de hablar y empezar a actuar   –Walt Disney\t"
    },
    "2023-04-28": {
        "content": "«El único modo de hacer un gran trabajo es amar lo que haces». –Steve Jobs\t"
    },
    "2023-04-29": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2023-04-30": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2023-05-01": {
        "content": "El hombre que nos hace reír tiene mas votos para su propósito que el hombre que nos exige pensar. –Malcom de Chazall\t"
    },
    "2023-05-02": {
        "content": "Si la vida no te diera al menos una persona que no quiera que lo logres, entonces la mitad de nosotros perdería la motivación de escalar el acantilado sólo para probar que se equivocan. –Shannon L. Alder\t"
    },
    "2023-05-03": {
        "content": " No le temas al fracaso. Es el camino al éxito. –LeBron James\t"
    },
    "2023-05-04": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2023-05-05": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2023-05-06": {
        "content": "Es maravilloso que nadie tenga que esperar ni un segundo para comenzar a hacer del mundo un lugar mejor”. –Ana Frank\t"
    },
    "2023-05-07": {
        "content": " El día peor empleado es aquél en que no se ha reído –Nicolás  Sebastien Roch Chamfort\t"
    },
    "2023-05-08": {
        "content": " Lo que hoy ves como fracaso puede ser un paso crucial hacia el éxito que estás buscando. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2023-05-09": {
        "content": "Hacer sonreír a una persona significa para mi más que cualquier cosa. –Michael Jackson\t"
    },
    "2023-05-10": {
        "content": "¿Cuándo regresará esa sonrisa que me desnuda en cuerpo y alma? –Jenniley Bonilla\t"
    },
    "2023-05-11": {
        "content": "«La vida no se trata de esperar a que pase la tormenta, se trata de aprender a bailar bajo la lluvia». –Vivian Greene\t"
    },
    "2023-05-12": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2023-05-13": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2023-05-14": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2023-05-15": {
        "content": "Nunca permitiré que me vuelva tan importante, sabio, reservado y poderoso, que no pueda sonreír a los demás y reírme de mí mismo. –Og Mandino\t"
    },
    "2023-05-16": {
        "content": "«El éxito no es para los que tienen fuerzas, sino para aquellos que no se rinden.» –Alexandre Dumas.\t"
    },
    "2023-05-17": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2023-05-18": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2023-05-19": {
        "content": "La raza humana tiene un arma verdaderamente eficaz: la risa. –Mark Twain\t"
    },
    "2023-05-20": {
        "content": "Sé amable, ya que cada persona que conoces está enfrentando una batalla interna”. –Platón\t"
    },
    "2023-05-21": {
        "content": "Los colores son la sonrisa de la naturaleza. –Leigh Hunt\t"
    },
    "2023-05-22": {
        "content": " Cada sonrisa te hace un día más joven  –Proverbio chino\t"
    },
    "2023-05-23": {
        "content": "El momento en el que consideras rendirte es justo el momento en el que debes seguir adelante”. –Anónimo\t"
    },
    "2023-05-24": {
        "content": "Para tener éxito hoy, tienes que fijar prioridades, decidir lo que representas. –Lee Laccoca\t"
    },
    "2023-05-25": {
        "content": "«El fracaso no es caer, sino negarse a levantarse». –Anónimo\t"
    },
    "2023-05-26": {
        "content": "Opino que lo que se llama belleza, reside únicamente en la sonrisa. –León Tolstoi\t"
    },
    "2023-05-27": {
        "content": "Tomar responsabilidad personal es algo hermoso porque nos da un control completo sobre nuestro destino. –Heather Schuck\t"
    },
    "2023-05-28": {
        "content": "La definición del propósito es el punto de comienzo de todo logro –Clement Stone.\t"
    },
    "2023-05-29": {
        "content": "Si no usas tu sonrisa, eres como un hombre con un millón de dolares en el banco y sin poder sacarlos. –Les Giblin\t"
    },
    "2023-05-30": {
        "content": "Hay tres tipos de personas en este mundo. Primero, están las que hacen que ocurran las cosas. Luego están las que ven cómo ocurren las cosas. Por último, están las que preguntan, ¿qué ha ocurrido? ¿Cuál quieres ser tú? –Steve Backley\t"
    },
    "2023-05-31": {
        "content": "Para ser exitoso hay que seguir el camino de los gigantes. –Lillian Cauldwell\t"
    },
    "2023-06-01": {
        "content": "Quítame el pan, si quieres, quítame el aire, pero no me quites tu risa. –Pablo Neruda\t"
    },
    "2023-06-02": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2023-06-03": {
        "content": "La risa es el lenguaje del alma. –Pablo Neruda\t"
    },
    "2023-06-04": {
        "content": "Haz que se pregunten por qué sigues sonriendo. –Elizabethtown\t"
    },
    "2023-06-05": {
        "content": "Ve definitivamente en dirección de tus sueños. Vive la vida que imaginaste tener –Henry David Thoreau.\t"
    },
    "2023-06-06": {
        "content": "Elimina de tu vida todo aquello que te cause estrés y te quite la sonrisa. –Paulo Coelho\t"
    },
    "2023-06-07": {
        "content": "Una sonrisa puede iluminar los lugares más oscuros. –Steve Maraboli\t"
    },
    "2023-06-08": {
        "content": " El éxito es hacer lo que sea que quieras hacer, cuando quieras, donde quieras, con quien quieras y por el tiempo que quieras. –Anthony Robbins\t"
    },
    "2023-06-09": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2023-06-10": {
        "content": " Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs\t"
    },
    "2023-06-11": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2023-06-12": {
        "content": "Aunque sentí que estaba a punto de llorar, opté por sonreír. –Kristen Ashley\t"
    },
    "2023-06-13": {
        "content": "Hazles comprender que no tienen en el mundo otro deber que la alegría. –Paul Claudel\t"
    },
    "2023-06-14": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2023-06-15": {
        "content": " Hoy es el único día sobre el que tenemos poder. –Steve Maraboli\t"
    },
    "2023-06-16": {
        "content": "Cambia tus pensamientos y cambiarás tu mundo –Norman Vincent Peale.\t"
    },
    "2023-06-17": {
        "content": "Mi padre siempre me decía: encuentra un trabajo que te guste y no tendrás que trabajar un solo día de tu vida. –Jim Fox.\t"
    },
    "2023-06-18": {
        "content": "Eres asombroso porque estás aquí vivo, luchas cada día para mantenerte de pie. ¿Por qué sufres si tienes la solución en tus manos? ¿Por qué lloras si puedes cambiarlo todo? La vida es hermosa porque existes tú”. –Frases sobre la vida\t"
    },
    "2023-06-19": {
        "content": "No importa lo lento que vayas mientras que no pares –Confucio.\t"
    },
    "2023-06-20": {
        "content": "Espero tu sonrisa y espero tu fragancia por encima de todo, del tiempo y la distancia. –José Angel Buesa\t"
    },
    "2023-06-21": {
        "content": "La forma de arruinar la vida de alguien es darle todo lo que quiere. –Patrick Swayze\t"
    },
    "2023-06-22": {
        "content": "Nunca eres demasiado mayor para establecer nuevas metas o tener nuevos sueños”. –C.S. Lewis\t"
    },
    "2023-06-23": {
        "content": " El éxito no es comparar lo que hemos hecho con lo que los demás han hecho. –Myles Munroe\t"
    },
    "2023-06-24": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2023-06-25": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2023-06-26": {
        "content": "Mantente alejado de la gente que intenta menospreciar tus ambiciones. La gente pequeña siempre hace eso, pero la gente realmente grande te hace sentir que tu también puedes ser grande –Mark Twain.\t"
    },
    "2023-06-27": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2023-06-28": {
        "content": "«La vida nos somete a pruebas para mostrarnos que somos más fuertes de lo que imaginamos en nuestros peores momentos». –Las mejores frases de la vida es dura\t"
    },
    "2023-06-29": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2023-06-30": {
        "content": "Todos deberíamos saber todo el bien que una simple sonrisa puede hacer. –Madre Teresa de Calcuta\t"
    },
    "2023-07-01": {
        "content": " Ríe y el mundo reirá contigo; llora y llorarás sólo –Eli Wilcox\t"
    },
    "2023-07-02": {
        "content": "El niño reconoce a la madre por la sonrisa. –Leon Tolstoi\t"
    },
    "2023-07-03": {
        "content": "Si un líder no transmite pasión e intensidad, entonces no habrá pasión e intensidad dentro de la organización y empezarán a caer y deprimirse. –Colin Powell\t"
    },
    "2023-07-04": {
        "content": "Puede que seas la única persona que crea en ti, pero eso es suficiente. Sólo se necesita una estrella para perforar la oscuridad del universo. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2023-07-05": {
        "content": " Confía en la fuerza de tu cuerpo y corazón. Elige por estrella la autosuficiencia, la fe, la honestidad y la industria. No tomes demasiados consejos, mantén el timón y dirige tu propio barco, y recuerda que el gran arte de mandar es tomar una participación equitativa del trabajo. Pon fuego sobre la marca que quieres golpear. La energía y la determinación con el motivo correctos son las palancas que mueven al mundo. –Noah Porter.\t"
    },
    "2023-07-06": {
        "content": "La sonrisa es el idioma general de los hombres inteligentes. Sólo son tristes los tontos y los delincuentes. –Víctor Ruiz Iriarte\t"
    },
    "2023-07-07": {
        "content": " La sonrisa enriquece a los que la reciben, sin empobrecer a los que la dan –Frank Irving\t"
    },
    "2023-07-08": {
        "content": "Sólo hay un éxito: ser capaz de pasar tu vida a tu manera. –Christopher Morley\t"
    },
    "2023-07-09": {
        "content": "Consulta no tus miedos sino tus esperanzas y tus sueños. No pienses en tus frustraciones, sino en tu potencial incumplido. Preocúpate no en lo que has intentado y fracasado, sino en lo que todavía es posible hacer y lograr. –Juan XXIII\t"
    },
    "2023-07-10": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2023-07-11": {
        "content": "Comienza donde estás, usa lo que tienes, haz lo que puedes –Arthur Ashe.\t"
    },
    "2023-07-12": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2023-07-13": {
        "content": "Si te pones a ti mismo en una posición en la que tienes que salir de tu zona de confort, estas forzando la expansión de tu consciencia. –Les Brown\t"
    },
    "2023-07-14": {
        "content": "Si es bueno vivir, todavía es mejor soñar, y lo mejor de todo, despertar –Antonio Machado.\t"
    },
    "2023-07-15": {
        "content": "«En medio de la dificultad yace la oportunidad». –Albert Einstein\t"
    },
    "2023-07-16": {
        "content": "Hoy llevo puesta la sonrisa que me regalaste. –Edith Castro\t"
    },
    "2023-07-17": {
        "content": "Sonreír en la presencia del peligro no significa que niegas el miedo, simplemente te ayudará a superarlo. –Richelle E. Goodrich\t"
    },
    "2023-07-18": {
        "content": "Eres lo que eres hoy por las acciones que has hecho o las acciones que no has hecho. –Steve Maraboli\t"
    },
    "2023-07-19": {
        "content": "El éxito no es final, el fracaso no es fatal. Es el coraje para continuar lo que cuenta. –Winston S. Churchill\t"
    },
    "2023-07-20": {
        "content": " Quien no sabe sonreír, no debe hablar de los beneficios de la sonrisa   –Eusebio Gómez Navarro\t"
    },
    "2023-07-21": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2023-07-22": {
        "content": "¿Quieres saber quién eres? No preguntes. ¡Actúa! La acción delineará y definirá quién eres –Thomas Jefferson.\t"
    },
    "2023-07-23": {
        "content": " Estoy convencido de que aproximadamente la mitad de lo que separa a los emprendedores de éxito de los de no  éxito es la perseverancia. –Steve Jobs\t"
    },
    "2023-07-24": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2023-07-25": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2023-07-26": {
        "content": "Tu mejor maestro es tu último error. –Ralph Nader\t"
    },
    "2023-07-27": {
        "content": "El éxito es un camino, no un destino. –Ben Sweetland\t"
    },
    "2023-07-28": {
        "content": "«El éxito no es para los que sueñan despiertos, sino para aquellos que sueñan y hacen que sus sueños se hagan realidad.» –Jack Canfield.\t"
    },
    "2023-07-29": {
        "content": " El éxito es hacer, no obtener. Es intentar, no triunfar. El éxito es un estándar personal, es alcanzar lo más alto que hay en nosotros, convertirnos en todo lo que podemos ser. Si damos nuestro mejor esfuerzo, somos exitosos. –Zig Ziglar\t"
    },
    "2023-07-30": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2023-07-31": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2023-08-01": {
        "content": "Todavía su conocimiento no ha aprendido a sonreír. –Friedrich Nietzsche\t"
    },
    "2023-08-02": {
        "content": "Conviene reír sin esperar a ser dichoso, no sea que nos sorprenda la muerte sin haber reído. –Jean de la Bruyere\t"
    },
    "2023-08-03": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2023-08-04": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2023-08-05": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2023-08-06": {
        "content": "La educación es el arma más poderosa para cambiar el mundo –Nelson Mandela.\t"
    },
    "2023-08-07": {
        "content": "«La vida nos prueba con obstáculos, pero nuestras respuestas a esas pruebas definen nuestra fuerza y determinación». ­–Frases de la vida es dura para reflexionar\t"
    },
    "2023-08-08": {
        "content": "Mira hacia atrás y sonríe ante los peligros del pasado. –Walter Scott\t"
    },
    "2023-08-09": {
        "content": "La mala noticia es que el tiempo vuela, la buena noticia es que tú eres el piloto. –Michael Althsuler\t"
    },
    "2023-08-10": {
        "content": "Una sonrisa es una línea curva que lo endereza todo –Phyllis Diller\t"
    },
    "2023-08-11": {
        "content": " Cuando deseamos convertirnos en personas de éxito, lo primero que tenemos que hacer es actuar como si ya lo fuésemos. –Gaby Vargas\t"
    },
    "2023-08-12": {
        "content": "¿Sabias que. . . Cuando pasas caminando por una flor, sea en algún jardín o en un alguna ladera, la flor siempre te sonríe? la forma más cortés de responder, me han dicho, es devolver la sonrisa alegremente. –Ron Atchison\t"
    },
    "2023-08-13": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2023-08-14": {
        "content": "La sonrisa es una verdadera fuerza vital, la única capaz de mover lo inconmovible –Orison Swett Marden\t"
    },
    "2023-08-15": {
        "content": "Lo que nos parecen pruebas amargas son bendiciones disfrazadas. –Oscar Wilde\t"
    },
    "2023-08-16": {
        "content": "Sonríe, es una terapia gratis. –Douglas Horton\t"
    },
    "2023-08-17": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2023-08-18": {
        "content": "La mayor declaración de amor es la que no se hace; el hombre que siente mucho, habla poco –Platón.\t"
    },
    "2023-08-19": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2023-08-20": {
        "content": "La vida es un 10% lo que me ocurre y 90% cómo reacciono a ello –John Maxwell.\t"
    },
    "2023-08-21": {
        "content": "Sonríe, a pesar de todo. –Steinberg\t"
    },
    "2023-08-22": {
        "content": "Podemos experimentar muchas derrotas, pero no debemos permitir que nos derroten”. –Maya Angelou\t"
    },
    "2023-08-23": {
        "content": "Si mi estupidez te hace sonreír, prefiero entonces actuar siempre como un estúpido. –M.F. Moonzajer\t"
    },
    "2023-08-24": {
        "content": "Cuando tienes la habilidad de hacer lo que amas y amar lo que haces, tienes la habilidad de impactar a las personas. Eso es lo que es tener una vida exitosa. Eso es lo que es tener una vida con sentido. –Tim Tebow\t"
    },
    "2023-08-25": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2023-08-26": {
        "content": "Después de cada tormenta sonríe el sol; para cada problema hay una solución y el deber irrenunciable del alma es estar de buen ánimo. –William R. Alger\t"
    },
    "2023-08-27": {
        "content": "Una sonrisa es la única aflicción infecciosa que animo a todos a que propaguen. –Omar Kiam\t"
    },
    "2023-08-28": {
        "content": "Ve tan lejos como puedas ver y verás más lejos. –Zig Ziglar\t"
    },
    "2023-08-29": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2023-08-30": {
        "content": "Las sonrisas generan amor y paz, el amor y la paz enciende las sonrisas en las personas. –Mehmet Murat ildan\t"
    },
    "2023-08-31": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2023-09-01": {
        "content": " Si quieres probarle algo al mundo, entonces necesitas que el mundo te preste atención para poder probarlo. –Toba Beta\t"
    },
    "2023-09-02": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2023-09-03": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2023-09-04": {
        "content": "Una sonrisa es una curva que pone todo recto. –Phyllis Diller\t"
    },
    "2023-09-05": {
        "content": "El éxito no es mágico ni misterioso. El éxito es la consecuencia natural de aplicar los fundamentos básicos. –Jim Rohn\t"
    },
    "2023-09-06": {
        "content": "No importa lo que la gente diga, las palabras y las ideas tienen el poder de cambiar el mundo”. –Robin Williams\t"
    },
    "2023-09-07": {
        "content": "La lógica te llevará desde A hasta B. La imaginación te llevará a cualquier parte –Albert Einstein.\t"
    },
    "2023-09-08": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2023-09-09": {
        "content": "Aferrarse a la ira es como beber veneno y esperar que la otra persona muera –Buda.\t"
    },
    "2023-09-10": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2023-09-11": {
        "content": "No llores porque terminó. Sonríe porque ocurrió. –Dr. Seuss.\t"
    },
    "2023-09-12": {
        "content": "Si he visto más lejos que otros, es por estar de pie sobre los hombros de gigantes –Isaac Newton.\t"
    },
    "2023-09-13": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2023-09-14": {
        "content": "Una sonrisa es la forma más barata de cambiar nuestro aspecto. –Charles Gordu\t"
    },
    "2023-09-15": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2023-09-16": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2023-09-17": {
        "content": "El hombre verdadero sonríe ante los problemas, toma fuerza de la angustia y crece valiente ante la decepción. –Thomas Paine\t"
    },
    "2023-09-18": {
        "content": "Si solo tienes una sonrisa, dásela a la gente que amas. –Maya Angelou\t"
    },
    "2023-09-19": {
        "content": "Ella sonrió y su rostro se iluminó. O tal vez fue el mío. Todo lo que sé con certeza es que su sonrisa iluminó hasta mis días más oscuros. –J. Sterling\t"
    },
    "2023-09-20": {
        "content": "Sonreímos, pero quiero más, quiero que me abraces. –M.J. Hayland\t"
    },
    "2023-09-21": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2023-09-22": {
        "content": "La sonrisa se ha puesto de pie como una hazaña. –Manuel Del Cabral\t"
    },
    "2023-09-23": {
        "content": "Haz de tu sonrisa un paraguas y deja que llueva. –Twin Peaks\t"
    },
    "2023-09-24": {
        "content": " La mayor arma que uno puede portar frente a sus enemigos es una simple…sonrisa –Lionel Suggs\t"
    },
    "2023-09-25": {
        "content": "Encontrarás la clave del éxito debajo de la alarma del reloj. –Benjamin Franklin\t"
    },
    "2023-09-26": {
        "content": " Aunque sonreír es un trabajo de un simple instante, sus efectos duran por mucho tiempo –Bucky Buckbinder\t"
    },
    "2023-09-27": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2023-09-28": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2023-09-29": {
        "content": " Crecerás el día en que verdaderamente te rías por primera vez de ti mismo –Ethel Barrymore\t"
    },
    "2023-09-30": {
        "content": "No malgastes tu tiempo odiando un fracaso. El fracaso es mejor maestro que el éxito. Escucha, aprende, sigue adelante. –Clarissa Pinkola Estés\t"
    },
    "2023-10-01": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2023-10-02": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2023-10-03": {
        "content": " El poder de una sonrisa es tal que, incluso si dibujas una carita feliz, bastará para hacer que tus labios se muevan de la misma forma –Richelle E. Goodrich\t"
    },
    "2023-10-04": {
        "content": " La diferencia entre la codicia y la ambición es que la persona codiciosa desea cosas para las que no está preparada. –Habeeb Akande\t"
    },
    "2023-10-05": {
        "content": "Huye de los rostros graves y solemnes que jamás se distienden en una sonrisa. Huye de los espíritus susceptibles, que por todo se ofenden. –Ricardo León\t"
    },
    "2023-10-06": {
        "content": " Vamos a conseguir muchas más cosas si pensamos que nada es imposible. –Vince Lombardi\t"
    },
    "2023-10-07": {
        "content": "Y que placer, cuando no hay nada, que pueda ver, y solo invento tu sonrisa, y apago así, toda agonía. –Luis Alberto Spinetta\t"
    },
    "2023-10-08": {
        "content": "Si vas en busca del amor nunca lo encontrarás porque el amor nunca se perdió; sólo nosotros nos perdemos. –Shannon L. Alder\t"
    },
    "2023-10-09": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2023-10-10": {
        "content": "Nadie puede hacerte sentir inferior sin tu consentimiento”. –Eleanor Roosevelt\t"
    },
    "2023-10-11": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito.» –Albert Schweitzer.\t"
    },
    "2023-10-12": {
        "content": " No hay ninguna cosa seria que no pueda decirse con una sonrisa –Alejandro Casona\t"
    },
    "2023-10-13": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, serás exitoso». –Albert Schweitzer\t"
    },
    "2023-10-14": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2023-10-15": {
        "content": "No puedo esperar a hacerlo, quiero hacerlo ahora, quiero que ocurra si va a ocurrir –Georgia O’Keeffe.\t"
    },
    "2023-10-16": {
        "content": "Ni siquiera trató de sonreír. Entonces supe que lo había perdido para siempre. –Marian Keyes\t"
    },
    "2023-10-17": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2023-10-18": {
        "content": " Es más fácil obtener lo que se desea con una sonrisa que con la punta de la espada –William Shakespeare\t"
    },
    "2023-10-19": {
        "content": " Nunca te olvides de sonreír porque el día que no sonrías será un día perdido –Charles Chaplin\t"
    },
    "2023-10-20": {
        "content": " ¿Cómo serías si fueses la única persona del mundo? Si quieres ser realmente feliz, debes ser esa persona. –Quetin Crisp\t"
    },
    "2023-10-21": {
        "content": " Lleva una sonrisa y ten amigos; lleva el ceño fruncido y ten arrugas –George Eliot\t"
    },
    "2023-10-22": {
        "content": " La felicidad no puede ser la única medida del éxito. –John C. Maxwell\t"
    },
    "2023-10-23": {
        "content": "El pesimista ve dificultades en cada oportunidad. El optimista ve oportunidades en cada dificultad”. –Winston Churchill\t"
    },
    "2023-10-24": {
        "content": "No puedo dejar de justificar el que se anime a los niños a sonreír antes que a llorar. No deseo pagar por ver otras lágrimas que las del teatro. –Robert Louis Stevenson\t"
    },
    "2023-10-25": {
        "content": "El éxito consiste en ir de fracaso en fracaso sin pérdida de entusiasmo. –Winston Churchill\t"
    },
    "2023-10-26": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2023-10-27": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2023-10-28": {
        "content": " Lo que mueve al mundo no son los potentes brazos de los héroes, sino la suma de los pequeños empujones de cada trabajador honrado. –Hellen Keller.\t"
    },
    "2023-10-29": {
        "content": "Cuando la necesidad de éxito es tanta como la necesidad de respirar, entonces serás exitoso –Eric Thomas.\t"
    },
    "2023-10-30": {
        "content": "Quien nos hace reír es un cómico. Quien nos hace pensar y luego reír es un humorista. –George Burns\t"
    },
    "2023-10-31": {
        "content": " A menudo las personas quedan atrapadas en un estado de sobre  pensamiento, y el resultado es que nunca toman una decisión. –Steve Backley\t"
    },
    "2023-11-01": {
        "content": " A veces, no obtener lo que quieres es un golpe de suerte brillante. –Lorii Myers\t"
    },
    "2023-11-02": {
        "content": "Cuando ella sonreía, él se olvidaba de todo. –Palmeras en la nieve\t"
    },
    "2023-11-03": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2023-11-04": {
        "content": "Supe que eras la indicada cuando tu sonrisa se convirtió en mi cielo, tu risa en mi canción favorita y tus brazos en mi hogar. –Steve Maraboli\t"
    },
    "2023-11-05": {
        "content": "El cielo no es un límite para mí, porque no tengo límites en mi vida. Porque la vida es un mundo lleno de riesgos y posibilidades. –Termitope Owosela\t"
    },
    "2023-11-06": {
        "content": "El hombre incapaz de reír no solamente es apto para las traiciones, las estratagemas y los fraudes, sino que su vida entera ya es una traición y una estratagema. –Thomas Carlyle\t"
    },
    "2023-11-07": {
        "content": " El trabajo más productivo es el que sale de las manos de un hombre contento. –Victor Pauchet.\t"
    },
    "2023-11-08": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2023-11-09": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2023-11-10": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2023-11-11": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes. –Víctor Ruiz Iriarte\t"
    },
    "2023-11-12": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2023-11-13": {
        "content": "La creatividad es la inteligencia divirtiéndose”. –Albert Einstein\t"
    },
    "2023-11-14": {
        "content": "Si de verdad quieres comer, sigue escalando. Las frutas están en la cima del árbol. Estira tus brazos y sigue estirándolos. El éxito está en la cima, sigue adelante. –Isralemore Ayivor\t"
    },
    "2023-11-15": {
        "content": "El comportamiento humano fluye de tres fuentes: emoción, deseo y conocimiento –Platón.\t"
    },
    "2023-11-16": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2023-11-17": {
        "content": "Cuando un niño comienza a sonreír, con una pequeña bifurcación de amargor y dulzura, las orillas de su sonrisa desembocan sin burlas en la anarquía del océano. –Osip Mandelshtam\t"
    },
    "2023-11-18": {
        "content": "Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs.\t"
    },
    "2023-11-19": {
        "content": "Cuando uno nace sólo sabe llorar. Sólo gradualmente se aprende a sonreír. –Halldór Laxness\t"
    },
    "2023-11-20": {
        "content": "No hay caminos para la paz; la paz es el camino –Mahatma Gandhi.\t"
    },
    "2023-11-21": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2023-11-22": {
        "content": " El día en que te halles a ti mismo sonriendo sin razón, ese día puedes decir que has encontrado la felicidad –Rebecca Stead\t"
    },
    "2023-11-23": {
        "content": "«El futuro pertenece a aquellos que creen en la belleza de sus sueños». –Eleanor Roosevelt\t"
    },
    "2023-11-24": {
        "content": " La vida es corta. Vive apasionadamente. –Marc A. Pitma\t"
    },
    "2023-11-25": {
        "content": "Si haces cada tarea como la vas a hacer por el resto de tu vida, es entonces cuando vas a destacar. –Mary Barra\t"
    },
    "2023-11-26": {
        "content": "En cierto momento logré sonreír de nuevo y sentirme mejor. No solo en el corazón, sino en todo mi cuerpo. Caí en la cuenta de que me hacía bien, que tenía que seguir sonriendo. –Yoko Ono\t"
    },
    "2023-11-27": {
        "content": "No esperes. Nunca habrá un momento perfecto”. –Napoleon Hill,\t"
    },
    "2023-11-28": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2023-11-29": {
        "content": "Cada día sabemos más y entendemos menos –Albert Einstein.\t"
    },
    "2023-11-30": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2023-12-01": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2023-12-02": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2023-12-03": {
        "content": "«La vida es como montar una bicicleta, para mantener el equilibrio debes seguir adelante». –Albert Einstein, frases de la vida es dura\t"
    },
    "2023-12-04": {
        "content": "Frecuentemente me pregunto si esas personas, las que más sonríen, serán en verdad las más tristes en el mundo. –Anthony Liccione\t"
    },
    "2023-12-05": {
        "content": "La paz comienza con una sonrisa. –Madre Teresa\t"
    },
    "2023-12-06": {
        "content": "Las más grandes decepciones que sufre el hombre, vienen de sus propias opiniones. –Leonardo DaVinci\t"
    },
    "2023-12-07": {
        "content": "La perseverancia y la energía conquistan todo”. –Benjamin Franklin\t"
    },
    "2023-12-08": {
        "content": "Hay sonrisas que no son de felicidad, sino de un modo de llorar con bondad. –Gabriela Mistral\t"
    },
    "2023-12-09": {
        "content": " No te sientas avergonzado de intentarlo y fallar, puesto que la persona que nunca ha fallado es alguien que nunca lo ha intentado. –Og Mandino\t"
    },
    "2023-12-10": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2023-12-11": {
        "content": " La risa es la distancia más corta entre dos personas –Víctor Borge\t"
    },
    "2023-12-12": {
        "content": "Los ganadores se toman su tiempo para realizar su trabajo, sabiendo que escalar la montaña es lo que hace la vista tan estimulante. –Denis Waitley.\t"
    },
    "2023-12-13": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2023-12-14": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2023-12-15": {
        "content": " Nunca conseguirás el éxito al menos que te guste lo que estas haciendo. –Dale Carnegie\t"
    },
    "2023-12-16": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2023-12-17": {
        "content": "«El éxito es cuestión de actitud, no de aptitud.» –Ralph Waldo Emerson.\t"
    },
    "2023-12-18": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2023-12-19": {
        "content": "Las sonrisas más auténticas son aquellas que se esparcen en nuestras caras cuando nadie nos está mirando. –Minhal Mehdi\t"
    },
    "2023-12-20": {
        "content": " La buena gestión consiste en mostrar a la gente común cómo hacer el trabajo de las personas superiores. –John D. Rockefeller\t"
    },
    "2023-12-21": {
        "content": "Cuando tengas fallas, no temas abandonarlas. –Confucio\t"
    },
    "2023-12-22": {
        "content": "Teresa de la dulce sonrisa; porque mientras las demás se esforzaban una y otra vez en superarla, ella simplemente las derrotaba con una sonrisa en su rostro. –Claymore\t"
    },
    "2023-12-23": {
        "content": " No tengas miedo de dejar algo bueno para ir a por algo mejor. –John D. Rockefeller\t"
    },
    "2023-12-24": {
        "content": "Dios creó el desierto para que el hombre pudiera sonreír al ver las palmeras. –Paulo Coelho\t"
    },
    "2023-12-25": {
        "content": "Deja que mi alma sonría a través de mi corazón y que mi corazón sonría a través de mis ojos, para que pueda esparcir ricas sonrisas en corazones tristes. –Paramahansa Yogananda\t"
    },
    "2023-12-26": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2023-12-27": {
        "content": " A veces tu alegría es la fuente de tu sonrisa, pero a veces tu sonrisa puede ser la fuente de tu alegría –Thích Nhất Hạnh\t"
    },
    "2023-12-28": {
        "content": " Comparte tus aspiraciones con aquellos que te vayan a apoyar, no con aquellos que respondan con duda o falta de interés. –Steve Backley\t"
    },
    "2023-12-29": {
        "content": " En cualquier momento, la decisión de ser feliz está presente. Sólo tenemos que escoger ser feliz. –Steve Maraboli\t"
    },
    "2023-12-30": {
        "content": "La mejor preparación para mañana es hacer lo mejor que puedas hoy –H. Jackson Brown, Jr.\t"
    },
    "2023-12-31": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2024-01-01": {
        "content": "El no querer es la causa, el no poder el pretexto –Seneca.\t"
    },
    "2024-01-02": {
        "content": " La única batalla real en la vida es la que se da entre elegir aferrarse o dejar que se vaya. –Shannon L. Alder\t"
    },
    "2024-01-03": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2024-01-04": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2024-01-05": {
        "content": " La diferencia entre la codicia y la ambición es que la persona codiciosa desea cosas para las que no está preparada. –Habeeb Akande\t"
    },
    "2024-01-06": {
        "content": "Sonreímos, pero quiero más, quiero que me abraces. –M.J. Hayland\t"
    },
    "2024-01-07": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2024-01-08": {
        "content": "La mayor declaración de amor es la que no se hace; el hombre que siente mucho, habla poco –Platón.\t"
    },
    "2024-01-09": {
        "content": "«La vida nos somete a pruebas para mostrarnos que somos más fuertes de lo que imaginamos en nuestros peores momentos». –Las mejores frases de la vida es dura\t"
    },
    "2024-01-10": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2024-01-11": {
        "content": "Aceptamos la salida del sol y de la luna, sabemos de su poder, de su influencia, incluso les rendimos tributo, pero no alcanzamos a comprender lo que nos puede beneficiar el nacimiento de una sonrisa en nuestro corazón. –Laura Esqivel\t"
    },
    "2024-01-12": {
        "content": "Hoy llevo puesta la sonrisa que me regalaste. –Edith Castro\t"
    },
    "2024-01-13": {
        "content": " La felicidad no puede ser la única medida del éxito. –John C. Maxwell\t"
    },
    "2024-01-14": {
        "content": " El futuro pertenece a los que creen en la belleza de sus sueños. –Eleanor Roosevelt\t"
    },
    "2024-01-15": {
        "content": "Todos deberíamos saber todo el bien que una simple sonrisa puede hacer. –Madre Teresa de Calcuta\t"
    },
    "2024-01-16": {
        "content": " Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs\t"
    },
    "2024-01-17": {
        "content": "El hombre incapaz de reír no solamente es apto para las traiciones, las estratagemas y los fraudes, sino que su vida entera ya es una traición y una estratagema. –Thomas Carlyle\t"
    },
    "2024-01-18": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2024-01-19": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2024-01-20": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2024-01-21": {
        "content": "A veces, cuando innovas, cometes errores. Lo mejor es admitirlos rápidamente y seguir mejorando otras innovaciones. –Steve Jobs\t"
    },
    "2024-01-22": {
        "content": "Sé amable, ya que cada persona que conoces está enfrentando una batalla interna”. –Platón\t"
    },
    "2024-01-23": {
        "content": "Todavía su conocimiento no ha aprendido a sonreír. –Friedrich Nietzsche\t"
    },
    "2024-01-24": {
        "content": "Si mi estupidez te hace sonreír, prefiero entonces actuar siempre como un estúpido. –M.F. Moonzajer\t"
    },
    "2024-01-25": {
        "content": "Si un líder no transmite pasión e intensidad, entonces no habrá pasión e intensidad dentro de la organización y empezarán a caer y deprimirse. –Colin Powell\t"
    },
    "2024-01-26": {
        "content": "Cada día sabemos más y entendemos menos –Albert Einstein.\t"
    },
    "2024-01-27": {
        "content": "Sólo hay un éxito: ser capaz de pasar tu vida a tu manera. –Christopher Morley\t"
    },
    "2024-01-28": {
        "content": "Aunque sentí que estaba a punto de llorar, opté por sonreír. –Kristen Ashley\t"
    },
    "2024-01-29": {
        "content": "Permanece firme a tus principios, pero siempre abierto a aprender. Trabaja duro, nunca renuncies a tus sueños, incluso cuando nadie crea que puedan hacerse realidad excepto tu. Estos no son clichés, sino que son herramientas auténticas que necesitas para estar concentrado en tu trayectoria, no importa a lo que te dediques en tu vida. –Phillip Sweet\t"
    },
    "2024-01-30": {
        "content": "El éxito y la felicidad dependen de ti. –Helen Keller\t"
    },
    "2024-01-31": {
        "content": "El niño reconoce a la madre por la sonrisa. –Leon Tolstoi\t"
    },
    "2024-02-01": {
        "content": "Deja de mentirte. Cuando negamos nuestra verdad, negamos nuestro verdadero potencial. –Steve Maraboli\t"
    },
    "2024-02-02": {
        "content": "«El éxito es la capacidad de ir de fracaso en fracaso sin perder el entusiasmo.» –Winston Churchill.\t"
    },
    "2024-02-03": {
        "content": " A menudo las personas quedan atrapadas en un estado de sobre  pensamiento, y el resultado es que nunca toman una decisión. –Steve Backley\t"
    },
    "2024-02-04": {
        "content": "El secreto de la existencia humana no solo está en vivir, sino también en saber para qué se vive –Fiódor Dostoievski\t"
    },
    "2024-02-05": {
        "content": " Es más fácil obtener lo que se desea con una sonrisa que con la punta de la espada –William Shakespeare\t"
    },
    "2024-02-06": {
        "content": " No hay razón para tener un plan B, porque te distrae del plan A. –Will Smith\t"
    },
    "2024-02-07": {
        "content": " Vamos a conseguir muchas más cosas si pensamos que nada es imposible. –Vince Lombardi\t"
    },
    "2024-02-08": {
        "content": "Puede que seas la única persona que crea en ti, pero eso es suficiente. Sólo se necesita una estrella para perforar la oscuridad del universo. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2024-02-09": {
        "content": "Por una mirada, un mundo –por una sonrisa, un cielo –por un beso… yo no sé –qué te diera por un beso. –Gustavo Adolfo Bécquer\t"
    },
    "2024-02-10": {
        "content": " Empieza cada día con una sonrisa y mantenla todo el día –W. C. Fields\t"
    },
    "2024-02-11": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2024-02-12": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2024-02-13": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2024-02-14": {
        "content": " Quien no sabe sonreír, no debe hablar de los beneficios de la sonrisa   –Eusebio Gómez Navarro\t"
    },
    "2024-02-15": {
        "content": "Su sonrisa podía parar el corazón de un hombre. –El nombre del viento\t"
    },
    "2024-02-16": {
        "content": "Si dicen mal de ti con fundamento, corrígete; de lo contrario, échate a reír. –Epicteto de Frigia\t"
    },
    "2024-02-17": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2024-02-18": {
        "content": " Aunque sonreír es un trabajo de un simple instante, sus efectos duran por mucho tiempo –Bucky Buckbinder\t"
    },
    "2024-02-19": {
        "content": "Eres asombroso porque estás aquí vivo, luchas cada día para mantenerte de pie. ¿Por qué sufres si tienes la solución en tus manos? ¿Por qué lloras si puedes cambiarlo todo? La vida es hermosa porque existes tú”. –Frases sobre la vida\t"
    },
    "2024-02-20": {
        "content": "El hombre que nos hace reír tiene mas votos para su propósito que el hombre que nos exige pensar. –Malcom de Chazall\t"
    },
    "2024-02-21": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2024-02-22": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2024-02-23": {
        "content": " Estoy convencido de que aproximadamente la mitad de lo que separa a los emprendedores de éxito de los de no  éxito es la perseverancia. –Steve Jobs\t"
    },
    "2024-02-24": {
        "content": "«La vida es como montar una bicicleta, para mantener el equilibrio debes seguir adelante». –Albert Einstein, frases de la vida es dura\t"
    },
    "2024-02-25": {
        "content": "Me sonrió como no podría hacerlo un hombre, como si las mujeres lo supieran todo unas de las otras desde la eternidad. –Marcela serrano\t"
    },
    "2024-02-26": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2024-02-27": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2024-02-28": {
        "content": " El éxito es hacer lo que sea que quieras hacer, cuando quieras, donde quieras, con quien quieras y por el tiempo que quieras. –Anthony Robbins\t"
    },
    "2024-02-29": {
        "content": "Si algo me gusta, es vivir. Ver mi cuerpo en la calle, hablar contigo como un camarada, mirar escaparates y, sobre todo, sonreír de lejos a los árboles. –Blas de Otero\t"
    },
    "2024-03-01": {
        "content": "Ni siquiera trató de sonreír. Entonces supe que lo había perdido para siempre. –Marian Keyes\t"
    },
    "2024-03-02": {
        "content": " Cada sonrisa te hace un día más joven  –Proverbio chino\t"
    },
    "2024-03-03": {
        "content": " Crecerás el día en que verdaderamente te rías por primera vez de ti mismo –Ethel Barrymore\t"
    },
    "2024-03-04": {
        "content": "El éxito consiste en ir de fracaso en fracaso sin pérdida de entusiasmo. –Winston Churchill\t"
    },
    "2024-03-05": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2024-03-06": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2024-03-07": {
        "content": "«La vida no se trata de esperar a que pase la tormenta, se trata de aprender a bailar bajo la lluvia». –Vivian Greene\t"
    },
    "2024-03-08": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2024-03-09": {
        "content": "La sonrisa es el trapo que limpia las telarañas del corazón. –Mort Walker\t"
    },
    "2024-03-10": {
        "content": "La educación es el arma más poderosa para cambiar el mundo –Nelson Mandela.\t"
    },
    "2024-03-11": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2024-03-12": {
        "content": "Frecuentemente me pregunto si esas personas, las que más sonríen, serán en verdad las más tristes en el mundo. –Anthony Liccione\t"
    },
    "2024-03-13": {
        "content": "Si he visto más lejos que otros, es por estar de pie sobre los hombros de gigantes –Isaac Newton.\t"
    },
    "2024-03-14": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2024-03-15": {
        "content": "Si la vida no te diera al menos una persona que no quiera que lo logres, entonces la mitad de nosotros perdería la motivación de escalar el acantilado sólo para probar que se equivocan. –Shannon L. Alder\t"
    },
    "2024-03-16": {
        "content": "Quítame el pan, si quieres, quítame el aire, pero no me quites tu risa. –Pablo Neruda\t"
    },
    "2024-03-17": {
        "content": " Nunca conseguirás el éxito al menos que te guste lo que estas haciendo. –Dale Carnegie\t"
    },
    "2024-03-18": {
        "content": "La sonrisa se ha puesto de pie como una hazaña. –Manuel Del Cabral\t"
    },
    "2024-03-19": {
        "content": "Ve definitivamente en dirección de tus sueños. Vive la vida que imaginaste tener –Henry David Thoreau.\t"
    },
    "2024-03-20": {
        "content": "Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs.\t"
    },
    "2024-03-21": {
        "content": "Las guerras seguirán mientras el color de la piel siga siendo más importante que el de los ojos –Bob Marley.\t"
    },
    "2024-03-22": {
        "content": "«No importa cuántas veces te caigas, sino cuántas veces te levantes». –Vince Lombardi\t"
    },
    "2024-03-23": {
        "content": "La sonrisa es una verdadera fuerza vital, la única capaz de mover lo inconmovible –Orison Swett Marden\t"
    },
    "2024-03-24": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2024-03-25": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2024-03-26": {
        "content": " El éxito es un mal maestro. –Robert T. Kiyosaki\t"
    },
    "2024-03-27": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2024-03-28": {
        "content": " El éxito es hacer, no obtener. Es intentar, no triunfar. El éxito es un estándar personal, es alcanzar lo más alto que hay en nosotros, convertirnos en todo lo que podemos ser. Si damos nuestro mejor esfuerzo, somos exitosos. –Zig Ziglar\t"
    },
    "2024-03-29": {
        "content": "Creo que podría hacerme sonreír aunque estuviera colgada de una horca. –Jandy Nelson\t"
    },
    "2024-03-30": {
        "content": "La sonrisa es la llave que abre el corazón de todo el mundo. –Anthony J. D’Angelo\t"
    },
    "2024-03-31": {
        "content": "Haz que se pregunten por qué sigues sonriendo. –Elizabethtown\t"
    },
    "2024-04-01": {
        "content": " La buena gestión consiste en mostrar a la gente común cómo hacer el trabajo de las personas superiores. –John D. Rockefeller\t"
    },
    "2024-04-02": {
        "content": "Hay un solo rincón del universo que puedes estar seguro de poder mejorar, y eres tú mismo –Aldous Huxley.\t"
    },
    "2024-04-03": {
        "content": "Hay sonrisas que no son de felicidad, sino de un modo de llorar con bondad. –Gabriela Mistral\t"
    },
    "2024-04-04": {
        "content": " La vida es corta. Vive apasionadamente. –Marc A. Pitma\t"
    },
    "2024-04-05": {
        "content": "Elimina de tu vida todo aquello que te cause estrés y te quite la sonrisa. –Paulo Coelho\t"
    },
    "2024-04-06": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2024-04-07": {
        "content": "«El éxito no es para los que tienen fuerzas, sino para aquellos que no se rinden.» –Alexandre Dumas.\t"
    },
    "2024-04-08": {
        "content": "«La vida nos prueba con obstáculos, pero nuestras respuestas a esas pruebas definen nuestra fuerza y determinación». ­–Frases de la vida es dura para reflexionar\t"
    },
    "2024-04-09": {
        "content": "«El futuro pertenece a aquellos que creen en la belleza de sus sueños». –Eleanor Roosevelt\t"
    },
    "2024-04-10": {
        "content": " A veces tu alegría es la fuente de tu sonrisa, pero a veces tu sonrisa puede ser la fuente de tu alegría –Thích Nhất Hạnh\t"
    },
    "2024-04-11": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2024-04-12": {
        "content": "De humanos es errar y de necios permanecer en el error –Marco Tulio Cicerón\t"
    },
    "2024-04-13": {
        "content": "Hacer sonreír a una persona significa para mi más que cualquier cosa. –Michael Jackson\t"
    },
    "2024-04-14": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2024-04-15": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2024-04-16": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2024-04-17": {
        "content": "La mejor preparación para mañana es hacer lo mejor que puedas hoy –H. Jackson Brown, Jr.\t"
    },
    "2024-04-18": {
        "content": "Tu mejor maestro es tu último error. –Ralph Nader\t"
    },
    "2024-04-19": {
        "content": "Las sonrisas más auténticas son aquellas que se esparcen en nuestras caras cuando nadie nos está mirando. –Minhal Mehdi\t"
    },
    "2024-04-20": {
        "content": " El secreto para disfrutar el trabajo esta contenido en una palabra –excelencia. Saber cómo hacer algo bien es disfrutarlo. –Pearl S. Buck.\t"
    },
    "2024-04-21": {
        "content": " No hay ninguna cosa seria que no pueda decirse con una sonrisa –Alejandro Casona\t"
    },
    "2024-04-22": {
        "content": "Suspiró y después sonrió con la misma sonrisa que, sin importar cuántas veces la viese, siempre le robaba el corazón. Cada vez que sonreía volvía a ser la primera vez. –Neil Gaiman\t"
    },
    "2024-04-23": {
        "content": " Nunca te sorprendas por tu éxito. –Steve Maraboli\t"
    },
    "2024-04-24": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2024-04-25": {
        "content": "Y que placer, cuando no hay nada, que pueda ver, y solo invento tu sonrisa, y apago así, toda agonía. –Luis Alberto Spinetta\t"
    },
    "2024-04-26": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2024-04-27": {
        "content": " El cambio está en el aire. Nos recuerda que fuimos esculpidos por el mismo poder que orquesta el cambio de las estaciones. Que esta sea la estación en la que aceptes el cambio y te alinees con él. –Steve Maraboli\t"
    },
    "2024-04-28": {
        "content": " Sin importar lo fácil o difícil que sea la vida, siempre encontraré la manera de disfrutar. Incluso cuando las circunstancias sean adversas. –Termitope Owosela\t"
    },
    "2024-04-29": {
        "content": "No se trata de si te derriban, se trata de si te levantas –Vince Lombardi.\t"
    },
    "2024-04-30": {
        "content": "No malgastes tu tiempo odiando un fracaso. El fracaso es mejor maestro que el éxito. Escucha, aprende, sigue adelante. –Clarissa Pinkola Estés\t"
    },
    "2024-05-01": {
        "content": " Cuando deseamos convertirnos en personas de éxito, lo primero que tenemos que hacer es actuar como si ya lo fuésemos. –Gaby Vargas\t"
    },
    "2024-05-02": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2024-05-03": {
        "content": "Encontrarás la clave del éxito debajo de la alarma del reloj. –Benjamin Franklin\t"
    },
    "2024-05-04": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2024-05-05": {
        "content": "Quien nos hace reír es un cómico. Quien nos hace pensar y luego reír es un humorista. –George Burns\t"
    },
    "2024-05-06": {
        "content": " Alguien que elige sonreír antes que enfadarse, es una persona realmente fuerte –David Schary\t"
    },
    "2024-05-07": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2024-05-08": {
        "content": "Nunca te rindas. Establece una meta y no te rindas hasta que la consigas. Cuando la consigas, establece otro objetivo y no te rindas hasta que lo alcances. Nunca te rindas. –Bear Bryant\t"
    },
    "2024-05-09": {
        "content": "Sin sonrisa de mujer no hay gloria completa de hombre. –José Marti\t"
    },
    "2024-05-10": {
        "content": "El momento en el que consideras rendirte es justo el momento en el que debes seguir adelante”. –Anónimo\t"
    },
    "2024-05-11": {
        "content": "Una gran sonrisa es un bello rostro de gigante –Charles Baudelaire\t"
    },
    "2024-05-12": {
        "content": "Sonríe, a pesar de todo. –Steinberg\t"
    },
    "2024-05-13": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2024-05-14": {
        "content": "Eres mi sol nocturno, mi luna de día, mi mejor sonrisa. –Federico Moccia\t"
    },
    "2024-05-15": {
        "content": "Sabes lo que es realmente contagioso? Una gran sonrisa. –Scrubs\t"
    },
    "2024-05-16": {
        "content": "Deja que mi alma sonría a través de mi corazón y que mi corazón sonría a través de mis ojos, para que pueda esparcir ricas sonrisas en corazones tristes. –Paramahansa Yogananda\t"
    },
    "2024-05-17": {
        "content": "El cielo no es un límite para mí, porque no tengo límites en mi vida. Porque la vida es un mundo lleno de riesgos y posibilidades. –Termitope Owosela\t"
    },
    "2024-05-18": {
        "content": "No puedo darte una fórmula segura para el éxito, pero puedo darte una para el fracaso: intenta complacer a todo el mundo todo el tiempo. –Herbert Bayard Swope\t"
    },
    "2024-05-19": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2024-05-20": {
        "content": "La perseverancia y la energía conquistan todo”. –Benjamin Franklin\t"
    },
    "2024-05-21": {
        "content": "«El fracaso no es caer, sino negarse a levantarse». –Anónimo\t"
    },
    "2024-05-22": {
        "content": "«El éxito es cuestión de actitud, no de aptitud.» –Ralph Waldo Emerson.\t"
    },
    "2024-05-23": {
        "content": "Supe que eras la indicada cuando tu sonrisa se convirtió en mi cielo, tu risa en mi canción favorita y tus brazos en mi hogar. –Steve Maraboli\t"
    },
    "2024-05-24": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2024-05-25": {
        "content": "Esa sonrisa me ha salvado de llantos y dolores. –Salvatore Quasimodo\t"
    },
    "2024-05-26": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2024-05-27": {
        "content": "La curva mas hermosa de una mujer es su sonrisa. –Bob Marley\t"
    },
    "2024-05-28": {
        "content": "Era una sonrisa que podría hacer que un hombre creyera que la vida era buena. –John Verdon\t"
    },
    "2024-05-29": {
        "content": " El poder de una sonrisa es tal que, incluso si dibujas una carita feliz, bastará para hacer que tus labios se muevan de la misma forma –Richelle E. Goodrich\t"
    },
    "2024-05-30": {
        "content": "¿Cuándo regresará esa sonrisa que me desnuda en cuerpo y alma? –Jenniley Bonilla\t"
    },
    "2024-05-31": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2024-06-01": {
        "content": "Estaba sonriendo ayer, estoy sonriendo hoy y sonreiré mañana. Simplemente porque la vida es muy corta para llorar por cualquier cosa. –Santosh Kalwar\t"
    },
    "2024-06-02": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2024-06-03": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2024-06-04": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2024-06-05": {
        "content": "La sonrisa es la llave que abre el corazón de todo el mundo. –Anthony J. D’Angelo\t"
    },
    "2024-06-06": {
        "content": " La risa cura, es la obra social más barata y efectiva del mundo –Roberto Pettinato\t"
    },
    "2024-06-07": {
        "content": "Si vas en busca del amor nunca lo encontrarás porque el amor nunca se perdió; sólo nosotros nos perdemos. –Shannon L. Alder\t"
    },
    "2024-06-08": {
        "content": "La forma de arruinar la vida de alguien es darle todo lo que quiere. –Patrick Swayze\t"
    },
    "2024-06-09": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2024-06-10": {
        "content": "Si un hombre sonríe todo el tiempo, probablemente vende algo que no funciona. –George Carlin\t"
    },
    "2024-06-11": {
        "content": "La voluntad de ganar, el deseo de triunfar, el ímpetu de alcanzar tu pleno potencial…estas son las llaves que abrirán la puerta a la excelencia personal. –Confucio\t"
    },
    "2024-06-12": {
        "content": "Cuando uno nace sólo sabe llorar. Sólo gradualmente se aprende a sonreír. –Halldór Laxness\t"
    },
    "2024-06-13": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2024-06-14": {
        "content": "Hazles comprender que no tienen en el mundo otro deber que la alegría. –Paul Claudel\t"
    },
    "2024-06-15": {
        "content": "La vida se encoge o se expande en proporción al coraje de uno –Anais Nin.\t"
    },
    "2024-06-16": {
        "content": "Para ser exitoso hay que seguir el camino de los gigantes. –Lillian Cauldwell\t"
    },
    "2024-06-17": {
        "content": "No hay caminos para la paz; la paz es el camino –Mahatma Gandhi.\t"
    },
    "2024-06-18": {
        "content": "El éxito es un camino, no un destino. –Ben Sweetland\t"
    },
    "2024-06-19": {
        "content": "Nadie puede hacerte sentir inferior sin tu consentimiento”. –Eleanor Roosevelt\t"
    },
    "2024-06-20": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2024-06-21": {
        "content": " Son necesarios cuarenta músculos para arrugar una frente, pero sólo quince para sonreír –Swami Sivananda\t"
    },
    "2024-06-22": {
        "content": " Nunca dejes de sonreír, ni siquiera cuando estés triste, porque nunca sabes quien se puede enamorar de tu sonrisa –Gabriel García Márquez\t"
    },
    "2024-06-23": {
        "content": "Podemos experimentar muchas derrotas, pero no debemos permitir que nos derroten”. –Maya Angelou\t"
    },
    "2024-06-24": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2024-06-25": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2024-06-26": {
        "content": "No esperes. Nunca habrá un momento perfecto”. –Napoleon Hill,\t"
    },
    "2024-06-27": {
        "content": "Huye de los rostros graves y solemnes que jamás se distienden en una sonrisa. Huye de los espíritus susceptibles, que por todo se ofenden. –Ricardo León\t"
    },
    "2024-06-28": {
        "content": "Pienso, luego existo –René Descartes.\t"
    },
    "2024-06-29": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2024-06-30": {
        "content": "Lo que nos parecen pruebas amargas son bendiciones disfrazadas. –Oscar Wilde\t"
    },
    "2024-07-01": {
        "content": "La creatividad es la inteligencia divirtiéndose”. –Albert Einstein\t"
    },
    "2024-07-02": {
        "content": "En cierto momento logré sonreír de nuevo y sentirme mejor. No solo en el corazón, sino en todo mi cuerpo. Caí en la cuenta de que me hacía bien, que tenía que seguir sonriendo. –Yoko Ono\t"
    },
    "2024-07-03": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2024-07-04": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2024-07-05": {
        "content": " El secreto del éxito es mantener una imagen de un resultado exitoso en la mente. –Henry David Thoreau\t"
    },
    "2024-07-06": {
        "content": "Y luego me da una sonrisa que parece tan genuinamente dulce con el toque justo de timidez que el calor inesperado corre a través de mí. –Suzanne Collins\t"
    },
    "2024-07-07": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2024-07-08": {
        "content": "Después de cada tormenta sonríe el sol; para cada problema hay una solución y el deber irrenunciable del alma es estar de buen ánimo. –William R. Alger\t"
    },
    "2024-07-09": {
        "content": " La mayor arma que uno puede portar frente a sus enemigos es una simple…sonrisa –Lionel Suggs\t"
    },
    "2024-07-10": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2024-07-11": {
        "content": "No importa lo lento que vayas mientras que no pares –Confucio.\t"
    },
    "2024-07-12": {
        "content": "La raza humana tiene un arma verdaderamente eficaz: la risa. –Mark Twain\t"
    },
    "2024-07-13": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2024-07-14": {
        "content": "Si estás leyendo esto… Felicitaciones, estás vivo. Si eso no es algo para sonreír, entonces no sé qué es. –Chad Sugg\t"
    },
    "2024-07-15": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2024-07-16": {
        "content": "Una sonrisa es la felicidad que encontrarás justo debajo te tu nariz. –Tom Wilson\t"
    },
    "2024-07-17": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2024-07-18": {
        "content": " No te sientas avergonzado de intentarlo y fallar, puesto que la persona que nunca ha fallado es alguien que nunca lo ha intentado. –Og Mandino\t"
    },
    "2024-07-19": {
        "content": "Los ganadores se toman su tiempo para realizar su trabajo, sabiendo que escalar la montaña es lo que hace la vista tan estimulante. –Denis Waitley.\t"
    },
    "2024-07-20": {
        "content": "Si de verdad quieres comer, sigue escalando. Las frutas están en la cima del árbol. Estira tus brazos y sigue estirándolos. El éxito está en la cima, sigue adelante. –Isralemore Ayivor\t"
    },
    "2024-07-21": {
        "content": " Confía en la fuerza de tu cuerpo y corazón. Elige por estrella la autosuficiencia, la fe, la honestidad y la industria. No tomes demasiados consejos, mantén el timón y dirige tu propio barco, y recuerda que el gran arte de mandar es tomar una participación equitativa del trabajo. Pon fuego sobre la marca que quieres golpear. La energía y la determinación con el motivo correctos son las palancas que mueven al mundo. –Noah Porter.\t"
    },
    "2024-07-22": {
        "content": "Eres lo que eres hoy por las acciones que has hecho o las acciones que no has hecho. –Steve Maraboli\t"
    },
    "2024-07-23": {
        "content": "Ve tan lejos como puedas ver y verás más lejos. –Zig Ziglar\t"
    },
    "2024-07-24": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2024-07-25": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2024-07-26": {
        "content": "No quiero dejar de sonreír, bajo ninguna circunstancia, ni perder la libertad para pensar, y quiero enfrentarme a cualquier dificultad con valentía. –Banana Yashimoto\t"
    },
    "2024-07-27": {
        "content": "Conviene reír sin esperar a ser dichoso, no sea que nos sorprenda la muerte sin haber reído. –Jean de la Bruyere\t"
    },
    "2024-07-28": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2024-07-29": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2024-07-30": {
        "content": "El primer paso es el más importante. Es el más crucial y más efectivo, ya que iniciará la dirección que has elegido. –Steve Backley\t"
    },
    "2024-07-31": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2024-08-01": {
        "content": "Una sonrisa es una curva que pone todo recto. –Phyllis Diller\t"
    },
    "2024-08-02": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2024-08-03": {
        "content": "Las más grandes decepciones que sufre el hombre, vienen de sus propias opiniones. –Leonardo DaVinci\t"
    },
    "2024-08-04": {
        "content": "Espero tu sonrisa y espero tu fragancia por encima de todo, del tiempo y la distancia. –José Angel Buesa\t"
    },
    "2024-08-05": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2024-08-06": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2024-08-07": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2024-08-08": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2024-08-09": {
        "content": "El único hombre que nunca comete un error es el hombre que nunca hace nada. –Theodore Roosevelt\t"
    },
    "2024-08-10": {
        "content": "Sonreír en la presencia del peligro no significa que niegas el miedo, simplemente te ayudará a superarlo. –Richelle E. Goodrich\t"
    },
    "2024-08-11": {
        "content": "Opino que lo que se llama belleza, reside únicamente en la sonrisa. –León Tolstoi\t"
    },
    "2024-08-12": {
        "content": " Es mejor olvidarse y sonreír que recordar y entristecerse –Cristina Rossetti\t"
    },
    "2024-08-13": {
        "content": " Hoy es el único día sobre el que tenemos poder. –Steve Maraboli\t"
    },
    "2024-08-14": {
        "content": " No tengas miedo de dejar algo bueno para ir a por algo mejor. –John D. Rockefeller\t"
    },
    "2024-08-15": {
        "content": "Si estás comprometido con algo que realmente te importa, no necesitarás que nadie te empuje: tu visión te impulsará”. –Steve Jobs\t"
    },
    "2024-08-16": {
        "content": "Si no usas tu sonrisa, eres como un hombre con un millón de dolares en el banco y sin poder sacarlos. –Les Giblin\t"
    },
    "2024-08-17": {
        "content": "Si solo tienes una sonrisa, dásela a la gente que amas. –Maya Angelou\t"
    },
    "2024-08-18": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2024-08-19": {
        "content": "Nunca eres demasiado mayor para establecer nuevas metas o tener nuevos sueños”. –C.S. Lewis\t"
    },
    "2024-08-20": {
        "content": "Una sonrisa es la forma más barata de cambiar nuestro aspecto. –Charles Gordu\t"
    },
    "2024-08-21": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2024-08-22": {
        "content": "Tenía los labios inmóviles, pero cuando lo miré parecieron sonreír sin hacer el más mínimo movimiento. –Anne Rice\t"
    },
    "2024-08-23": {
        "content": "Comienza y termina el día con una sonrisa. –W.C. Fields\t"
    },
    "2024-08-24": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2024-08-25": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2024-08-26": {
        "content": "Mira hacia atrás y sonríe ante los peligros del pasado. –Walter Scott\t"
    },
    "2024-08-27": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2024-08-28": {
        "content": " El día peor empleado es aquél en que no se ha reído –Nicolás  Sebastien Roch Chamfort\t"
    },
    "2024-08-29": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2024-08-30": {
        "content": "Rodéate de personas que crean en tus sueños, alienten tus ideas, apoyen tus ambiciones y saquen lo mejor de ti”. –Roy T. Bennett\t"
    },
    "2024-08-31": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2024-09-01": {
        "content": "No puedo dejar de justificar el que se anime a los niños a sonreír antes que a llorar. No deseo pagar por ver otras lágrimas que las del teatro. –Robert Louis Stevenson\t"
    },
    "2024-09-02": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, serás exitoso». –Albert Schweitzer\t"
    },
    "2024-09-03": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2024-09-04": {
        "content": "La mayoría de las grandes hazañas logradas por el hombre fueron consideradas imposibles antes de que alguien las llevara a cabo”. –Louis D. Brandeis.\t"
    },
    "2024-09-05": {
        "content": "La forma de empezar es dejar de hablar y empezar a actuar   –Walt Disney\t"
    },
    "2024-09-06": {
        "content": "No importa lo que la gente diga, las palabras y las ideas tienen el poder de cambiar el mundo”. –Robin Williams\t"
    },
    "2024-09-07": {
        "content": "Cuando ella sonreía, él se olvidaba de todo. –Palmeras en la nieve\t"
    },
    "2024-09-08": {
        "content": " A veces, no obtener lo que quieres es un golpe de suerte brillante. –Lorii Myers\t"
    },
    "2024-09-09": {
        "content": "Las sonrisas generan amor y paz, el amor y la paz enciende las sonrisas en las personas. –Mehmet Murat ildan\t"
    },
    "2024-09-10": {
        "content": "La paz comienza con una sonrisa. –Madre Teresa\t"
    },
    "2024-09-11": {
        "content": "Si te pones a ti mismo en una posición en la que tienes que salir de tu zona de confort, estas forzando la expansión de tu consciencia. –Les Brown\t"
    },
    "2024-09-12": {
        "content": "No puedo mantenerme enojado cuando me sonríes. –M.F. Moonzajer\t"
    },
    "2024-09-13": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2024-09-14": {
        "content": "El comportamiento humano fluye de tres fuentes: emoción, deseo y conocimiento –Platón.\t"
    },
    "2024-09-15": {
        "content": " La risa es la distancia más corta entre dos personas –Víctor Borge\t"
    },
    "2024-09-16": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2024-09-17": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2024-09-18": {
        "content": " Ríe y el mundo reirá contigo; llora y llorarás sólo –Eli Wilcox\t"
    },
    "2024-09-19": {
        "content": "Mantente alejado de la gente que intenta menospreciar tus ambiciones. La gente pequeña siempre hace eso, pero la gente realmente grande te hace sentir que tu también puedes ser grande –Mark Twain.\t"
    },
    "2024-09-20": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2024-09-21": {
        "content": "Haz de tu sonrisa un paraguas y deja que llueva. –Twin Peaks\t"
    },
    "2024-09-22": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2024-09-23": {
        "content": "¿Quieres saber quién eres? No preguntes. ¡Actúa! La acción delineará y definirá quién eres –Thomas Jefferson.\t"
    },
    "2024-09-24": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2024-09-25": {
        "content": "Una sonrisa es una línea curva que lo endereza todo –Phyllis Diller\t"
    },
    "2024-09-26": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2024-09-27": {
        "content": "Aferrarse a la ira es como beber veneno y esperar que la otra persona muera –Buda.\t"
    },
    "2024-09-28": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2024-09-29": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2024-09-30": {
        "content": "El éxito no es final, el fracaso no es fatal. Es el coraje para continuar lo que cuenta. –Winston S. Churchill\t"
    },
    "2024-10-01": {
        "content": "El pesimista ve dificultades en cada oportunidad. El optimista ve oportunidades en cada dificultad”. –Winston Churchill\t"
    },
    "2024-10-02": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2024-10-03": {
        "content": "Los seres humanos no nacen para siempre el día en que sus madres los alumbran, sino que la vida los obliga a parirse a sí mismos una y otra vez –Gabriel García Márquez.\t"
    },
    "2024-10-04": {
        "content": "No llores porque ya se terminó, sonríe porque sucedió. –Gabriel García Marquez\t"
    },
    "2024-10-05": {
        "content": "Quienes no saben llorar con todo el corazón, tampoco saben sonreír. –Golda Meir\t"
    },
    "2024-10-06": {
        "content": "Una sonrisa es la única aflicción infecciosa que animo a todos a que propaguen. –Omar Kiam\t"
    },
    "2024-10-07": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2024-10-08": {
        "content": "Cuando la necesidad de éxito es tanta como la necesidad de respirar, entonces serás exitoso –Eric Thomas.\t"
    },
    "2024-10-09": {
        "content": " Participa en tus propios sueños. No digas solamente lo que quieres o te quejes de lo que no tienes. –Steve Maraboli\t"
    },
    "2024-10-10": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2024-10-11": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2024-10-12": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2024-10-13": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2024-10-14": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2024-10-15": {
        "content": "El hombre verdadero sonríe ante los problemas, toma fuerza de la angustia y crece valiente ante la decepción. –Thomas Paine\t"
    },
    "2024-10-16": {
        "content": "Tomar responsabilidad personal es algo hermoso porque nos da un control completo sobre nuestro destino. –Heather Schuck\t"
    },
    "2024-10-17": {
        "content": "Porque yo, sólo yo, veo su sonrisa una milésima antes de que le llegue a la cara. –David Mitchell\t"
    },
    "2024-10-18": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2024-10-19": {
        "content": "Ella sonrió y su rostro se iluminó. O tal vez fue el mío. Todo lo que sé con certeza es que su sonrisa iluminó hasta mis días más oscuros. –J. Sterling\t"
    },
    "2024-10-20": {
        "content": "La definición del propósito es el punto de comienzo de todo logro –Clement Stone.\t"
    },
    "2024-10-21": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2024-10-22": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2024-10-23": {
        "content": " No importa lo bueno que seas, siempre puedes ser mejor, esa es la parte emocionante. –Tiger Woods\t"
    },
    "2024-10-24": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2024-10-25": {
        "content": "Tu trabajo va a llenar una gran parte de tu vida; la única manera de estar verdaderamente satisfecho es hacer lo que crees que es un gran trabajo y la única manera de hacer un gran trabajo es amar lo que haces. Si todavía no lo has encontrado, sigue buscando. No te conformes. Como con todos los asuntos del corazón, sabrás cuando lo encuentres. –Steve Jobs\t"
    },
    "2024-10-26": {
        "content": "Mi padre siempre me decía: encuentra un trabajo que te guste y no tendrás que trabajar un solo día de tu vida. –Jim Fox.\t"
    },
    "2024-10-27": {
        "content": " Nunca te olvides de sonreír porque el día que no sonrías será un día perdido –Charles Chaplin\t"
    },
    "2024-10-28": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2024-10-29": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2024-10-30": {
        "content": "Las arrugas solo deberían indicar donde las sonrisas han estado. –Mark Twain\t"
    },
    "2024-10-31": {
        "content": "«El éxito no es para los que sueñan despiertos, sino para aquellos que sueñan y hacen que sus sueños se hagan realidad.» –Jack Canfield.\t"
    },
    "2024-11-01": {
        "content": "Una sonrisa puede iluminar los lugares más oscuros. –Steve Maraboli\t"
    },
    "2024-11-02": {
        "content": "La mala noticia es que el tiempo vuela, la buena noticia es que tú eres el piloto. –Michael Althsuler\t"
    },
    "2024-11-03": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2024-11-04": {
        "content": " La sonrisa enriquece a los que la reciben, sin empobrecer a los que la dan –Frank Irving\t"
    },
    "2024-11-05": {
        "content": " Si quieres probarle algo al mundo, entonces necesitas que el mundo te preste atención para poder probarlo. –Toba Beta\t"
    },
    "2024-11-06": {
        "content": " Lo que mueve al mundo no son los potentes brazos de los héroes, sino la suma de los pequeños empujones de cada trabajador honrado. –Hellen Keller.\t"
    },
    "2024-11-07": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2024-11-08": {
        "content": "La lógica te llevará de la A a la Z, pero la imaginación te llevará a cualquier lugar”. –Albert Einstein\t"
    },
    "2024-11-09": {
        "content": " En cualquier momento, la decisión de ser feliz está presente. Sólo tenemos que escoger ser feliz. –Steve Maraboli\t"
    },
    "2024-11-10": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2024-11-11": {
        "content": "Si es bueno vivir, todavía es mejor soñar, y lo mejor de todo, despertar –Antonio Machado.\t"
    },
    "2024-11-12": {
        "content": " El trabajo más productivo es el que sale de las manos de un hombre contento. –Victor Pauchet.\t"
    },
    "2024-11-13": {
        "content": "El corazón alegre hermosea el rostro. –Rey Salomón\t"
    },
    "2024-11-14": {
        "content": "¿Sabias que. . . Cuando pasas caminando por una flor, sea en algún jardín o en un alguna ladera, la flor siempre te sonríe? la forma más cortés de responder, me han dicho, es devolver la sonrisa alegremente. –Ron Atchison\t"
    },
    "2024-11-15": {
        "content": " Prende tu sonrisa, ríe sin razón. Tú controlas el interruptor de la felicidad, eres tú quien cambia el mundo –Roel van Sleeuwen\t"
    },
    "2024-11-16": {
        "content": "No llores porque terminó. Sonríe porque ocurrió. –Dr. Seuss.\t"
    },
    "2024-11-17": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2024-11-18": {
        "content": " Comparte tus aspiraciones con aquellos que te vayan a apoyar, no con aquellos que respondan con duda o falta de interés. –Steve Backley\t"
    },
    "2024-11-19": {
        "content": "Teresa de la dulce sonrisa; porque mientras las demás se esforzaban una y otra vez en superarla, ella simplemente las derrotaba con una sonrisa en su rostro. –Claymore\t"
    },
    "2024-11-20": {
        "content": "El éxito no es mágico ni misterioso. El éxito es la consecuencia natural de aplicar los fundamentos básicos. –Jim Rohn\t"
    },
    "2024-11-21": {
        "content": "Los colores son la sonrisa de la naturaleza. –Leigh Hunt\t"
    },
    "2024-11-22": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2024-11-23": {
        "content": "La sonrisa es el idioma general de los hombres inteligentes. Sólo son tristes los tontos y los delincuentes. –Víctor Ruiz Iriarte\t"
    },
    "2024-11-24": {
        "content": "Cambia tus pensamientos y cambiarás tu mundo –Norman Vincent Peale.\t"
    },
    "2024-11-25": {
        "content": "Si es posible, se debe hacer reír hasta a los muertos. –Leonardo Da Vinci\t"
    },
    "2024-11-26": {
        "content": "No importa si te derriban, lo importante es si te levantas cuando sucede”. –Vince Lombardi\t"
    },
    "2024-11-27": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2024-11-28": {
        "content": "No cuentes los días, haz que los días cuenten –Muhammad Ali.\t"
    },
    "2024-11-29": {
        "content": "Consulta no tus miedos sino tus esperanzas y tus sueños. No pienses en tus frustraciones, sino en tu potencial incumplido. Preocúpate no en lo que has intentado y fracasado, sino en lo que todavía es posible hacer y lograr. –Juan XXIII\t"
    },
    "2024-11-30": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2024-12-01": {
        "content": "No dejes de sonreír… Porque es muy poco el tiempo que te dan para la alegría –Zenaida Bacardí de Argamasilla\t"
    },
    "2024-12-02": {
        "content": "«El único modo de hacer un gran trabajo es amar lo que haces». –Steve Jobs\t"
    },
    "2024-12-03": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2024-12-04": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2024-12-05": {
        "content": "Para tener éxito hoy, tienes que fijar prioridades, decidir lo que representas. –Lee Laccoca\t"
    },
    "2024-12-06": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2024-12-07": {
        "content": "Si haces cada tarea como la vas a hacer por el resto de tu vida, es entonces cuando vas a destacar. –Mary Barra\t"
    },
    "2024-12-08": {
        "content": " El día en que te halles a ti mismo sonriendo sin razón, ese día puedes decir que has encontrado la felicidad –Rebecca Stead\t"
    },
    "2024-12-09": {
        "content": " Lo que hoy ves como fracaso puede ser un paso crucial hacia el éxito que estás buscando. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2024-12-10": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2024-12-11": {
        "content": "La vida es un 10% lo que me ocurre y 90% cómo reacciono a ello –John Maxwell.\t"
    },
    "2024-12-12": {
        "content": "La risa es el lenguaje del alma. –Pablo Neruda\t"
    },
    "2024-12-13": {
        "content": "Las limitaciones solo habitan en nuestra mente. Pero si usamos nuestra imaginación, nuestras posibilidades son ilimitadas –Jamie Paolinetti.\t"
    },
    "2024-12-14": {
        "content": "Cuando un niño comienza a sonreír, con una pequeña bifurcación de amargor y dulzura, las orillas de su sonrisa desembocan sin burlas en la anarquía del océano. –Osip Mandelshtam\t"
    },
    "2024-12-15": {
        "content": "Comienza donde estás, usa lo que tienes, haz lo que puedes –Arthur Ashe.\t"
    },
    "2024-12-16": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2024-12-17": {
        "content": "La lógica te llevará desde A hasta B. La imaginación te llevará a cualquier parte –Albert Einstein.\t"
    },
    "2024-12-18": {
        "content": "No puedo esperar a hacerlo, quiero hacerlo ahora, quiero que ocurra si va a ocurrir –Georgia O’Keeffe.\t"
    },
    "2024-12-19": {
        "content": " ¿Cómo serías si fueses la única persona del mundo? Si quieres ser realmente feliz, debes ser esa persona. –Quetin Crisp\t"
    },
    "2024-12-20": {
        "content": "Nunca permitiré que me vuelva tan importante, sabio, reservado y poderoso, que no pueda sonreír a los demás y reírme de mí mismo. –Og Mandino\t"
    },
    "2024-12-21": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2024-12-22": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2024-12-23": {
        "content": " El éxito no es comparar lo que hemos hecho con lo que los demás han hecho. –Myles Munroe\t"
    },
    "2024-12-24": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2024-12-25": {
        "content": "Cuando tengas fallas, no temas abandonarlas. –Confucio\t"
    },
    "2024-12-26": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2024-12-27": {
        "content": "La muerte nos sonríe a todos, devolvámosle la sonrisa. –Gladiator\t"
    },
    "2024-12-28": {
        "content": "Es maravilloso que nadie tenga que esperar ni un segundo para comenzar a hacer del mundo un lugar mejor”. –Ana Frank\t"
    },
    "2024-12-29": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2024-12-30": {
        "content": " Lleva una sonrisa y ten amigos; lleva el ceño fruncido y ten arrugas –George Eliot\t"
    },
    "2024-12-31": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2025-01-01": {
        "content": "La creatividad es la inteligencia divirtiéndose”. –Albert Einstein\t"
    },
    "2025-01-02": {
        "content": "La educación es el arma más poderosa para cambiar el mundo –Nelson Mandela.\t"
    },
    "2025-01-03": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2025-01-04": {
        "content": " Lleva una sonrisa y ten amigos; lleva el ceño fruncido y ten arrugas –George Eliot\t"
    },
    "2025-01-05": {
        "content": "Todavía su conocimiento no ha aprendido a sonreír. –Friedrich Nietzsche\t"
    },
    "2025-01-06": {
        "content": "La raza humana tiene un arma verdaderamente eficaz: la risa. –Mark Twain\t"
    },
    "2025-01-07": {
        "content": " A veces tu alegría es la fuente de tu sonrisa, pero a veces tu sonrisa puede ser la fuente de tu alegría –Thích Nhất Hạnh\t"
    },
    "2025-01-08": {
        "content": "La lógica te llevará desde A hasta B. La imaginación te llevará a cualquier parte –Albert Einstein.\t"
    },
    "2025-01-09": {
        "content": "Hazles comprender que no tienen en el mundo otro deber que la alegría. –Paul Claudel\t"
    },
    "2025-01-10": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2025-01-11": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2025-01-12": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2025-01-13": {
        "content": "Eres lo que eres hoy por las acciones que has hecho o las acciones que no has hecho. –Steve Maraboli\t"
    },
    "2025-01-14": {
        "content": " Es más fácil obtener lo que se desea con una sonrisa que con la punta de la espada –William Shakespeare\t"
    },
    "2025-01-15": {
        "content": " Sin importar lo fácil o difícil que sea la vida, siempre encontraré la manera de disfrutar. Incluso cuando las circunstancias sean adversas. –Termitope Owosela\t"
    },
    "2025-01-16": {
        "content": "Si no usas tu sonrisa, eres como un hombre con un millón de dolares en el banco y sin poder sacarlos. –Les Giblin\t"
    },
    "2025-01-17": {
        "content": "Sonreír es definitivamente una de los mejores y más bellos remedios. Si tienes un buen sentido de humor y un buen enfoque hacia la vida, eso es hermoso –Rashida Jones\t"
    },
    "2025-01-18": {
        "content": " El éxito es un mal maestro. –Robert T. Kiyosaki\t"
    },
    "2025-01-19": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2025-01-20": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2025-01-21": {
        "content": "Tu mejor maestro es tu último error. –Ralph Nader\t"
    },
    "2025-01-22": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2025-01-23": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2025-01-24": {
        "content": " Participa en tus propios sueños. No digas solamente lo que quieres o te quejes de lo que no tienes. –Steve Maraboli\t"
    },
    "2025-01-25": {
        "content": "Ve definitivamente en dirección de tus sueños. Vive la vida que imaginaste tener –Henry David Thoreau.\t"
    },
    "2025-01-26": {
        "content": " Si quieres probarle algo al mundo, entonces necesitas que el mundo te preste atención para poder probarlo. –Toba Beta\t"
    },
    "2025-01-27": {
        "content": "El éxito y la felicidad dependen de ti. –Helen Keller\t"
    },
    "2025-01-28": {
        "content": "No hay caminos para la paz; la paz es el camino –Mahatma Gandhi.\t"
    },
    "2025-01-29": {
        "content": " Son necesarios cuarenta músculos para arrugar una frente, pero sólo quince para sonreír –Swami Sivananda\t"
    },
    "2025-01-30": {
        "content": "«La vida no se trata de esperar a que pase la tormenta, se trata de aprender a bailar bajo la lluvia». –Vivian Greene\t"
    },
    "2025-01-31": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2025-02-01": {
        "content": "La lógica te llevará de la A a la Z, pero la imaginación te llevará a cualquier lugar”. –Albert Einstein\t"
    },
    "2025-02-02": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2025-02-03": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2025-02-04": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2025-02-05": {
        "content": " Nunca te olvides de sonreír porque el día que no sonrías será un día perdido –Charles Chaplin\t"
    },
    "2025-02-06": {
        "content": "No quiero dejar de sonreír, bajo ninguna circunstancia, ni perder la libertad para pensar, y quiero enfrentarme a cualquier dificultad con valentía. –Banana Yashimoto\t"
    },
    "2025-02-07": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2025-02-08": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2025-02-09": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2025-02-10": {
        "content": " Ríe y el mundo reirá contigo; llora y llorarás sólo –Eli Wilcox\t"
    },
    "2025-02-11": {
        "content": "Conviene reír sin esperar a ser dichoso, no sea que nos sorprenda la muerte sin haber reído. –Jean de la Bruyere\t"
    },
    "2025-02-12": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2025-02-13": {
        "content": "El hombre que nos hace reír tiene mas votos para su propósito que el hombre que nos exige pensar. –Malcom de Chazall\t"
    },
    "2025-02-14": {
        "content": "El hombre incapaz de reír no solamente es apto para las traiciones, las estratagemas y los fraudes, sino que su vida entera ya es una traición y una estratagema. –Thomas Carlyle\t"
    },
    "2025-02-15": {
        "content": "Y luego me da una sonrisa que parece tan genuinamente dulce con el toque justo de timidez que el calor inesperado corre a través de mí. –Suzanne Collins\t"
    },
    "2025-02-16": {
        "content": "Eres mi sol nocturno, mi luna de día, mi mejor sonrisa. –Federico Moccia\t"
    },
    "2025-02-17": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2025-02-18": {
        "content": "No se trata de si te derriban, se trata de si te levantas –Vince Lombardi.\t"
    },
    "2025-02-19": {
        "content": "Cuando tienes la habilidad de hacer lo que amas y amar lo que haces, tienes la habilidad de impactar a las personas. Eso es lo que es tener una vida exitosa. Eso es lo que es tener una vida con sentido. –Tim Tebow\t"
    },
    "2025-02-20": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2025-02-21": {
        "content": " El secreto del éxito es mantener una imagen de un resultado exitoso en la mente. –Henry David Thoreau\t"
    },
    "2025-02-22": {
        "content": " Prende tu sonrisa, ríe sin razón. Tú controlas el interruptor de la felicidad, eres tú quien cambia el mundo –Roel van Sleeuwen\t"
    },
    "2025-02-23": {
        "content": "Sonreír en la presencia del peligro no significa que niegas el miedo, simplemente te ayudará a superarlo. –Richelle E. Goodrich\t"
    },
    "2025-02-24": {
        "content": "Una sonrisa es la única aflicción infecciosa que animo a todos a que propaguen. –Omar Kiam\t"
    },
    "2025-02-25": {
        "content": "«El éxito es la capacidad de ir de fracaso en fracaso sin perder el entusiasmo.» –Winston Churchill.\t"
    },
    "2025-02-26": {
        "content": "El hombre está condenado a ser libre –Jean  Paul Sartre.\t"
    },
    "2025-02-27": {
        "content": " La risa cura, es la obra social más barata y efectiva del mundo –Roberto Pettinato\t"
    },
    "2025-02-28": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2025-03-01": {
        "content": "El éxito consiste en ir de fracaso en fracaso sin pérdida de entusiasmo. –Winston Churchill\t"
    },
    "2025-03-02": {
        "content": "Nunca eres demasiado mayor para establecer nuevas metas o tener nuevos sueños”. –C.S. Lewis\t"
    },
    "2025-03-03": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2025-03-04": {
        "content": "La curva mas hermosa de una mujer es su sonrisa. –Bob Marley\t"
    },
    "2025-03-05": {
        "content": " Quien no sabe sonreír, no debe hablar de los beneficios de la sonrisa   –Eusebio Gómez Navarro\t"
    },
    "2025-03-06": {
        "content": "La perseverancia y la energía conquistan todo”. –Benjamin Franklin\t"
    },
    "2025-03-07": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2025-03-08": {
        "content": " Confía en la fuerza de tu cuerpo y corazón. Elige por estrella la autosuficiencia, la fe, la honestidad y la industria. No tomes demasiados consejos, mantén el timón y dirige tu propio barco, y recuerda que el gran arte de mandar es tomar una participación equitativa del trabajo. Pon fuego sobre la marca que quieres golpear. La energía y la determinación con el motivo correctos son las palancas que mueven al mundo. –Noah Porter.\t"
    },
    "2025-03-09": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2025-03-10": {
        "content": "Lo que nos parecen pruebas amargas son bendiciones disfrazadas. –Oscar Wilde\t"
    },
    "2025-03-11": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2025-03-12": {
        "content": " El secreto para disfrutar el trabajo esta contenido en una palabra –excelencia. Saber cómo hacer algo bien es disfrutarlo. –Pearl S. Buck.\t"
    },
    "2025-03-13": {
        "content": "Si solo tienes una sonrisa, dásela a la gente que amas. –Maya Angelou\t"
    },
    "2025-03-14": {
        "content": "La voluntad de ganar, el deseo de triunfar, el ímpetu de alcanzar tu pleno potencial…estas son las llaves que abrirán la puerta a la excelencia personal. –Confucio\t"
    },
    "2025-03-15": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2025-03-16": {
        "content": "Encontrarás la clave del éxito debajo de la alarma del reloj. –Benjamin Franklin\t"
    },
    "2025-03-17": {
        "content": "No puedo esperar a hacerlo, quiero hacerlo ahora, quiero que ocurra si va a ocurrir –Georgia O’Keeffe.\t"
    },
    "2025-03-18": {
        "content": "Tomar responsabilidad personal es algo hermoso porque nos da un control completo sobre nuestro destino. –Heather Schuck\t"
    },
    "2025-03-19": {
        "content": "Cada día sabemos más y entendemos menos –Albert Einstein.\t"
    },
    "2025-03-20": {
        "content": " El éxito no es comparar lo que hemos hecho con lo que los demás han hecho. –Myles Munroe\t"
    },
    "2025-03-21": {
        "content": "Hacer sonreír a una persona significa para mi más que cualquier cosa. –Michael Jackson\t"
    },
    "2025-03-22": {
        "content": " No importa lo bueno que seas, siempre puedes ser mejor, esa es la parte emocionante. –Tiger Woods\t"
    },
    "2025-03-23": {
        "content": "La forma de empezar es dejar de hablar y empezar a actuar   –Walt Disney\t"
    },
    "2025-03-24": {
        "content": "Si es bueno vivir, todavía es mejor soñar, y lo mejor de todo, despertar –Antonio Machado.\t"
    },
    "2025-03-25": {
        "content": "Haz de tu sonrisa un paraguas y deja que llueva. –Twin Peaks\t"
    },
    "2025-03-26": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2025-03-27": {
        "content": " El éxito es hacer, no obtener. Es intentar, no triunfar. El éxito es un estándar personal, es alcanzar lo más alto que hay en nosotros, convertirnos en todo lo que podemos ser. Si damos nuestro mejor esfuerzo, somos exitosos. –Zig Ziglar\t"
    },
    "2025-03-28": {
        "content": "El no querer es la causa, el no poder el pretexto –Seneca.\t"
    },
    "2025-03-29": {
        "content": "Estar preparado es importante, saber esperarlo es aún más, pero aprovechar el momento adecuado es la clave de la vida –Arthur Schnitzler.\t"
    },
    "2025-03-30": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2025-03-31": {
        "content": "La sonrisa es una verdadera fuerza vital, la única capaz de mover lo inconmovible –Orison Swett Marden\t"
    },
    "2025-04-01": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2025-04-02": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2025-04-03": {
        "content": "Dios creó el desierto para que el hombre pudiera sonreír al ver las palmeras. –Paulo Coelho\t"
    },
    "2025-04-04": {
        "content": "La mayoría de las grandes hazañas logradas por el hombre fueron consideradas imposibles antes de que alguien las llevara a cabo”. –Louis D. Brandeis.\t"
    },
    "2025-04-05": {
        "content": "La sonrisa se ha puesto de pie como una hazaña. –Manuel Del Cabral\t"
    },
    "2025-04-06": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2025-04-07": {
        "content": "«El fracaso no es caer, sino negarse a levantarse». –Anónimo\t"
    },
    "2025-04-08": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2025-04-09": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2025-04-10": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2025-04-11": {
        "content": "Quien nos hace reír es un cómico. Quien nos hace pensar y luego reír es un humorista. –George Burns\t"
    },
    "2025-04-12": {
        "content": "Ni siquiera trató de sonreír. Entonces supe que lo había perdido para siempre. –Marian Keyes\t"
    },
    "2025-04-13": {
        "content": "Y que placer, cuando no hay nada, que pueda ver, y solo invento tu sonrisa, y apago así, toda agonía. –Luis Alberto Spinetta\t"
    },
    "2025-04-14": {
        "content": "No llores porque ya se terminó, sonríe porque sucedió. –Gabriel García Marquez\t"
    },
    "2025-04-15": {
        "content": "Podemos experimentar muchas derrotas, pero no debemos permitir que nos derroten”. –Maya Angelou\t"
    },
    "2025-04-16": {
        "content": "Aceptamos la salida del sol y de la luna, sabemos de su poder, de su influencia, incluso les rendimos tributo, pero no alcanzamos a comprender lo que nos puede beneficiar el nacimiento de una sonrisa en nuestro corazón. –Laura Esqivel\t"
    },
    "2025-04-17": {
        "content": "Porque yo, sólo yo, veo su sonrisa una milésima antes de que le llegue a la cara. –David Mitchell\t"
    },
    "2025-04-18": {
        "content": "Sabes lo que es realmente contagioso? Una gran sonrisa. –Scrubs\t"
    },
    "2025-04-19": {
        "content": "Sonríe, a pesar de todo. –Steinberg\t"
    },
    "2025-04-20": {
        "content": " Aunque sonreír es un trabajo de un simple instante, sus efectos duran por mucho tiempo –Bucky Buckbinder\t"
    },
    "2025-04-21": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2025-04-22": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2025-04-23": {
        "content": "Consulta no tus miedos sino tus esperanzas y tus sueños. No pienses en tus frustraciones, sino en tu potencial incumplido. Preocúpate no en lo que has intentado y fracasado, sino en lo que todavía es posible hacer y lograr. –Juan XXIII\t"
    },
    "2025-04-24": {
        "content": " La única batalla real en la vida es la que se da entre elegir aferrarse o dejar que se vaya. –Shannon L. Alder\t"
    },
    "2025-04-25": {
        "content": " Empieza cada día con una sonrisa y mantenla todo el día –W. C. Fields\t"
    },
    "2025-04-26": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2025-04-27": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2025-04-28": {
        "content": "Es maravilloso que nadie tenga que esperar ni un segundo para comenzar a hacer del mundo un lugar mejor”. –Ana Frank\t"
    },
    "2025-04-29": {
        "content": " El poder de una sonrisa es tal que, incluso si dibujas una carita feliz, bastará para hacer que tus labios se muevan de la misma forma –Richelle E. Goodrich\t"
    },
    "2025-04-30": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2025-05-01": {
        "content": "Si haces cada tarea como la vas a hacer por el resto de tu vida, es entonces cuando vas a destacar. –Mary Barra\t"
    },
    "2025-05-02": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2025-05-03": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2025-05-04": {
        "content": " Nunca conseguirás el éxito al menos que te guste lo que estas haciendo. –Dale Carnegie\t"
    },
    "2025-05-05": {
        "content": "Mantente alejado de la gente que intenta menospreciar tus ambiciones. La gente pequeña siempre hace eso, pero la gente realmente grande te hace sentir que tu también puedes ser grande –Mark Twain.\t"
    },
    "2025-05-06": {
        "content": "No importa lo lento que vayas mientras que no pares –Confucio.\t"
    },
    "2025-05-07": {
        "content": "«El éxito es cuestión de actitud, no de aptitud.» –Ralph Waldo Emerson.\t"
    },
    "2025-05-08": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2025-05-09": {
        "content": " Cada sonrisa te hace un día más joven  –Proverbio chino\t"
    },
    "2025-05-10": {
        "content": "«La vida es como montar una bicicleta, para mantener el equilibrio debes seguir adelante». –Albert Einstein, frases de la vida es dura\t"
    },
    "2025-05-11": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2025-05-12": {
        "content": "«El éxito no es para los que sueñan despiertos, sino para aquellos que sueñan y hacen que sus sueños se hagan realidad.» –Jack Canfield.\t"
    },
    "2025-05-13": {
        "content": "Nunca te rindas. Establece una meta y no te rindas hasta que la consigas. Cuando la consigas, establece otro objetivo y no te rindas hasta que lo alcances. Nunca te rindas. –Bear Bryant\t"
    },
    "2025-05-14": {
        "content": "Cambia tus pensamientos y cambiarás tu mundo –Norman Vincent Peale.\t"
    },
    "2025-05-15": {
        "content": "Si un hombre sonríe todo el tiempo, probablemente vende algo que no funciona. –George Carlin\t"
    },
    "2025-05-16": {
        "content": " No tengas miedo de dejar algo bueno para ir a por algo mejor. –John D. Rockefeller\t"
    },
    "2025-05-17": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2025-05-18": {
        "content": "«El éxito no es para los que tienen fuerzas, sino para aquellos que no se rinden.» –Alexandre Dumas.\t"
    },
    "2025-05-19": {
        "content": " No hay ninguna cosa seria que no pueda decirse con una sonrisa –Alejandro Casona\t"
    },
    "2025-05-20": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2025-05-21": {
        "content": "El primer paso es el más importante. Es el más crucial y más efectivo, ya que iniciará la dirección que has elegido. –Steve Backley\t"
    },
    "2025-05-22": {
        "content": " Crecerás el día en que verdaderamente te rías por primera vez de ti mismo –Ethel Barrymore\t"
    },
    "2025-05-23": {
        "content": " En cualquier momento, la decisión de ser feliz está presente. Sólo tenemos que escoger ser feliz. –Steve Maraboli\t"
    },
    "2025-05-24": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2025-05-25": {
        "content": " Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs\t"
    },
    "2025-05-26": {
        "content": "Huye de los rostros graves y solemnes que jamás se distienden en una sonrisa. Huye de los espíritus susceptibles, que por todo se ofenden. –Ricardo León\t"
    },
    "2025-05-27": {
        "content": "Si es posible, se debe hacer reír hasta a los muertos. –Leonardo Da Vinci\t"
    },
    "2025-05-28": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2025-05-29": {
        "content": "La risa es el lenguaje del alma. –Pablo Neruda\t"
    },
    "2025-05-30": {
        "content": "La vida es un 10% lo que me ocurre y 90% cómo reacciono a ello –John Maxwell.\t"
    },
    "2025-05-31": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2025-06-01": {
        "content": " Vamos a conseguir muchas más cosas si pensamos que nada es imposible. –Vince Lombardi\t"
    },
    "2025-06-02": {
        "content": "No puedo dejar de justificar el que se anime a los niños a sonreír antes que a llorar. No deseo pagar por ver otras lágrimas que las del teatro. –Robert Louis Stevenson\t"
    },
    "2025-06-03": {
        "content": "El momento en el que consideras rendirte es justo el momento en el que debes seguir adelante”. –Anónimo\t"
    },
    "2025-06-04": {
        "content": " La felicidad no puede ser la única medida del éxito. –John C. Maxwell\t"
    },
    "2025-06-05": {
        "content": "La sonrisa es la llave que abre el corazón de todo el mundo. –Anthony J. D’Angelo\t"
    },
    "2025-06-06": {
        "content": "Hay tres tipos de personas en este mundo. Primero, están las que hacen que ocurran las cosas. Luego están las que ven cómo ocurren las cosas. Por último, están las que preguntan, ¿qué ha ocurrido? ¿Cuál quieres ser tú? –Steve Backley\t"
    },
    "2025-06-07": {
        "content": "El éxito no es final, el fracaso no es fatal. Es el coraje para continuar lo que cuenta. –Winston S. Churchill\t"
    },
    "2025-06-08": {
        "content": " Lo que mueve al mundo no son los potentes brazos de los héroes, sino la suma de los pequeños empujones de cada trabajador honrado. –Hellen Keller.\t"
    },
    "2025-06-09": {
        "content": "«No importa cuántas veces te caigas, sino cuántas veces te levantes». –Vince Lombardi\t"
    },
    "2025-06-10": {
        "content": "Rodéate de personas que crean en tus sueños, alienten tus ideas, apoyen tus ambiciones y saquen lo mejor de ti”. –Roy T. Bennett\t"
    },
    "2025-06-11": {
        "content": "Cuando la necesidad de éxito es tanta como la necesidad de respirar, entonces serás exitoso –Eric Thomas.\t"
    },
    "2025-06-12": {
        "content": "Pienso, luego existo –René Descartes.\t"
    },
    "2025-06-13": {
        "content": "La mala noticia es que el tiempo vuela, la buena noticia es que tú eres el piloto. –Michael Althsuler\t"
    },
    "2025-06-14": {
        "content": "La definición del propósito es el punto de comienzo de todo logro –Clement Stone.\t"
    },
    "2025-06-15": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2025-06-16": {
        "content": "Sin sonrisa de mujer no hay gloria completa de hombre. –José Marti\t"
    },
    "2025-06-17": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2025-06-18": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2025-06-19": {
        "content": "En cierto momento logré sonreír de nuevo y sentirme mejor. No solo en el corazón, sino en todo mi cuerpo. Caí en la cuenta de que me hacía bien, que tenía que seguir sonriendo. –Yoko Ono\t"
    },
    "2025-06-20": {
        "content": "Espero tu sonrisa y espero tu fragancia por encima de todo, del tiempo y la distancia. –José Angel Buesa\t"
    },
    "2025-06-21": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2025-06-22": {
        "content": "La sonrisa es el trapo que limpia las telarañas del corazón. –Mort Walker\t"
    },
    "2025-06-23": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2025-06-24": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2025-06-25": {
        "content": "El cielo no es un límite para mí, porque no tengo límites en mi vida. Porque la vida es un mundo lleno de riesgos y posibilidades. –Termitope Owosela\t"
    },
    "2025-06-26": {
        "content": "Las más grandes decepciones que sufre el hombre, vienen de sus propias opiniones. –Leonardo DaVinci\t"
    },
    "2025-06-27": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2025-06-28": {
        "content": "Sólo hay un éxito: ser capaz de pasar tu vida a tu manera. –Christopher Morley\t"
    },
    "2025-06-29": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2025-06-30": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2025-07-01": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2025-07-02": {
        "content": "Una sonrisa es una curva que pone todo recto. –Phyllis Diller\t"
    },
    "2025-07-03": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2025-07-04": {
        "content": " Alguien que elige sonreír antes que enfadarse, es una persona realmente fuerte –David Schary\t"
    },
    "2025-07-05": {
        "content": "Opino que lo que se llama belleza, reside únicamente en la sonrisa. –León Tolstoi\t"
    },
    "2025-07-06": {
        "content": "No esperes. Nunca habrá un momento perfecto”. –Napoleon Hill,\t"
    },
    "2025-07-07": {
        "content": " El día en que te halles a ti mismo sonriendo sin razón, ese día puedes decir que has encontrado la felicidad –Rebecca Stead\t"
    },
    "2025-07-08": {
        "content": "Sonríe, es una terapia gratis. –Douglas Horton\t"
    },
    "2025-07-09": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2025-07-10": {
        "content": "El secreto de la existencia humana no solo está en vivir, sino también en saber para qué se vive –Fiódor Dostoievski\t"
    },
    "2025-07-11": {
        "content": "Hay sonrisas que no son de felicidad, sino de un modo de llorar con bondad. –Gabriela Mistral\t"
    },
    "2025-07-12": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2025-07-13": {
        "content": "Era una sonrisa que podría hacer que un hombre creyera que la vida era buena. –John Verdon\t"
    },
    "2025-07-14": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2025-07-15": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2025-07-16": {
        "content": " El trabajo más productivo es el que sale de las manos de un hombre contento. –Victor Pauchet.\t"
    },
    "2025-07-17": {
        "content": "Los colores son la sonrisa de la naturaleza. –Leigh Hunt\t"
    },
    "2025-07-18": {
        "content": "Las limitaciones solo habitan en nuestra mente. Pero si usamos nuestra imaginación, nuestras posibilidades son ilimitadas –Jamie Paolinetti.\t"
    },
    "2025-07-19": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2025-07-20": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2025-07-21": {
        "content": "Supe que eras la indicada cuando tu sonrisa se convirtió en mi cielo, tu risa en mi canción favorita y tus brazos en mi hogar. –Steve Maraboli\t"
    },
    "2025-07-22": {
        "content": "«La vida nos prueba con obstáculos, pero nuestras respuestas a esas pruebas definen nuestra fuerza y determinación». ­–Frases de la vida es dura para reflexionar\t"
    },
    "2025-07-23": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2025-07-24": {
        "content": "Quienes no saben llorar con todo el corazón, tampoco saben sonreír. –Golda Meir\t"
    },
    "2025-07-25": {
        "content": " Estoy convencido de que aproximadamente la mitad de lo que separa a los emprendedores de éxito de los de no  éxito es la perseverancia. –Steve Jobs\t"
    },
    "2025-07-26": {
        "content": "El único hombre que nunca comete un error es el hombre que nunca hace nada. –Theodore Roosevelt\t"
    },
    "2025-07-27": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2025-07-28": {
        "content": " La mayor arma que uno puede portar frente a sus enemigos es una simple…sonrisa –Lionel Suggs\t"
    },
    "2025-07-29": {
        "content": "Si dicen mal de ti con fundamento, corrígete; de lo contrario, échate a reír. –Epicteto de Frigia\t"
    },
    "2025-07-30": {
        "content": "«La vida nos somete a pruebas para mostrarnos que somos más fuertes de lo que imaginamos en nuestros peores momentos». –Las mejores frases de la vida es dura\t"
    },
    "2025-07-31": {
        "content": "Eres asombroso porque estás aquí vivo, luchas cada día para mantenerte de pie. ¿Por qué sufres si tienes la solución en tus manos? ¿Por qué lloras si puedes cambiarlo todo? La vida es hermosa porque existes tú”. –Frases sobre la vida\t"
    },
    "2025-08-01": {
        "content": " El cambio está en el aire. Nos recuerda que fuimos esculpidos por el mismo poder que orquesta el cambio de las estaciones. Que esta sea la estación en la que aceptes el cambio y te alinees con él. –Steve Maraboli\t"
    },
    "2025-08-02": {
        "content": "Comienza donde estás, usa lo que tienes, haz lo que puedes –Arthur Ashe.\t"
    },
    "2025-08-03": {
        "content": " Lo que hoy ves como fracaso puede ser un paso crucial hacia el éxito que estás buscando. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2025-08-04": {
        "content": "La sonrisa es el idioma general de los hombres inteligentes. Sólo son tristes los tontos y los delincuentes. –Víctor Ruiz Iriarte\t"
    },
    "2025-08-05": {
        "content": "Todos deberíamos saber todo el bien que una simple sonrisa puede hacer. –Madre Teresa de Calcuta\t"
    },
    "2025-08-06": {
        "content": "Ella sonrió y su rostro se iluminó. O tal vez fue el mío. Todo lo que sé con certeza es que su sonrisa iluminó hasta mis días más oscuros. –J. Sterling\t"
    },
    "2025-08-07": {
        "content": "Aferrarse a la ira es como beber veneno y esperar que la otra persona muera –Buda.\t"
    },
    "2025-08-08": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2025-08-09": {
        "content": " La risa es la distancia más corta entre dos personas –Víctor Borge\t"
    },
    "2025-08-10": {
        "content": "¿Cuándo regresará esa sonrisa que me desnuda en cuerpo y alma? –Jenniley Bonilla\t"
    },
    "2025-08-11": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2025-08-12": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2025-08-13": {
        "content": "La muerte nos sonríe a todos, devolvámosle la sonrisa. –Gladiator\t"
    },
    "2025-08-14": {
        "content": "Tenía los labios inmóviles, pero cuando lo miré parecieron sonreír sin hacer el más mínimo movimiento. –Anne Rice\t"
    },
    "2025-08-15": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2025-08-16": {
        "content": "Deja de mentirte. Cuando negamos nuestra verdad, negamos nuestro verdadero potencial. –Steve Maraboli\t"
    },
    "2025-08-17": {
        "content": " Cuando deseamos convertirnos en personas de éxito, lo primero que tenemos que hacer es actuar como si ya lo fuésemos. –Gaby Vargas\t"
    },
    "2025-08-18": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2025-08-19": {
        "content": "El éxito no es mágico ni misterioso. El éxito es la consecuencia natural de aplicar los fundamentos básicos. –Jim Rohn\t"
    },
    "2025-08-20": {
        "content": "Creo que podría hacerme sonreír aunque estuviera colgada de una horca. –Jandy Nelson\t"
    },
    "2025-08-21": {
        "content": "Los seres humanos no nacen para siempre el día en que sus madres los alumbran, sino que la vida los obliga a parirse a sí mismos una y otra vez –Gabriel García Márquez.\t"
    },
    "2025-08-22": {
        "content": "Tu trabajo va a llenar una gran parte de tu vida; la única manera de estar verdaderamente satisfecho es hacer lo que crees que es un gran trabajo y la única manera de hacer un gran trabajo es amar lo que haces. Si todavía no lo has encontrado, sigue buscando. No te conformes. Como con todos los asuntos del corazón, sabrás cuando lo encuentres. –Steve Jobs\t"
    },
    "2025-08-23": {
        "content": "No cuentes los días, haz que los días cuenten –Muhammad Ali.\t"
    },
    "2025-08-24": {
        "content": "Frecuentemente me pregunto si esas personas, las que más sonríen, serán en verdad las más tristes en el mundo. –Anthony Liccione\t"
    },
    "2025-08-25": {
        "content": "Si un líder no transmite pasión e intensidad, entonces no habrá pasión e intensidad dentro de la organización y empezarán a caer y deprimirse. –Colin Powell\t"
    },
    "2025-08-26": {
        "content": "No dejes de sonreír… Porque es muy poco el tiempo que te dan para la alegría –Zenaida Bacardí de Argamasilla\t"
    },
    "2025-08-27": {
        "content": "Puede que seas la única persona que crea en ti, pero eso es suficiente. Sólo se necesita una estrella para perforar la oscuridad del universo. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2025-08-28": {
        "content": "Una sonrisa es una línea curva que lo endereza todo –Phyllis Diller\t"
    },
    "2025-08-29": {
        "content": "Por una mirada, un mundo –por una sonrisa, un cielo –por un beso… yo no sé –qué te diera por un beso. –Gustavo Adolfo Bécquer\t"
    },
    "2025-08-30": {
        "content": "No malgastes tu tiempo odiando un fracaso. El fracaso es mejor maestro que el éxito. Escucha, aprende, sigue adelante. –Clarissa Pinkola Estés\t"
    },
    "2025-08-31": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2025-09-01": {
        "content": "Cuando un niño comienza a sonreír, con una pequeña bifurcación de amargor y dulzura, las orillas de su sonrisa desembocan sin burlas en la anarquía del océano. –Osip Mandelshtam\t"
    },
    "2025-09-02": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, serás exitoso». –Albert Schweitzer\t"
    },
    "2025-09-03": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2025-09-04": {
        "content": "Si estás comprometido con algo que realmente te importa, no necesitarás que nadie te empuje: tu visión te impulsará”. –Steve Jobs\t"
    },
    "2025-09-05": {
        "content": "¿Quieres saber quién eres? No preguntes. ¡Actúa! La acción delineará y definirá quién eres –Thomas Jefferson.\t"
    },
    "2025-09-06": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2025-09-07": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2025-09-08": {
        "content": " No le temas al fracaso. Es el camino al éxito. –LeBron James\t"
    },
    "2025-09-09": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2025-09-10": {
        "content": " Hoy es el único día sobre el que tenemos poder. –Steve Maraboli\t"
    },
    "2025-09-11": {
        "content": "Quítame el pan, si quieres, quítame el aire, pero no me quites tu risa. –Pablo Neruda\t"
    },
    "2025-09-12": {
        "content": "Deja que mi alma sonría a través de mi corazón y que mi corazón sonría a través de mis ojos, para que pueda esparcir ricas sonrisas en corazones tristes. –Paramahansa Yogananda\t"
    },
    "2025-09-13": {
        "content": " La sonrisa enriquece a los que la reciben, sin empobrecer a los que la dan –Frank Irving\t"
    },
    "2025-09-14": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2025-09-15": {
        "content": " La vida es corta. Vive apasionadamente. –Marc A. Pitma\t"
    },
    "2025-09-16": {
        "content": "La paz comienza con una sonrisa. –Madre Teresa\t"
    },
    "2025-09-17": {
        "content": "Si mi estupidez te hace sonreír, prefiero entonces actuar siempre como un estúpido. –M.F. Moonzajer\t"
    },
    "2025-09-18": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2025-09-19": {
        "content": "Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs.\t"
    },
    "2025-09-20": {
        "content": " Nunca dejes de sonreír, ni siquiera cuando estés triste, porque nunca sabes quien se puede enamorar de tu sonrisa –Gabriel García Márquez\t"
    },
    "2025-09-21": {
        "content": "Me sonrió como no podría hacerlo un hombre, como si las mujeres lo supieran todo unas de las otras desde la eternidad. –Marcela serrano\t"
    },
    "2025-09-22": {
        "content": " La buena gestión consiste en mostrar a la gente común cómo hacer el trabajo de las personas superiores. –John D. Rockefeller\t"
    },
    "2025-09-23": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2025-09-24": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2025-09-25": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2025-09-26": {
        "content": "Aunque sentí que estaba a punto de llorar, opté por sonreír. –Kristen Ashley\t"
    },
    "2025-09-27": {
        "content": "Cuando ella sonreía, él se olvidaba de todo. –Palmeras en la nieve\t"
    },
    "2025-09-28": {
        "content": "Su sonrisa podía parar el corazón de un hombre. –El nombre del viento\t"
    },
    "2025-09-29": {
        "content": "Cuando tengas fallas, no temas abandonarlas. –Confucio\t"
    },
    "2025-09-30": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2025-10-01": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2025-10-02": {
        "content": "Después de cada tormenta sonríe el sol; para cada problema hay una solución y el deber irrenunciable del alma es estar de buen ánimo. –William R. Alger\t"
    },
    "2025-10-03": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2025-10-04": {
        "content": "Si te pones a ti mismo en una posición en la que tienes que salir de tu zona de confort, estas forzando la expansión de tu consciencia. –Les Brown\t"
    },
    "2025-10-05": {
        "content": "La vida se encoge o se expande en proporción al coraje de uno –Anais Nin.\t"
    },
    "2025-10-06": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito.» –Albert Schweitzer.\t"
    },
    "2025-10-07": {
        "content": "Mira hacia atrás y sonríe ante los peligros del pasado. –Walter Scott\t"
    },
    "2025-10-08": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes. –Víctor Ruiz Iriarte\t"
    },
    "2025-10-09": {
        "content": "Si vas en busca del amor nunca lo encontrarás porque el amor nunca se perdió; sólo nosotros nos perdemos. –Shannon L. Alder\t"
    },
    "2025-10-10": {
        "content": "Las arrugas solo deberían indicar donde las sonrisas han estado. –Mark Twain\t"
    },
    "2025-10-11": {
        "content": "No importa lo que la gente diga, las palabras y las ideas tienen el poder de cambiar el mundo”. –Robin Williams\t"
    },
    "2025-10-12": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2025-10-13": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2025-10-14": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2025-10-15": {
        "content": "La mejor preparación para mañana es hacer lo mejor que puedas hoy –H. Jackson Brown, Jr.\t"
    },
    "2025-10-16": {
        "content": "Si la vida no te diera al menos una persona que no quiera que lo logres, entonces la mitad de nosotros perdería la motivación de escalar el acantilado sólo para probar que se equivocan. –Shannon L. Alder\t"
    },
    "2025-10-17": {
        "content": "La forma de arruinar la vida de alguien es darle todo lo que quiere. –Patrick Swayze\t"
    },
    "2025-10-18": {
        "content": "Cuando uno nace sólo sabe llorar. Sólo gradualmente se aprende a sonreír. –Halldór Laxness\t"
    },
    "2025-10-19": {
        "content": "El niño reconoce a la madre por la sonrisa. –Leon Tolstoi\t"
    },
    "2025-10-20": {
        "content": "Haz que se pregunten por qué sigues sonriendo. –Elizabethtown\t"
    },
    "2025-10-21": {
        "content": "Suspiró y después sonrió con la misma sonrisa que, sin importar cuántas veces la viese, siempre le robaba el corazón. Cada vez que sonreía volvía a ser la primera vez. –Neil Gaiman\t"
    },
    "2025-10-22": {
        "content": "«El único modo de hacer un gran trabajo es amar lo que haces». –Steve Jobs\t"
    },
    "2025-10-23": {
        "content": " No hay razón para tener un plan B, porque te distrae del plan A. –Will Smith\t"
    },
    "2025-10-24": {
        "content": "Hoy llevo puesta la sonrisa que me regalaste. –Edith Castro\t"
    },
    "2025-10-25": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2025-10-26": {
        "content": "Nunca permitiré que me vuelva tan importante, sabio, reservado y poderoso, que no pueda sonreír a los demás y reírme de mí mismo. –Og Mandino\t"
    },
    "2025-10-27": {
        "content": "Estaba sonriendo ayer, estoy sonriendo hoy y sonreiré mañana. Simplemente porque la vida es muy corta para llorar por cualquier cosa. –Santosh Kalwar\t"
    },
    "2025-10-28": {
        "content": "Una sonrisa es la felicidad que encontrarás justo debajo te tu nariz. –Tom Wilson\t"
    },
    "2025-10-29": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2025-10-30": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2025-10-31": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2025-11-01": {
        "content": "De humanos es errar y de necios permanecer en el error –Marco Tulio Cicerón\t"
    },
    "2025-11-02": {
        "content": "El corazón alegre hermosea el rostro. –Rey Salomón\t"
    },
    "2025-11-03": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2025-11-04": {
        "content": "A veces, cuando innovas, cometes errores. Lo mejor es admitirlos rápidamente y seguir mejorando otras innovaciones. –Steve Jobs\t"
    },
    "2025-11-05": {
        "content": "Para tener éxito hoy, tienes que fijar prioridades, decidir lo que representas. –Lee Laccoca\t"
    },
    "2025-11-06": {
        "content": "No llores porque terminó. Sonríe porque ocurrió. –Dr. Seuss.\t"
    },
    "2025-11-07": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2025-11-08": {
        "content": "No importa si te derriban, lo importante es si te levantas cuando sucede”. –Vince Lombardi\t"
    },
    "2025-11-09": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2025-11-10": {
        "content": "Las guerras seguirán mientras el color de la piel siga siendo más importante que el de los ojos –Bob Marley.\t"
    },
    "2025-11-11": {
        "content": " ¿Cómo serías si fueses la única persona del mundo? Si quieres ser realmente feliz, debes ser esa persona. –Quetin Crisp\t"
    },
    "2025-11-12": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2025-11-13": {
        "content": "Si estás leyendo esto… Felicitaciones, estás vivo. Si eso no es algo para sonreír, entonces no sé qué es. –Chad Sugg\t"
    },
    "2025-11-14": {
        "content": " Nunca te sorprendas por tu éxito. –Steve Maraboli\t"
    },
    "2025-11-15": {
        "content": "Para ser exitoso hay que seguir el camino de los gigantes. –Lillian Cauldwell\t"
    },
    "2025-11-16": {
        "content": "El éxito es un camino, no un destino. –Ben Sweetland\t"
    },
    "2025-11-17": {
        "content": " A menudo las personas quedan atrapadas en un estado de sobre  pensamiento, y el resultado es que nunca toman una decisión. –Steve Backley\t"
    },
    "2025-11-18": {
        "content": "Sonreímos, pero quiero más, quiero que me abraces. –M.J. Hayland\t"
    },
    "2025-11-19": {
        "content": " A veces, no obtener lo que quieres es un golpe de suerte brillante. –Lorii Myers\t"
    },
    "2025-11-20": {
        "content": "Si de verdad quieres comer, sigue escalando. Las frutas están en la cima del árbol. Estira tus brazos y sigue estirándolos. El éxito está en la cima, sigue adelante. –Isralemore Ayivor\t"
    },
    "2025-11-21": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2025-11-22": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2025-11-23": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2025-11-24": {
        "content": "Nadie puede hacerte sentir inferior sin tu consentimiento”. –Eleanor Roosevelt\t"
    },
    "2025-11-25": {
        "content": "Una sonrisa es la forma más barata de cambiar nuestro aspecto. –Charles Gordu\t"
    },
    "2025-11-26": {
        "content": "Una sonrisa puede iluminar los lugares más oscuros. –Steve Maraboli\t"
    },
    "2025-11-27": {
        "content": " El día peor empleado es aquél en que no se ha reído –Nicolás  Sebastien Roch Chamfort\t"
    },
    "2025-11-28": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2025-11-29": {
        "content": "No puedo mantenerme enojado cuando me sonríes. –M.F. Moonzajer\t"
    },
    "2025-11-30": {
        "content": "Elimina de tu vida todo aquello que te cause estrés y te quite la sonrisa. –Paulo Coelho\t"
    },
    "2025-12-01": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2025-12-02": {
        "content": " No te sientas avergonzado de intentarlo y fallar, puesto que la persona que nunca ha fallado es alguien que nunca lo ha intentado. –Og Mandino\t"
    },
    "2025-12-03": {
        "content": "La mayor declaración de amor es la que no se hace; el hombre que siente mucho, habla poco –Platón.\t"
    },
    "2025-12-04": {
        "content": "El hombre verdadero sonríe ante los problemas, toma fuerza de la angustia y crece valiente ante la decepción. –Thomas Paine\t"
    },
    "2025-12-05": {
        "content": "El pesimista ve dificultades en cada oportunidad. El optimista ve oportunidades en cada dificultad”. –Winston Churchill\t"
    },
    "2025-12-06": {
        "content": "Si he visto más lejos que otros, es por estar de pie sobre los hombros de gigantes –Isaac Newton.\t"
    },
    "2025-12-07": {
        "content": "Hay un solo rincón del universo que puedes estar seguro de poder mejorar, y eres tú mismo –Aldous Huxley.\t"
    },
    "2025-12-08": {
        "content": "El comportamiento humano fluye de tres fuentes: emoción, deseo y conocimiento –Platón.\t"
    },
    "2025-12-09": {
        "content": "Las sonrisas más auténticas son aquellas que se esparcen en nuestras caras cuando nadie nos está mirando. –Minhal Mehdi\t"
    },
    "2025-12-10": {
        "content": "No puedo darte una fórmula segura para el éxito, pero puedo darte una para el fracaso: intenta complacer a todo el mundo todo el tiempo. –Herbert Bayard Swope\t"
    },
    "2025-12-11": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2025-12-12": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2025-12-13": {
        "content": " Comparte tus aspiraciones con aquellos que te vayan a apoyar, no con aquellos que respondan con duda o falta de interés. –Steve Backley\t"
    },
    "2025-12-14": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2025-12-15": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2025-12-16": {
        "content": "Ve tan lejos como puedas ver y verás más lejos. –Zig Ziglar\t"
    },
    "2025-12-17": {
        "content": " La diferencia entre la codicia y la ambición es que la persona codiciosa desea cosas para las que no está preparada. –Habeeb Akande\t"
    },
    "2025-12-18": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2025-12-19": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2025-12-20": {
        "content": "Teresa de la dulce sonrisa; porque mientras las demás se esforzaban una y otra vez en superarla, ella simplemente las derrotaba con una sonrisa en su rostro. –Claymore\t"
    },
    "2025-12-21": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2025-12-22": {
        "content": "«En medio de la dificultad yace la oportunidad». –Albert Einstein\t"
    },
    "2025-12-23": {
        "content": "Mi padre siempre me decía: encuentra un trabajo que te guste y no tendrás que trabajar un solo día de tu vida. –Jim Fox.\t"
    },
    "2025-12-24": {
        "content": " El éxito es hacer lo que sea que quieras hacer, cuando quieras, donde quieras, con quien quieras y por el tiempo que quieras. –Anthony Robbins\t"
    },
    "2025-12-25": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2025-12-26": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2025-12-27": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2025-12-28": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2025-12-29": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2025-12-30": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2025-12-31": {
        "content": "¿Sabias que. . . Cuando pasas caminando por una flor, sea en algún jardín o en un alguna ladera, la flor siempre te sonríe? la forma más cortés de responder, me han dicho, es devolver la sonrisa alegremente. –Ron Atchison\t"
    },
    "2026-01-01": {
        "content": " Lo que hoy ves como fracaso puede ser un paso crucial hacia el éxito que estás buscando. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2026-01-02": {
        "content": " La vida es corta. Vive apasionadamente. –Marc A. Pitma\t"
    },
    "2026-01-03": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2026-01-04": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2026-01-05": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2026-01-06": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2026-01-07": {
        "content": " El trabajo más productivo es el que sale de las manos de un hombre contento. –Victor Pauchet.\t"
    },
    "2026-01-08": {
        "content": " El éxito no es comparar lo que hemos hecho con lo que los demás han hecho. –Myles Munroe\t"
    },
    "2026-01-09": {
        "content": "Si la vida no te diera al menos una persona que no quiera que lo logres, entonces la mitad de nosotros perdería la motivación de escalar el acantilado sólo para probar que se equivocan. –Shannon L. Alder\t"
    },
    "2026-01-10": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2026-01-11": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2026-01-12": {
        "content": "Permanece firme a tus principios, pero siempre abierto a aprender. Trabaja duro, nunca renuncies a tus sueños, incluso cuando nadie crea que puedan hacerse realidad excepto tu. Estos no son clichés, sino que son herramientas auténticas que necesitas para estar concentrado en tu trayectoria, no importa a lo que te dediques en tu vida. –Phillip Sweet\t"
    },
    "2026-01-13": {
        "content": "Sé amable, ya que cada persona que conoces está enfrentando una batalla interna”. –Platón\t"
    },
    "2026-01-14": {
        "content": "Suspiró y después sonrió con la misma sonrisa que, sin importar cuántas veces la viese, siempre le robaba el corazón. Cada vez que sonreía volvía a ser la primera vez. –Neil Gaiman\t"
    },
    "2026-01-15": {
        "content": "Las limitaciones solo habitan en nuestra mente. Pero si usamos nuestra imaginación, nuestras posibilidades son ilimitadas –Jamie Paolinetti.\t"
    },
    "2026-01-16": {
        "content": "Cada día sabemos más y entendemos menos –Albert Einstein.\t"
    },
    "2026-01-17": {
        "content": "Comienza donde estás, usa lo que tienes, haz lo que puedes –Arthur Ashe.\t"
    },
    "2026-01-18": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2026-01-19": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2026-01-20": {
        "content": " La única batalla real en la vida es la que se da entre elegir aferrarse o dejar que se vaya. –Shannon L. Alder\t"
    },
    "2026-01-21": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2026-01-22": {
        "content": "Me sonrió como no podría hacerlo un hombre, como si las mujeres lo supieran todo unas de las otras desde la eternidad. –Marcela serrano\t"
    },
    "2026-01-23": {
        "content": "Comienza y termina el día con una sonrisa. –W.C. Fields\t"
    },
    "2026-01-24": {
        "content": " Nunca te sorprendas por tu éxito. –Steve Maraboli\t"
    },
    "2026-01-25": {
        "content": "La lógica te llevará de la A a la Z, pero la imaginación te llevará a cualquier lugar”. –Albert Einstein\t"
    },
    "2026-01-26": {
        "content": "Sin sonrisa de mujer no hay gloria completa de hombre. –José Marti\t"
    },
    "2026-01-27": {
        "content": "Aceptamos la salida del sol y de la luna, sabemos de su poder, de su influencia, incluso les rendimos tributo, pero no alcanzamos a comprender lo que nos puede beneficiar el nacimiento de una sonrisa en nuestro corazón. –Laura Esqivel\t"
    },
    "2026-01-28": {
        "content": "Quítame el pan, si quieres, quítame el aire, pero no me quites tu risa. –Pablo Neruda\t"
    },
    "2026-01-29": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2026-01-30": {
        "content": "«En medio de la dificultad yace la oportunidad». –Albert Einstein\t"
    },
    "2026-01-31": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2026-02-01": {
        "content": "El hombre está condenado a ser libre –Jean  Paul Sartre.\t"
    },
    "2026-02-02": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2026-02-03": {
        "content": "A menudo subestimamos el poder de una caricia, una sonrisa, una palabra amable, un oído atento, un cumplido honesto o el acto más pequeño de cariño, todos los cuales tienen el potencial de cambiar una vida. –Leo Buscaglia\t"
    },
    "2026-02-04": {
        "content": " No dejes que los bloqueos mentales te controlen. Libérate. Enfrenta tus miedos y transforma los bloqueos mentales en bloques de construcción. –Roopleen\t"
    },
    "2026-02-05": {
        "content": "Las arrugas solo deberían indicar donde las sonrisas han estado. –Mark Twain\t"
    },
    "2026-02-06": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2026-02-07": {
        "content": "La mayor declaración de amor es la que no se hace; el hombre que siente mucho, habla poco –Platón.\t"
    },
    "2026-02-08": {
        "content": " El secreto del éxito es mantener una imagen de un resultado exitoso en la mente. –Henry David Thoreau\t"
    },
    "2026-02-09": {
        "content": "Los ganadores se toman su tiempo para realizar su trabajo, sabiendo que escalar la montaña es lo que hace la vista tan estimulante. –Denis Waitley.\t"
    },
    "2026-02-10": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2026-02-11": {
        "content": " El día en que te halles a ti mismo sonriendo sin razón, ese día puedes decir que has encontrado la felicidad –Rebecca Stead\t"
    },
    "2026-02-12": {
        "content": " La mayor arma que uno puede portar frente a sus enemigos es una simple…sonrisa –Lionel Suggs\t"
    },
    "2026-02-13": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2026-02-14": {
        "content": "«El único modo de hacer un gran trabajo es amar lo que haces». –Steve Jobs\t"
    },
    "2026-02-15": {
        "content": "En cierto momento logré sonreír de nuevo y sentirme mejor. No solo en el corazón, sino en todo mi cuerpo. Caí en la cuenta de que me hacía bien, que tenía que seguir sonriendo. –Yoko Ono\t"
    },
    "2026-02-16": {
        "content": "Ve definitivamente en dirección de tus sueños. Vive la vida que imaginaste tener –Henry David Thoreau.\t"
    },
    "2026-02-17": {
        "content": " El futuro pertenece a los que creen en la belleza de sus sueños. –Eleanor Roosevelt\t"
    },
    "2026-02-18": {
        "content": "La vida se encoge o se expande en proporción al coraje de uno –Anais Nin.\t"
    },
    "2026-02-19": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2026-02-20": {
        "content": "Sólo hay un éxito: ser capaz de pasar tu vida a tu manera. –Christopher Morley\t"
    },
    "2026-02-21": {
        "content": "La mejor parte de la belleza es aquella que ninguna imagen puede expresar –Francis Bacon.\t"
    },
    "2026-02-22": {
        "content": " La felicidad no puede ser la única medida del éxito. –John C. Maxwell\t"
    },
    "2026-02-23": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, serás exitoso». –Albert Schweitzer\t"
    },
    "2026-02-24": {
        "content": "Una sonrisa cálida es el lenguaje universal de la amabilidad. –William Arthur Ward\t"
    },
    "2026-02-25": {
        "content": " No tengas miedo de dejar algo bueno para ir a por algo mejor. –John D. Rockefeller\t"
    },
    "2026-02-26": {
        "content": "Hacer sonreír a una persona significa para mi más que cualquier cosa. –Michael Jackson\t"
    },
    "2026-02-27": {
        "content": "Si no usas tu sonrisa, eres como un hombre con un millón de dolares en el banco y sin poder sacarlos. –Les Giblin\t"
    },
    "2026-02-28": {
        "content": "Nunca te rindas. Establece una meta y no te rindas hasta que la consigas. Cuando la consigas, establece otro objetivo y no te rindas hasta que lo alcances. Nunca te rindas. –Bear Bryant\t"
    },
    "2026-03-01": {
        "content": "Quiero borrar las lágrimas de todos los que confiaron en la esperanza y se las quitaron. Quiero que se queden con una sonrisa en sus rostros. –Cuarteto Magica\t"
    },
    "2026-03-02": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2026-03-03": {
        "content": "Las sonrisas más auténticas son aquellas que se esparcen en nuestras caras cuando nadie nos está mirando. –Minhal Mehdi\t"
    },
    "2026-03-04": {
        "content": " Nunca te olvides de sonreír porque el día que no sonrías será un día perdido –Charles Chaplin\t"
    },
    "2026-03-05": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2026-03-06": {
        "content": " El éxito es un mal maestro. –Robert T. Kiyosaki\t"
    },
    "2026-03-07": {
        "content": "La auténtica motivación proviene de trabajar en cosas que nos importan”. –Sheryl Sandberg\t"
    },
    "2026-03-08": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2026-03-09": {
        "content": "Rodéate de personas que crean en tus sueños, alienten tus ideas, apoyen tus ambiciones y saquen lo mejor de ti”. –Roy T. Bennett\t"
    },
    "2026-03-10": {
        "content": "Tu mejor maestro es tu último error. –Ralph Nader\t"
    },
    "2026-03-11": {
        "content": "Es maravilloso que nadie tenga que esperar ni un segundo para comenzar a hacer del mundo un lugar mejor”. –Ana Frank\t"
    },
    "2026-03-12": {
        "content": "Los sueños son sumamente importantes. Nada se hace sin que antes se imagine –George Lucas.\t"
    },
    "2026-03-13": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2026-03-14": {
        "content": " Prende tu sonrisa, ríe sin razón. Tú controlas el interruptor de la felicidad, eres tú quien cambia el mundo –Roel van Sleeuwen\t"
    },
    "2026-03-15": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2026-03-16": {
        "content": "Muchos desean cambiar el mundo, pero pocos piensan en cambiarse a sí mismos”. –León Tolstói\t"
    },
    "2026-03-17": {
        "content": "La paz comienza con una sonrisa. –Madre Teresa\t"
    },
    "2026-03-18": {
        "content": "¿Sabias que. . . Cuando pasas caminando por una flor, sea en algún jardín o en un alguna ladera, la flor siempre te sonríe? la forma más cortés de responder, me han dicho, es devolver la sonrisa alegremente. –Ron Atchison\t"
    },
    "2026-03-19": {
        "content": "La sonrisa es una verdadera fuerza vital, la única capaz de mover lo inconmovible –Orison Swett Marden\t"
    },
    "2026-03-20": {
        "content": "Eres asombroso porque estás aquí vivo, luchas cada día para mantenerte de pie. ¿Por qué sufres si tienes la solución en tus manos? ¿Por qué lloras si puedes cambiarlo todo? La vida es hermosa porque existes tú”. –Frases sobre la vida\t"
    },
    "2026-03-21": {
        "content": "La sonrisa es el trapo que limpia las telarañas del corazón. –Mort Walker\t"
    },
    "2026-03-22": {
        "content": "Cuando tienes la habilidad de hacer lo que amas y amar lo que haces, tienes la habilidad de impactar a las personas. Eso es lo que es tener una vida exitosa. Eso es lo que es tener una vida con sentido. –Tim Tebow\t"
    },
    "2026-03-23": {
        "content": "Si estás comprometido con algo que realmente te importa, no necesitarás que nadie te empuje: tu visión te impulsará”. –Steve Jobs\t"
    },
    "2026-03-24": {
        "content": "La sonrisa es la llave que abre el corazón de todo el mundo. –Anthony J. D’Angelo\t"
    },
    "2026-03-25": {
        "content": "El éxito y la felicidad dependen de ti. –Helen Keller\t"
    },
    "2026-03-26": {
        "content": "Puede que seas la única persona que crea en ti, pero eso es suficiente. Sólo se necesita una estrella para perforar la oscuridad del universo. Nunca te rindas. –Richelle E. Goodrich\t"
    },
    "2026-03-27": {
        "content": "Cuando tengas fallas, no temas abandonarlas. –Confucio\t"
    },
    "2026-03-28": {
        "content": "Una sonrisa puede iluminar los lugares más oscuros. –Steve Maraboli\t"
    },
    "2026-03-29": {
        "content": "El pesimista ve dificultades en cada oportunidad. El optimista ve oportunidades en cada dificultad”. –Winston Churchill\t"
    },
    "2026-03-30": {
        "content": "El hombre incapaz de reír no solamente es apto para las traiciones, las estratagemas y los fraudes, sino que su vida entera ya es una traición y una estratagema. –Thomas Carlyle\t"
    },
    "2026-03-31": {
        "content": "Una sonrisa es la forma más barata de cambiar nuestro aspecto. –Charles Gordu\t"
    },
    "2026-04-01": {
        "content": "Haz de tu sonrisa un paraguas y deja que llueva. –Twin Peaks\t"
    },
    "2026-04-02": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2026-04-03": {
        "content": "Las más grandes decepciones que sufre el hombre, vienen de sus propias opiniones. –Leonardo DaVinci\t"
    },
    "2026-04-04": {
        "content": "«El éxito es cuestión de actitud, no de aptitud.» –Ralph Waldo Emerson.\t"
    },
    "2026-04-05": {
        "content": " A menudo las personas quedan atrapadas en un estado de sobre  pensamiento, y el resultado es que nunca toman una decisión. –Steve Backley\t"
    },
    "2026-04-06": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2026-04-07": {
        "content": "«El éxito es la capacidad de ir de fracaso en fracaso sin perder el entusiasmo.» –Winston Churchill.\t"
    },
    "2026-04-08": {
        "content": "No puedo mantenerme enojado cuando me sonríes. –M.F. Moonzajer\t"
    },
    "2026-04-09": {
        "content": "No malgastes tu tiempo odiando un fracaso. El fracaso es mejor maestro que el éxito. Escucha, aprende, sigue adelante. –Clarissa Pinkola Estés\t"
    },
    "2026-04-10": {
        "content": "Elimina de tu vida todo aquello que te cause estrés y te quite la sonrisa. –Paulo Coelho\t"
    },
    "2026-04-11": {
        "content": "No puedo darte una fórmula segura para el éxito, pero puedo darte una para el fracaso: intenta complacer a todo el mundo todo el tiempo. –Herbert Bayard Swope\t"
    },
    "2026-04-12": {
        "content": "«La vida nos prueba con obstáculos, pero nuestras respuestas a esas pruebas definen nuestra fuerza y determinación». ­–Frases de la vida es dura para reflexionar\t"
    },
    "2026-04-13": {
        "content": " La diferencia entre la codicia y la ambición es que la persona codiciosa desea cosas para las que no está preparada. –Habeeb Akande\t"
    },
    "2026-04-14": {
        "content": " Participa en tus propios sueños. No digas solamente lo que quieres o te quejes de lo que no tienes. –Steve Maraboli\t"
    },
    "2026-04-15": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2026-04-16": {
        "content": "Deja de mentirte. Cuando negamos nuestra verdad, negamos nuestro verdadero potencial. –Steve Maraboli\t"
    },
    "2026-04-17": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2026-04-18": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2026-04-19": {
        "content": "No hay caminos para la paz; la paz es el camino –Mahatma Gandhi.\t"
    },
    "2026-04-20": {
        "content": "Los colores son la sonrisa de la naturaleza. –Leigh Hunt\t"
    },
    "2026-04-21": {
        "content": "El momento en el que consideras rendirte es justo el momento en el que debes seguir adelante”. –Anónimo\t"
    },
    "2026-04-22": {
        "content": " Sin importar lo fácil o difícil que sea la vida, siempre encontraré la manera de disfrutar. Incluso cuando las circunstancias sean adversas. –Termitope Owosela\t"
    },
    "2026-04-23": {
        "content": "El éxito no es final, el fracaso no es fatal. Es el coraje para continuar lo que cuenta. –Winston S. Churchill\t"
    },
    "2026-04-24": {
        "content": "Nunca permitiré que me vuelva tan importante, sabio, reservado y poderoso, que no pueda sonreír a los demás y reírme de mí mismo. –Og Mandino\t"
    },
    "2026-04-25": {
        "content": "Sonreír en la presencia del peligro no significa que niegas el miedo, simplemente te ayudará a superarlo. –Richelle E. Goodrich\t"
    },
    "2026-04-26": {
        "content": "Si solo tienes una sonrisa, dásela a la gente que amas. –Maya Angelou\t"
    },
    "2026-04-27": {
        "content": "Si algo me gusta, es vivir. Ver mi cuerpo en la calle, hablar contigo como un camarada, mirar escaparates y, sobre todo, sonreír de lejos a los árboles. –Blas de Otero\t"
    },
    "2026-04-28": {
        "content": "Una sonrisa es la única aflicción infecciosa que animo a todos a que propaguen. –Omar Kiam\t"
    },
    "2026-04-29": {
        "content": " La sonrisa enriquece a los que la reciben, sin empobrecer a los que la dan –Frank Irving\t"
    },
    "2026-04-30": {
        "content": "El corazón alegre hermosea el rostro. –Rey Salomón\t"
    },
    "2026-05-01": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2026-05-02": {
        "content": "«El fracaso no es caer, sino negarse a levantarse». –Anónimo\t"
    },
    "2026-05-03": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2026-05-04": {
        "content": "No puedo esperar a hacerlo, quiero hacerlo ahora, quiero que ocurra si va a ocurrir –Georgia O’Keeffe.\t"
    },
    "2026-05-05": {
        "content": "Mantente alejado de la gente que intenta menospreciar tus ambiciones. La gente pequeña siempre hace eso, pero la gente realmente grande te hace sentir que tu también puedes ser grande –Mark Twain.\t"
    },
    "2026-05-06": {
        "content": "No llores porque terminó. Sonríe porque ocurrió. –Dr. Seuss.\t"
    },
    "2026-05-07": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2026-05-08": {
        "content": "No se trata de si te derriban, se trata de si te levantas –Vince Lombardi.\t"
    },
    "2026-05-09": {
        "content": "Nunca eres demasiado mayor para establecer nuevas metas o tener nuevos sueños”. –C.S. Lewis\t"
    },
    "2026-05-10": {
        "content": "El primer paso es el más importante. Es el más crucial y más efectivo, ya que iniciará la dirección que has elegido. –Steve Backley\t"
    },
    "2026-05-11": {
        "content": "Una sonrisa es una curva que pone todo recto. –Phyllis Diller\t"
    },
    "2026-05-12": {
        "content": " La risa cura, es la obra social más barata y efectiva del mundo –Roberto Pettinato\t"
    },
    "2026-05-13": {
        "content": "La forma de empezar es dejar de hablar y empezar a actuar   –Walt Disney\t"
    },
    "2026-05-14": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2026-05-15": {
        "content": "La muerte nos sonríe a todos, devolvámosle la sonrisa. –Gladiator\t"
    },
    "2026-05-16": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2026-05-17": {
        "content": "«La vida no se trata de esperar a que pase la tormenta, se trata de aprender a bailar bajo la lluvia». –Vivian Greene\t"
    },
    "2026-05-18": {
        "content": "Frecuentemente me pregunto si esas personas, las que más sonríen, serán en verdad las más tristes en el mundo. –Anthony Liccione\t"
    },
    "2026-05-19": {
        "content": "A veces, cuando innovas, cometes errores. Lo mejor es admitirlos rápidamente y seguir mejorando otras innovaciones. –Steve Jobs\t"
    },
    "2026-05-20": {
        "content": " A veces tu alegría es la fuente de tu sonrisa, pero a veces tu sonrisa puede ser la fuente de tu alegría –Thích Nhất Hạnh\t"
    },
    "2026-05-21": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2026-05-22": {
        "content": "La perseverancia y la energía conquistan todo”. –Benjamin Franklin\t"
    },
    "2026-05-23": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2026-05-24": {
        "content": "Ve tan lejos como puedas ver y verás más lejos. –Zig Ziglar\t"
    },
    "2026-05-25": {
        "content": "El hombre que nos hace reír tiene mas votos para su propósito que el hombre que nos exige pensar. –Malcom de Chazall\t"
    },
    "2026-05-26": {
        "content": " Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs\t"
    },
    "2026-05-27": {
        "content": "Y que placer, cuando no hay nada, que pueda ver, y solo invento tu sonrisa, y apago así, toda agonía. –Luis Alberto Spinetta\t"
    },
    "2026-05-28": {
        "content": "De humanos es errar y de necios permanecer en el error –Marco Tulio Cicerón\t"
    },
    "2026-05-29": {
        "content": "Quisiera tener varias sonrisas de recambio y un vasto repertorio de modos de expresarme. –Concha Méndez\t"
    },
    "2026-05-30": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2026-05-31": {
        "content": "Cuando uno nace sólo sabe llorar. Sólo gradualmente se aprende a sonreír. –Halldór Laxness\t"
    },
    "2026-06-01": {
        "content": "La sonrisa se ha puesto de pie como una hazaña. –Manuel Del Cabral\t"
    },
    "2026-06-02": {
        "content": "El éxito consiste en ir de fracaso en fracaso sin pérdida de entusiasmo. –Winston Churchill\t"
    },
    "2026-06-03": {
        "content": " No hay razón para tener un plan B, porque te distrae del plan A. –Will Smith\t"
    },
    "2026-06-04": {
        "content": " Crecerás el día en que verdaderamente te rías por primera vez de ti mismo –Ethel Barrymore\t"
    },
    "2026-06-05": {
        "content": "Estaba sonriendo ayer, estoy sonriendo hoy y sonreiré mañana. Simplemente porque la vida es muy corta para llorar por cualquier cosa. –Santosh Kalwar\t"
    },
    "2026-06-06": {
        "content": "Aprende a vivir y sabrás morir bien –Confucio.\t"
    },
    "2026-06-07": {
        "content": "Después de cada tormenta sonríe el sol; para cada problema hay una solución y el deber irrenunciable del alma es estar de buen ánimo. –William R. Alger\t"
    },
    "2026-06-08": {
        "content": "Pienso, luego existo –René Descartes.\t"
    },
    "2026-06-09": {
        "content": "El dar de mala gana es grosería. Nada cuesta añadir una sonrisa. –Jean de La Bruyère\t"
    },
    "2026-06-10": {
        "content": "«No importa cuántas veces te caigas, sino cuántas veces te levantes». –Vince Lombardi\t"
    },
    "2026-06-11": {
        "content": "Encontrarás la clave del éxito debajo de la alarma del reloj. –Benjamin Franklin\t"
    },
    "2026-06-12": {
        "content": "La vida es un 10% lo que me ocurre y 90% cómo reacciono a ello –John Maxwell.\t"
    },
    "2026-06-13": {
        "content": "Cuando la necesidad de éxito es tanta como la necesidad de respirar, entonces serás exitoso –Eric Thomas.\t"
    },
    "2026-06-14": {
        "content": "Son tus ojos los que busco, son tus labios los que quiero ver sonreír. –Violeta Parra\t"
    },
    "2026-06-15": {
        "content": "Si es posible, se debe hacer reír hasta a los muertos. –Leonardo Da Vinci\t"
    },
    "2026-06-16": {
        "content": "La definición del propósito es el punto de comienzo de todo logro –Clement Stone.\t"
    },
    "2026-06-17": {
        "content": "Si un hombre sonríe todo el tiempo, probablemente vende algo que no funciona. –George Carlin\t"
    },
    "2026-06-18": {
        "content": "La sonrisa es el idioma general de los hombres inteligentes. Sólo son tristes los tontos y los delincuentes. –Víctor Ruiz Iriarte\t"
    },
    "2026-06-19": {
        "content": "Deja que mi alma sonría a través de mi corazón y que mi corazón sonría a través de mis ojos, para que pueda esparcir ricas sonrisas en corazones tristes. –Paramahansa Yogananda\t"
    },
    "2026-06-20": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2026-06-21": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2026-06-22": {
        "content": " ¿Cómo serías si fueses la única persona del mundo? Si quieres ser realmente feliz, debes ser esa persona. –Quetin Crisp\t"
    },
    "2026-06-23": {
        "content": "Si he visto más lejos que otros, es por estar de pie sobre los hombros de gigantes –Isaac Newton.\t"
    },
    "2026-06-24": {
        "content": " El secreto para disfrutar el trabajo esta contenido en una palabra –excelencia. Saber cómo hacer algo bien es disfrutarlo. –Pearl S. Buck.\t"
    },
    "2026-06-25": {
        "content": "Creo que podría hacerme sonreír aunque estuviera colgada de una horca. –Jandy Nelson\t"
    },
    "2026-06-26": {
        "content": "Y luego me da una sonrisa que parece tan genuinamente dulce con el toque justo de timidez que el calor inesperado corre a través de mí. –Suzanne Collins\t"
    },
    "2026-06-27": {
        "content": "Las guerras seguirán mientras el color de la piel siga siendo más importante que el de los ojos –Bob Marley.\t"
    },
    "2026-06-28": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2026-06-29": {
        "content": "La mala noticia es que el tiempo vuela, la buena noticia es que tú eres el piloto. –Michael Althsuler\t"
    },
    "2026-06-30": {
        "content": " El día peor empleado es aquél en que no se ha reído –Nicolás  Sebastien Roch Chamfort\t"
    },
    "2026-07-01": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2026-07-02": {
        "content": "Esconde tu locura detrás de una sonrisa. –Paulo Coelho\t"
    },
    "2026-07-03": {
        "content": "Era una sonrisa que podría hacer que un hombre creyera que la vida era buena. –John Verdon\t"
    },
    "2026-07-04": {
        "content": "El éxito no es mágico ni misterioso. El éxito es la consecuencia natural de aplicar los fundamentos básicos. –Jim Rohn\t"
    },
    "2026-07-05": {
        "content": "El comportamiento humano fluye de tres fuentes: emoción, deseo y conocimiento –Platón.\t"
    },
    "2026-07-06": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes . –Víctor Ruiz Iriarte\t"
    },
    "2026-07-07": {
        "content": "Opino que lo que se llama belleza, reside únicamente en la sonrisa. –León Tolstoi\t"
    },
    "2026-07-08": {
        "content": "Quienes no saben llorar con todo el corazón, tampoco saben sonreír. –Golda Meir\t"
    },
    "2026-07-09": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2026-07-10": {
        "content": "Conviene reír sin esperar a ser dichoso, no sea que nos sorprenda la muerte sin haber reído. –Jean de la Bruyere\t"
    },
    "2026-07-11": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2026-07-12": {
        "content": "El éxito es un camino, no un destino. –Ben Sweetland\t"
    },
    "2026-07-13": {
        "content": "«El éxito no es definitivo, el fracaso no es fatal: es el coraje para continuar lo que cuenta.» –Winston Churchill.\t"
    },
    "2026-07-14": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2026-07-15": {
        "content": "El niño reconoce a la madre por la sonrisa. –Leon Tolstoi\t"
    },
    "2026-07-16": {
        "content": " Si sonríes cuando no hay nadie a tu alrededor, lo haces en serio –Andy Rooney\t"
    },
    "2026-07-17": {
        "content": "Si vas en busca del amor nunca lo encontrarás porque el amor nunca se perdió; sólo nosotros nos perdemos. –Shannon L. Alder\t"
    },
    "2026-07-18": {
        "content": "Cuando un niño comienza a sonreír, con una pequeña bifurcación de amargor y dulzura, las orillas de su sonrisa desembocan sin burlas en la anarquía del océano. –Osip Mandelshtam\t"
    },
    "2026-07-19": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2026-07-20": {
        "content": "La vida es algo difícil de entender. Nos hace demasiado felices y de repente nos quita la felicidad. Pero debemos sonreír en cada fase de la vida. –Sahaj Oberoi\t"
    },
    "2026-07-21": {
        "content": "Si estás esperando a sentirte lo suficientemente talentoso para poder hacer algo, nunca lo harás. –Criss Jami\t"
    },
    "2026-07-22": {
        "content": "Nadie puede hacerte sentir inferior sin tu consentimiento”. –Eleanor Roosevelt\t"
    },
    "2026-07-23": {
        "content": "Consulta no tus miedos sino tus esperanzas y tus sueños. No pienses en tus frustraciones, sino en tu potencial incumplido. Preocúpate no en lo que has intentado y fracasado, sino en lo que todavía es posible hacer y lograr. –Juan XXIII\t"
    },
    "2026-07-24": {
        "content": "«La vida nos somete a pruebas para mostrarnos que somos más fuertes de lo que imaginamos en nuestros peores momentos». –Las mejores frases de la vida es dura\t"
    },
    "2026-07-25": {
        "content": " La risa es la distancia más corta entre dos personas –Víctor Borge\t"
    },
    "2026-07-26": {
        "content": "Antes de fruncir tu ceño, comprueba que no hay sonrisas disponibles. –Jim Beggs.\t"
    },
    "2026-07-27": {
        "content": "Si dicen mal de ti con fundamento, corrígete; de lo contrario, échate a reír. –Epicteto de Frigia\t"
    },
    "2026-07-28": {
        "content": "Para ser exitoso hay que seguir el camino de los gigantes. –Lillian Cauldwell\t"
    },
    "2026-07-29": {
        "content": "No esperes. Nunca habrá un momento perfecto”. –Napoleon Hill,\t"
    },
    "2026-07-30": {
        "content": " Vamos a conseguir muchas más cosas si pensamos que nada es imposible. –Vince Lombardi\t"
    },
    "2026-07-31": {
        "content": "Supe que eras la indicada cuando tu sonrisa se convirtió en mi cielo, tu risa en mi canción favorita y tus brazos en mi hogar. –Steve Maraboli\t"
    },
    "2026-08-01": {
        "content": "Estar preparado es importante, saber esperarlo es aún más, pero aprovechar el momento adecuado es la clave de la vida –Arthur Schnitzler.\t"
    },
    "2026-08-02": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2026-08-03": {
        "content": " Siempre que te pregunten si puedes hacer un trabajo, contesta que sí y ponte enseguida a aprender cómo se hace. –Franklin D. Roosevelt.\t"
    },
    "2026-08-04": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2026-08-05": {
        "content": "Si estás leyendo esto… Felicitaciones, estás vivo. Si eso no es algo para sonreír, entonces no sé qué es. –Chad Sugg\t"
    },
    "2026-08-06": {
        "content": "Porque yo, sólo yo, veo su sonrisa una milésima antes de que le llegue a la cara. –David Mitchell\t"
    },
    "2026-08-07": {
        "content": "El hombre verdadero sonríe ante los problemas, toma fuerza de la angustia y crece valiente ante la decepción. –Thomas Paine\t"
    },
    "2026-08-08": {
        "content": " El poder de una sonrisa es tal que, incluso si dibujas una carita feliz, bastará para hacer que tus labios se muevan de la misma forma –Richelle E. Goodrich\t"
    },
    "2026-08-09": {
        "content": "«El optimismo es la fe que conduce al logro. Nada se puede hacer sin esperanza y confianza». –Helen Keller\t"
    },
    "2026-08-10": {
        "content": "La lógica te llevará desde A hasta B. La imaginación te llevará a cualquier parte –Albert Einstein.\t"
    },
    "2026-08-11": {
        "content": "Ella sonrió y su rostro se iluminó. O tal vez fue el mío. Todo lo que sé con certeza es que su sonrisa iluminó hasta mis días más oscuros. –J. Sterling\t"
    },
    "2026-08-12": {
        "content": "Hoy llevo puesta la sonrisa que me regalaste. –Edith Castro\t"
    },
    "2026-08-13": {
        "content": "Mira hacia atrás y sonríe ante los peligros del pasado. –Walter Scott\t"
    },
    "2026-08-14": {
        "content": "Sabes lo que es realmente contagioso? Una gran sonrisa. –Scrubs\t"
    },
    "2026-08-15": {
        "content": "Si un líder no transmite pasión e intensidad, entonces no habrá pasión e intensidad dentro de la organización y empezarán a caer y deprimirse. –Colin Powell\t"
    },
    "2026-08-16": {
        "content": " El éxito es hacer lo que sea que quieras hacer, cuando quieras, donde quieras, con quien quieras y por el tiempo que quieras. –Anthony Robbins\t"
    },
    "2026-08-17": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2026-08-18": {
        "content": "Si mi estupidez te hace sonreír, prefiero entonces actuar siempre como un estúpido. –M.F. Moonzajer\t"
    },
    "2026-08-19": {
        "content": "Aferrarse a la ira es como beber veneno y esperar que la otra persona muera –Buda.\t"
    },
    "2026-08-20": {
        "content": "Todavía su conocimiento no ha aprendido a sonreír. –Friedrich Nietzsche\t"
    },
    "2026-08-21": {
        "content": "El no querer es la causa, el no poder el pretexto –Seneca.\t"
    },
    "2026-08-22": {
        "content": " Es mejor olvidarse y sonreír que recordar y entristecerse –Cristina Rossetti\t"
    },
    "2026-08-23": {
        "content": "Para tener éxito hoy, tienes que fijar prioridades, decidir lo que representas. –Lee Laccoca\t"
    },
    "2026-08-24": {
        "content": "Quien nos hace reír es un cómico. Quien nos hace pensar y luego reír es un humorista. –George Burns\t"
    },
    "2026-08-25": {
        "content": "La risa es el lenguaje del alma. –Pablo Neruda\t"
    },
    "2026-08-26": {
        "content": " Cada sonrisa te hace un día más joven  –Proverbio chino\t"
    },
    "2026-08-27": {
        "content": "Teresa de la dulce sonrisa; porque mientras las demás se esforzaban una y otra vez en superarla, ella simplemente las derrotaba con una sonrisa en su rostro. –Claymore\t"
    },
    "2026-08-28": {
        "content": "¿Quieres saber quién eres? No preguntes. ¡Actúa! La acción delineará y definirá quién eres –Thomas Jefferson.\t"
    },
    "2026-08-29": {
        "content": " No importa lo bueno que seas, siempre puedes ser mejor, esa es la parte emocionante. –Tiger Woods\t"
    },
    "2026-08-30": {
        "content": "Ríete de la noche, del día, de la luna, ríete de las calles torcidas de la isla, ríete de este torpe muchacho que te quiere. –Pablo Neruda\t"
    },
    "2026-08-31": {
        "content": "El cielo no es un límite para mí, porque no tengo límites en mi vida. Porque la vida es un mundo lleno de riesgos y posibilidades. –Termitope Owosela\t"
    },
    "2026-09-01": {
        "content": "Su sonrisa podía parar el corazón de un hombre. –El nombre del viento\t"
    },
    "2026-09-02": {
        "content": " No hay ninguna cosa seria que no pueda decirse con una sonrisa –Alejandro Casona\t"
    },
    "2026-09-03": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2026-09-04": {
        "content": "No tienes que ser grande para empezar. Pero tienes que empezar para poder ser grande”. –Zig Ziglar\t"
    },
    "2026-09-05": {
        "content": "«La vida es como montar una bicicleta, para mantener el equilibrio debes seguir adelante». –Albert Einstein, frases de la vida es dura\t"
    },
    "2026-09-06": {
        "content": "Muy frecuentemente las lágrimas son la última sonrisa del amor. –Stendhal\t"
    },
    "2026-09-07": {
        "content": "No dejes de sonreír… Porque es muy poco el tiempo que te dan para la alegría –Zenaida Bacardí de Argamasilla\t"
    },
    "2026-09-08": {
        "content": "Si te pones a ti mismo en una posición en la que tienes que salir de tu zona de confort, estas forzando la expansión de tu consciencia. –Les Brown\t"
    },
    "2026-09-09": {
        "content": " Cuando deseamos convertirnos en personas de éxito, lo primero que tenemos que hacer es actuar como si ya lo fuésemos. –Gaby Vargas\t"
    },
    "2026-09-10": {
        "content": "Aunque sentí que estaba a punto de llorar, opté por sonreír. –Kristen Ashley\t"
    },
    "2026-09-11": {
        "content": "Una gran sonrisa es un bello rostro de gigante –Charles Baudelaire\t"
    },
    "2026-09-12": {
        "content": "Si es bueno vivir, todavía es mejor soñar, y lo mejor de todo, despertar –Antonio Machado.\t"
    },
    "2026-09-13": {
        "content": "«El futuro pertenece a aquellos que creen en la belleza de sus sueños». –Eleanor Roosevelt\t"
    },
    "2026-09-14": {
        "content": "Tu trabajo va a llenar una gran parte de tu vida; la única manera de estar verdaderamente satisfecho es hacer lo que crees que es un gran trabajo y la única manera de hacer un gran trabajo es amar lo que haces. Si todavía no lo has encontrado, sigue buscando. No te conformes. Como con todos los asuntos del corazón, sabrás cuando lo encuentres. –Steve Jobs\t"
    },
    "2026-09-15": {
        "content": "La curva mas hermosa de una mujer es su sonrisa. –Bob Marley\t"
    },
    "2026-09-16": {
        "content": "La capacidad de reír juntos es el amor. –Francoise Sagan\t"
    },
    "2026-09-17": {
        "content": " Si quieres probarle algo al mundo, entonces necesitas que el mundo te preste atención para poder probarlo. –Toba Beta\t"
    },
    "2026-09-18": {
        "content": "Si de verdad quieres comer, sigue escalando. Las frutas están en la cima del árbol. Estira tus brazos y sigue estirándolos. El éxito está en la cima, sigue adelante. –Isralemore Ayivor\t"
    },
    "2026-09-19": {
        "content": "Hay tres tipos de personas en este mundo. Primero, están las que hacen que ocurran las cosas. Luego están las que ven cómo ocurren las cosas. Por último, están las que preguntan, ¿qué ha ocurrido? ¿Cuál quieres ser tú? –Steve Backley\t"
    },
    "2026-09-20": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2026-09-21": {
        "content": "El secreto de la existencia humana no solo está en vivir, sino también en saber para qué se vive –Fiódor Dostoievski\t"
    },
    "2026-09-22": {
        "content": "Nunca estas completamente vestido sin una sonrisa –Martin Charnin\t"
    },
    "2026-09-23": {
        "content": "Lo que el sol es para las flores, la sonrisa es para la humanidad. –Joseph Addison\t"
    },
    "2026-09-24": {
        "content": "Eres lo que eres hoy por las acciones que has hecho o las acciones que no has hecho. –Steve Maraboli\t"
    },
    "2026-09-25": {
        "content": " El éxito es hacer, no obtener. Es intentar, no triunfar. El éxito es un estándar personal, es alcanzar lo más alto que hay en nosotros, convertirnos en todo lo que podemos ser. Si damos nuestro mejor esfuerzo, somos exitosos. –Zig Ziglar\t"
    },
    "2026-09-26": {
        "content": " No te sientas avergonzado de intentarlo y fallar, puesto que la persona que nunca ha fallado es alguien que nunca lo ha intentado. –Og Mandino\t"
    },
    "2026-09-27": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2026-09-28": {
        "content": "No importa lo que la gente diga, las palabras y las ideas tienen el poder de cambiar el mundo”. –Robin Williams\t"
    },
    "2026-09-29": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2026-09-30": {
        "content": "Nunca me cansaré de decir esto: no te compares con los demás, sé fiel a ti mismo y sigue aprendiendo todo lo que puedas aprender. –Daisaku Ikeda\t"
    },
    "2026-10-01": {
        "content": "Comienza por hacer lo necesario; luego haz lo que es posible; y, de repente, estarás haciendo lo imposible –Francisco de Asís.\t"
    },
    "2026-10-02": {
        "content": "Puedes tenerlo todo, sólo que no al mismo tiempo. –Betty Friedan\t"
    },
    "2026-10-03": {
        "content": "«El éxito no es la clave de la felicidad. La felicidad es la clave del éxito. Si amas lo que haces, tendrás éxito.» –Albert Schweitzer.\t"
    },
    "2026-10-04": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2026-10-05": {
        "content": "No importa si te derriban, lo importante es si te levantas cuando sucede”. –Vince Lombardi\t"
    },
    "2026-10-06": {
        "content": "La mejor preparación para mañana es hacer lo mejor que puedas hoy –H. Jackson Brown, Jr.\t"
    },
    "2026-10-07": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2026-10-08": {
        "content": "¿Cuándo regresará esa sonrisa que me desnuda en cuerpo y alma? –Jenniley Bonilla\t"
    },
    "2026-10-09": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2026-10-10": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2026-10-11": {
        "content": "Toma tus victorias, sin importar cuáles sean, cuídalas, úsalas, pero no te conformes con ellas. –Mia Hamm\t"
    },
    "2026-10-12": {
        "content": "Sonríe, es una terapia gratis. –Douglas Horton\t"
    },
    "2026-10-13": {
        "content": " Es más fácil obtener lo que se desea con una sonrisa que con la punta de la espada –William Shakespeare\t"
    },
    "2026-10-14": {
        "content": " La única manera de hacer un gran trabajo es amar lo que hace. Si no ha encontrado todavía algo que ame, siga buscando. No se conforme. Al igual que los asuntos del corazón, sabrá cuando lo encuentre. –Steve Jobs.\t"
    },
    "2026-10-15": {
        "content": " Su sonrisa sigue siendo como el sol que rompe a través de las nubes. –Dale Carnegie\t"
    },
    "2026-10-16": {
        "content": "Cuando ella sonreía, él se olvidaba de todo. –Palmeras en la nieve\t"
    },
    "2026-10-17": {
        "content": " Aunque sonreír es un trabajo de un simple instante, sus efectos duran por mucho tiempo –Bucky Buckbinder\t"
    },
    "2026-10-18": {
        "content": "Una sonrisa es la felicidad que encontrarás justo debajo te tu nariz. –Tom Wilson\t"
    },
    "2026-10-19": {
        "content": "La sonrisa es el lenguaje universal de los hombres inteligentes. –Víctor Ruiz Iriarte\t"
    },
    "2026-10-20": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2026-10-21": {
        "content": "La forma de arruinar la vida de alguien es darle todo lo que quiere. –Patrick Swayze\t"
    },
    "2026-10-22": {
        "content": "No importa cuán fugaz es tu sonrisa, tu sonrisa es el principio de la luz de tu sabiduría. –Sri Chinmoy\t"
    },
    "2026-10-23": {
        "content": "«El éxito no es para los que sueñan despiertos, sino para aquellos que sueñan y hacen que sus sueños se hagan realidad.» –Jack Canfield.\t"
    },
    "2026-10-24": {
        "content": "Ni siquiera trató de sonreír. Entonces supe que lo había perdido para siempre. –Marian Keyes\t"
    },
    "2026-10-25": {
        "content": "No puedo dejar de justificar el que se anime a los niños a sonreír antes que a llorar. No deseo pagar por ver otras lágrimas que las del teatro. –Robert Louis Stevenson\t"
    },
    "2026-10-26": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2026-10-27": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2026-10-28": {
        "content": "Lo que nos parecen pruebas amargas son bendiciones disfrazadas. –Oscar Wilde\t"
    },
    "2026-10-29": {
        "content": "Si haces cada tarea como la vas a hacer por el resto de tu vida, es entonces cuando vas a destacar. –Mary Barra\t"
    },
    "2026-10-30": {
        "content": "En nuestras bocas las sonrisas fueron mensajes de esperanza. –Alfonso Orantes\t"
    },
    "2026-10-31": {
        "content": "Lo inevitable puede aceptarse también sin sonreír. –Santiago Rusiñol\t"
    },
    "2026-11-01": {
        "content": " Estoy convencido de que aproximadamente la mitad de lo que separa a los emprendedores de éxito de los de no  éxito es la perseverancia. –Steve Jobs\t"
    },
    "2026-11-02": {
        "content": "Las sonrisas generan amor y paz, el amor y la paz enciende las sonrisas en las personas. –Mehmet Murat ildan\t"
    },
    "2026-11-03": {
        "content": "Tenía los labios inmóviles, pero cuando lo miré parecieron sonreír sin hacer el más mínimo movimiento. –Anne Rice\t"
    },
    "2026-11-04": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2026-11-05": {
        "content": " Alcanza el éxito el hombre que ha vivido bien, quien ha reído con frecuencia y ha amado mucho, quien ha disfrutado de la confianza de una mujer pura, del respeto de la inteligencia de los otros y del amor de sus hijos. –Bessie Anderson Stanley\t"
    },
    "2026-11-06": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2026-11-07": {
        "content": "No quiero dejar de sonreír, bajo ninguna circunstancia, ni perder la libertad para pensar, y quiero enfrentarme a cualquier dificultad con valentía. –Banana Yashimoto\t"
    },
    "2026-11-08": {
        "content": "La voluntad de ganar, el deseo de triunfar, el ímpetu de alcanzar tu pleno potencial…estas son las llaves que abrirán la puerta a la excelencia personal. –Confucio\t"
    },
    "2026-11-09": {
        "content": "Cuando sonrió el hombre, el mundo lo amó. Cuando rió, le tuvo miedo. –Rabindranath Tagore\t"
    },
    "2026-11-10": {
        "content": "El único hombre que nunca comete un error es el hombre que nunca hace nada. –Theodore Roosevelt\t"
    },
    "2026-11-11": {
        "content": "La raza humana tiene un arma verdaderamente eficaz: la risa. –Mark Twain\t"
    },
    "2026-11-12": {
        "content": "Sólo tu sonrisa permanece como muchas estrellas sobre ti, y pronto también sobre mí. –Rainer Maria Rilke\t"
    },
    "2026-11-13": {
        "content": "«La vida es 10% lo que nos sucede y 90 % cómo reaccionamos ante ello». –Charles R. Swindoll\t"
    },
    "2026-11-14": {
        "content": "Mi padre siempre me decía: encuentra un trabajo que te guste y no tendrás que trabajar un solo día de tu vida. –Jim Fox.\t"
    },
    "2026-11-15": {
        "content": " Ríe y el mundo reirá contigo; llora y llorarás sólo –Eli Wilcox\t"
    },
    "2026-11-16": {
        "content": "Hazles comprender que no tienen en el mundo otro deber que la alegría. –Paul Claudel\t"
    },
    "2026-11-17": {
        "content": " El cambio está en el aire. Nos recuerda que fuimos esculpidos por el mismo poder que orquesta el cambio de las estaciones. Que esta sea la estación en la que aceptes el cambio y te alinees con él. –Steve Maraboli\t"
    },
    "2026-11-18": {
        "content": "Ningún hombre es lo bastante bueno para gobernar a otros sin su consentimiento –Abraham Lincoln.\t"
    },
    "2026-11-19": {
        "content": " Empieza cada día con una sonrisa y mantenla todo el día –W. C. Fields\t"
    },
    "2026-11-20": {
        "content": "Llegará el día en que miremos atrás, todas esas sonrisas que hemos compartido, y entonces sonreiremos una vez más. Son momentos como ese los que hacen que la vida valga la pena. –Nadège Richards\t"
    },
    "2026-11-21": {
        "content": "Hay un solo rincón del universo que puedes estar seguro de poder mejorar, y eres tú mismo –Aldous Huxley.\t"
    },
    "2026-11-22": {
        "content": "Los seres humanos no nacen para siempre el día en que sus madres los alumbran, sino que la vida los obliga a parirse a sí mismos una y otra vez –Gabriel García Márquez.\t"
    },
    "2026-11-23": {
        "content": " En cualquier momento, la decisión de ser feliz está presente. Sólo tenemos que escoger ser feliz. –Steve Maraboli\t"
    },
    "2026-11-24": {
        "content": "Dios creó el desierto para que el hombre pudiera sonreír al ver las palmeras. –Paulo Coelho\t"
    },
    "2026-11-25": {
        "content": "Ella era una persona cuyo rostro cambiaba totalmente cuando sonreía, como el sol cuando se pone en el horizonte. –Caroline Green\t"
    },
    "2026-11-26": {
        "content": "Las cosas más valiosas son baratas, como el pan o el agua, o son gratis; como el aire, la sonrisa y el amor. –Pacoyo\t"
    },
    "2026-11-27": {
        "content": " Alguien que elige sonreír antes que enfadarse, es una persona realmente fuerte –David Schary\t"
    },
    "2026-11-28": {
        "content": "Sonríe, a pesar de todo. –Steinberg\t"
    },
    "2026-11-29": {
        "content": " Confía en la fuerza de tu cuerpo y corazón. Elige por estrella la autosuficiencia, la fe, la honestidad y la industria. No tomes demasiados consejos, mantén el timón y dirige tu propio barco, y recuerda que el gran arte de mandar es tomar una participación equitativa del trabajo. Pon fuego sobre la marca que quieres golpear. La energía y la determinación con el motivo correctos son las palancas que mueven al mundo. –Noah Porter.\t"
    },
    "2026-11-30": {
        "content": "Eres mi sol nocturno, mi luna de día, mi mejor sonrisa. –Federico Moccia\t"
    },
    "2026-12-01": {
        "content": "Huye de los rostros graves y solemnes que jamás se distienden en una sonrisa. Huye de los espíritus susceptibles, que por todo se ofenden. –Ricardo León\t"
    },
    "2026-12-02": {
        "content": "Si en la lid el destino te derriba; si todo en tu camino es cuesta arriba, si tu sonrisa es ansia insatisfecha, si hay faena excesiva y vil cosecha, si a tu caudal se anteponen diques. . . Date una tregua ¡pero no claudiques! –Rudyard Kipling\t"
    },
    "2026-12-03": {
        "content": " Son necesarios cuarenta músculos para arrugar una frente, pero sólo quince para sonreír –Swami Sivananda\t"
    },
    "2026-12-04": {
        "content": "«El éxito es la suma de pequeños esfuerzos repetidos día tras día.» –Robert Collier.\t"
    },
    "2026-12-05": {
        "content": "Espero tu sonrisa y espero tu fragancia por encima de todo, del tiempo y la distancia. –José Angel Buesa\t"
    },
    "2026-12-06": {
        "content": "No importa lo lento que vayas mientras que no pares –Confucio.\t"
    },
    "2026-12-07": {
        "content": "Todos deberíamos saber todo el bien que una simple sonrisa puede hacer. –Madre Teresa de Calcuta\t"
    },
    "2026-12-08": {
        "content": "Despierta ríes y al reír tus labios inquietos me parecen relámpagos de grana que serpean sobre un cielo de nieve. –Gustavo Adolfo Bécquer\t"
    },
    "2026-12-09": {
        "content": "Podemos experimentar muchas derrotas, pero no debemos permitir que nos derroten”. –Maya Angelou\t"
    },
    "2026-12-10": {
        "content": "No cuentes los días, haz que los días cuenten –Muhammad Ali.\t"
    },
    "2026-12-11": {
        "content": "Hay sonrisas que no son de felicidad, sino de un modo de llorar con bondad. –Gabriela Mistral\t"
    },
    "2026-12-12": {
        "content": "Volvió a sonreír. Era una buena sonrisa, con montones de dientes. –Harlan Coben\t"
    },
    "2026-12-13": {
        "content": "Cambia tus pensamientos y cambiarás tu mundo –Norman Vincent Peale.\t"
    },
    "2026-12-14": {
        "content": " Tómate las siguientes 24 horas y relájate sabiendo que todo va bien. Deja ir el estrés y las preocupaciones por un día y observa qué ocurre. –Mike Basevic\t"
    },
    "2026-12-15": {
        "content": " A veces, no obtener lo que quieres es un golpe de suerte brillante. –Lorii Myers\t"
    },
    "2026-12-16": {
        "content": "El mundo es bello, pero tiene un defecto llamado hombre –Friedrich Nietzsche.\t"
    },
    "2026-12-17": {
        "content": "La mayoría de las grandes hazañas logradas por el hombre fueron consideradas imposibles antes de que alguien las llevara a cabo”. –Louis D. Brandeis.\t"
    },
    "2026-12-18": {
        "content": "El éxito es ir de fracaso en fracaso sin perder el entusiasmo –Winston Churchill.\t"
    },
    "2026-12-19": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2026-12-20": {
        "content": "Si quieres tener éxito es simple. Conoce lo que estás haciendo, ama lo que estás haciendo y cree en lo que estás haciendo. –Will Rogers\t"
    },
    "2026-12-21": {
        "content": "No llores porque ya se terminó, sonríe porque sucedió. –Gabriel García Marquez\t"
    },
    "2026-12-22": {
        "content": "«Los momentos más oscuros son la antesala de los amaneceres más brillantes; nunca pierdas la esperanza». –Citas y frases de la vida es dura\t"
    },
    "2026-12-23": {
        "content": "La educación es el arma más poderosa para cambiar el mundo –Nelson Mandela.\t"
    },
    "2026-12-24": {
        "content": " Lo que mueve al mundo no son los potentes brazos de los héroes, sino la suma de los pequeños empujones de cada trabajador honrado. –Hellen Keller.\t"
    },
    "2026-12-25": {
        "content": "Una sonrisa es la bienvenida universal. –Max Eastman\t"
    },
    "2026-12-26": {
        "content": "Sonreír es definitivamente una de los mejores y más bellos remedios. Si tienes un buen sentido de humor y un buen enfoque hacia la vida, eso es hermoso –Rashida Jones\t"
    },
    "2026-12-27": {
        "content": " No le temas al fracaso. Es el camino al éxito. –LeBron James\t"
    },
    "2026-12-28": {
        "content": "Tomar responsabilidad personal es algo hermoso porque nos da un control completo sobre nuestro destino. –Heather Schuck\t"
    },
    "2026-12-29": {
        "content": "Haz que se pregunten por qué sigues sonriendo. –Elizabethtown\t"
    },
    "2026-12-30": {
        "content": "«El único lugar donde los sueños son imposibles es en nuestra mente». –Emilio Lledó\t"
    },
    "2026-12-31": {
        "content": " Comparte tus aspiraciones con aquellos que te vayan a apoyar, no con aquellos que respondan con duda o falta de interés. –Steve Backley\t"
    }
}

export const dateYinList = {
    "2023-01-01": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2023-01-02": {
        "content": "Pikirkanlah sebelum berbicara, karena perkataan memiliki kekuatan yang besar. –Ali bin Abi Thalib"
    },
    "2023-01-03": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2023-01-04": {
        "content": "Ubah lukamu menjadi kebijaksanaan. –Oprah Winfrey"
    },
    "2023-01-05": {
        "content": "Kebahagiaan dari hidupmu tergantung dari kualitas pikiranmu –Marcus Aurelius."
    },
    "2023-01-06": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2023-01-07": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2023-01-08": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2023-01-09": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2023-01-10": {
        "content": "Kegigihan adalah kunci kesuksesan dalam pencapaian tujuan. –Abdul Qadir al-Jilani"
    },
    "2023-01-11": {
        "content": "Penemuan terbesar sepanjang masa adalah bahwa seseorang bisa mengubah masa depannya hanya dengan mengubah sikapnya saat ini. -Oprah Winfrey"
    },
    "2023-01-12": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2023-01-13": {
        "content": "Semua mimpi kita bisa jadi kenyataan, jika kita memiliki keberanian untuk mengejarnya –Walt Disney"
    },
    "2023-01-14": {
        "content": "Kebenaran adalah cahaya yang menghilangkan kegelapan. –al-Ghazali"
    },
    "2023-01-15": {
        "content": "Perjalanan seribu mil dimulai dengan satu langkah. –Lao Tzu"
    },
    "2023-01-16": {
        "content": "Jalan menuju sukses dan jalan menuju kegagalan hampir persis sama –Colin R. Davis"
    },
    "2023-01-17": {
        "content": "Hanya mereka yang berani mengambil risiko melangkah terlalu jauh, yang mungkin bisa mengetahui seberapa jauh seseorang bisa melangkah. –TS Eliot"
    },
    "2023-01-18": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2023-01-19": {
        "content": "Suatu pekerjaan yang paling tak kunjung bisa diselesaikan adalah pekerjaan yang tak kunjung pernah dimulai. –JRR Tolkien"
    },
    "2023-01-20": {
        "content": "Diam adalah sumber dari kekuatan yang luar biasa. -Lao Tzu"
    },
    "2023-01-21": {
        "content": "Tidak apa-apa merayakan kesuksesan, tapi lebih penting untuk memperhatikan pelajaran tentang kegagalan.  –Bill Gates"
    },
    "2023-01-22": {
        "content": "Ketika Anda jatuh cinta, kebahagiaan akan membuat Anda sulit tertidur karena kenyataan lebih baik dibandingkan mimpi Anda –Dr Seuss"
    },
    "2023-01-23": {
        "content": "Jangan habiskan waktumu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. -Coco Canel"
    },
    "2023-01-24": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2023-01-25": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2023-01-26": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2023-01-27": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2023-01-28": {
        "content": "Ingatlah selalu bahwa kamu benar-benar unik. Sama seperti orang lain. –Margaret Mead"
    },
    "2023-01-29": {
        "content": "Kamu hanya gagal saat kamu berhenti mencoba –Albert Einstein"
    },
    "2023-01-30": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2023-01-31": {
        "content": "Seseorang tak akan pernah tahu betapa dalam kadar cintanya sampai terjadi sebuah perpisahan. -Kahlil Gibran"
    },
    "2023-02-01": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2023-02-02": {
        "content": "Kita harus belajar untuk hidup bersama sebagai saudara atau kita akan binasa bersama-sama sebagai orang bodoh. –Martin Luther King"
    },
    "2023-02-03": {
        "content": "Hidup adalah perjalanan spiritual menuju kebenaran mutlak. –Rumi"
    },
    "2023-02-04": {
        "content": "Semuanya kelihatan tidak mungkin sampai segala sesuatu selesai. -Nelson Mandela"
    },
    "2023-02-05": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2023-02-06": {
        "content": "Kamu tidak bisa kembali dan mengubah awal saat kamu memulainya, tapi kamu bisa memulainya lagi dari di mana kamu berada sekarang dan ubah akhirnya. -C.S Lewis"
    },
    "2023-02-07": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2023-02-08": {
        "content": "Kita memahami dunia tidak sebagaimana ia adalah, tetapi sebagaimana kita. –Immanuel Kant"
    },
    "2023-02-09": {
        "content": "Jauhi orang-orang yang mencoba meremehkan ambisimu. Pikiran kecil akan selalu melakukan itu, tetapi pikiran besar akan memberi perasaan bahwa kamu juga bisa menjadi hebat –Mark Twain"
    },
    "2023-02-10": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2023-02-11": {
        "content": "Pada saat-saat tergelaplah kita harus fokus untuk melihat cahaya. -Aristoteles"
    },
    "2023-02-12": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2023-02-13": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2023-02-14": {
        "content": "Ketahuilah bahwa hidup ini adalah ujian yang sebenarnya. –Ibn Qayyim al-Jawziyya"
    },
    "2023-02-15": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2023-02-16": {
        "content": "Kekuatan dan perkembangan datang hanya dari usaha dan perjuangan yang terus menerus. –Napoleon Hill"
    },
    "2023-02-17": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara. -Shah Rukh Khan"
    },
    "2023-02-18": {
        "content": "Belajarlah seolah-olah kamu akan hidup selamanya, hiduplah seolah-olah kamu akan mati besok. –Mahatma Gandhi"
    },
    "2023-02-19": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2023-02-20": {
        "content": "Jika kamu benar-benar menginginkan sesuatu, jangan menunggu untuk itu –ajari dirimu untuk tidak sabar –Gurbaksh Chahal"
    },
    "2023-02-21": {
        "content": "Beri nilai dari usahanya jangan dari hasilnya. Baru kita bisa menilai kehidupan. -Albert Einstein"
    },
    "2023-02-22": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2023-02-23": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2023-02-24": {
        "content": "Cinta tak berupa tatapan satu sama lain, tetapi memandang keluar bersama ke arah yang sama. –B.J. Habibie"
    },
    "2023-02-25": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2023-02-26": {
        "content": "Kita tidak hidup dari apa yang kita miliki, tetapi dari apa yang kita berikan. –Pablo Picasso"
    },
    "2023-02-27": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2023-02-28": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2023-03-01": {
        "content": "Cinta tidak terlihat dengan mata, tetapi dengan hati.–William Shakespeare"
    },
    "2023-03-02": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2023-03-03": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2023-03-04": {
        "content": "Tidak ada akhir untuk pendidikan. Bukan berarti Anda membaca buku, lulus ujian, dan menyelesaikan pendidikan. Seluruh kehidupan, dari saat Anda lahir hingga saat Anda mati, adalah proses pembelajaran. –Jiddu Krishnamurti"
    },
    "2023-03-05": {
        "content": "Suatu ide yang berguna lebih berharga daripada kekayaan. –Socrates"
    },
    "2023-03-06": {
        "content": "Aku tidak takut badai karena telah belajar berlayar dengan kapalku –Louisa May Alcott."
    },
    "2023-03-07": {
        "content": "Anda ingin mengetahui siapa diri Anda? Jangan bertanya. Beraksilah! -Thomas Jefferson "
    },
    "2023-03-08": {
        "content": "Kesempatan emas yang kau cari ada di dalam dirimu sendiri –Orison Sweet Marden."
    },
    "2023-03-09": {
        "content": "Kau tidak pernah dilahirkan untuk hidup kalah, tertekan, bersalah, malu, terkutuk, dan tidak layak. Kau dilahirkan untuk menjadi seorang pemenang –Mikhail Sergeyevich Gorbachev."
    },
    "2023-03-10": {
        "content": "Untuk sukses, sikap sama pentingnya dengan kemampuan. -Walter Scott"
    },
    "2023-03-11": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2023-03-12": {
        "content": "Dia yang mengetahui semua jawaban belum pernah memperoleh pertanyaan –Confucius."
    },
    "2023-03-13": {
        "content": "Jangan habiskan waktu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. –Coco Canel"
    },
    "2023-03-14": {
        "content": "Jangan pernah bermimpi untuk sukses, tapi kerjakan sesuatu untuk meraih kesuksesan –Ester Lauder"
    },
    "2023-03-15": {
        "content": "Sulit untuk mengalahkan orang yang tidak pernah menyerah –Babe Ruth"
    },
    "2023-03-16": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2023-03-17": {
        "content": "Tindakan menyalahkan hanya akan membuang waktu. Sebesar apapun kesalahan yang kamu timpakan ke orang lain, dan sebesar apapun kamu menyalahkannya, hal tersebut tidak akan mengubahmu –Wayne Dyer"
    },
    "2023-03-18": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2023-03-19": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2023-03-20": {
        "content": "Cara paling mendasar dan kuat untuk terhubung dengan orang lain adalah dengan mendengarkan. Cukup Dengarkan. Mungkin hal terpenting yang bisa kita berikan kepada orang lain adalah perhatian kita –Rachel Naomi Remen"
    },
    "2023-03-21": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2023-03-22": {
        "content": "Satu-satunya sumber dari pengertahuan adalah pengalaman. -Albert Einstein"
    },
    "2023-03-23": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti.–Kahlil Gibran, Pujangga"
    },
    "2023-03-24": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2023-03-25": {
        "content": "Bila Anda gagal hari ini, jangan pernah menyerah Ulangi terus kegagalan Anda sampai atasan/komandan Anda menyerah –Mario Teguh"
    },
    "2023-03-26": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2023-03-27": {
        "content": "Kebebasan adalah hak setiap individu. –John Locke"
    },
    "2023-03-28": {
        "content": "Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.–Ibnu Sina"
    },
    "2023-03-29": {
        "content": "Hidup merupakan suatu perjalanan yang dapat dijadikan pengalaman, bukan sekadar masalah yang harus diselesaikan –C.S. Lewis."
    },
    "2023-03-30": {
        "content": "Hidup adalah rangkaian pelajaran yang harus dijalani untuk dipahami. –Ralph Waldo Emerson"
    },
    "2023-03-31": {
        "content": "Gravitasi tidak bertanggung-jawab atas orang yang jatuh cinta –Albert Einstein"
    },
    "2023-04-01": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. -Bunda Teresa"
    },
    "2023-04-02": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2023-04-03": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2023-04-04": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2023-04-05": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2023-04-06": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2023-04-07": {
        "content": "Anda hanya hidup sekali, tetapi jika Anda melakukannya dengan benar, sekali saja sudah cukup. -Mae West"
    },
    "2023-04-08": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2023-04-09": {
        "content": "Tetap menghadapkan wajah selalu ke arah sinar matahari, dan bayangan akan jatuh di belakangmu –Walt Whitman"
    },
    "2023-04-10": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2023-04-11": {
        "content": "Kedamaian hilang karena kita lupa jika sebenarnya memiliki satu sama lain –Bunda Maria Teresa Bojaxhiu."
    },
    "2023-04-12": {
        "content": "Aku telah belajar jika ada lebih banyak kekuatan di dalam sebuah pelukan yang kuat dibandingkan dengan seribu kata-kata –Ann Hood."
    },
    "2023-04-13": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2023-04-14": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2023-04-15": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2023-04-16": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2023-04-17": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2023-04-18": {
        "content": "Jangan khawatir jika Anda tidak diakui, tetapi berusahalah untuk menjadi layak diakui. -Abraham Lincoln"
    },
    "2023-04-19": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. -Maya Angelou"
    },
    "2023-04-20": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2023-04-21": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2023-04-22": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. –Zig Ziglar"
    },
    "2023-04-23": {
        "content": "Hubungan asmara itu seperti kaca. Terkadang lebih baik meninggalkannya dalam keadaan pecah. Daripada menyakiti dirimu dengan cara menyatukan mereka kembali. -D.Love"
    },
    "2023-04-24": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2023-04-25": {
        "content": "Jangan takut akan kesulitan, karena itu adalah kesempatan untuk tumbuh. –Albert Einstein"
    },
    "2023-04-26": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2023-04-27": {
        "content": "Kesuksesan bukanlah hal yang final; kegagalan bukanlah hal yang fatal: Yang terpenting adalah keberanian untuk terus maju. -Winston S. Churchill"
    },
    "2023-04-28": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2023-04-29": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2023-04-30": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2023-05-01": {
        "content": "Arahkan mata Anda pada bintang-bintang dengan kaki tetap berpijak pada tanah. -Theodore Roosevelt"
    },
    "2023-05-02": {
        "content": "Cinta itu seperti halnya angin. Kita tidak dapat melihatnya, tetapi dapat merasakannya –Nicholas Sparks."
    },
    "2023-05-03": {
        "content": "Kebahagiaan kita tergantung kepada diri kita sendiri –Aristoteles."
    },
    "2023-05-04": {
        "content": "Gagal itu makanan sehari-hari. Itu biasa, yang penting bagimana kamu menyikapinya. Evaluasi. Bangkit. Gagal lagi? Bangkit lagi!” –Chairul Tanjung"
    },
    "2023-05-05": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2023-05-06": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2023-05-07": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2023-05-08": {
        "content": "Rahasia kesuksesan adalah melakukan hal yang biasa dengan sangat baik. -John D. Rockefeller Jr."
    },
    "2023-05-09": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2023-05-10": {
        "content": "Begitu kita menerima batasan kita, kita melampauinya. –Albert Einstein"
    },
    "2023-05-11": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2023-05-12": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2023-05-13": {
        "content": "Belajar tidak pernah melelahkan pikiran –Leonardo Da Vinci"
    },
    "2023-05-14": {
        "content": "Tiga hal dalam hidup –kesehatanmu, misimu dan orang yang kamu cintai. –Naval Ravikant"
    },
    "2023-05-15": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2023-05-16": {
        "content": "Apa yang kita pikirkan menentukan apa yang akan terjadi pada kita. Jadi jika kita ingin mengubah hidup, kita perlu sedikit mengubah pikiran kita. –Wayne Dyer."
    },
    "2023-05-17": {
        "content": "Rahasia dari kesuksesan kita adalah bahwa kita tidak pernah menyerah. –Ilma Mankiller"
    },
    "2023-05-18": {
        "content": "Jangan merusak sesuatu yang kau miliki dengan mengharapkan sesuatu yang tidak akan pernah kau miliki; ingatlah jika sesuatu yang kau miliki saat ini pernah menjadi salah satu hal yang kau harapkan –Epicurus."
    },
    "2023-05-19": {
        "content": "Dia, yang tidak cukup berani untuk mengambil risiko, tidak akan mencapai apa pun dalam hidup. –Muhammad Ali"
    },
    "2023-05-20": {
        "content": " Orang yang memiliki sasaran sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2023-05-21": {
        "content": "Jangan menunggu orang lain membahagiakanmu. Kebahagiaan apa pun yang Anda peroleh, harus Anda ciptakan sendiri. -Alice Walker"
    },
    "2023-05-22": {
        "content": "Cinta selalu saja misterius Jangan diburu-buru, atau kau akan merusak jalan ceritanya sendiri –Tere Liye"
    },
    "2023-05-23": {
        "content": "Hadapkan wajahmu selalu ke arah matahari, sehingga bayangan akan jatuh di belakangmu. -Walt Whitman"
    },
    "2023-05-24": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2023-05-25": {
        "content": "Hidup itu sebentar. Kamu harus bisa tersenyum saat merasakan kepedihan atau kita tak akan pernah melanjutkan hidup.–Jeff Ross"
    },
    "2023-05-26": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2023-05-27": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2023-05-28": {
        "content": "Hidup adalah yang terjadi saat kamu sibuk membuat rencana lain. –John Lennon"
    },
    "2023-05-29": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2023-05-30": {
        "content": "Satu-satunya tempat di mana kesuksesan datang sebelum bekerja adalah di kamus. -Vidal Sassoon"
    },
    "2023-05-31": {
        "content": "Kebahagiaan berada di dalam. Hal itu tidak ada hubungannya dengan seberapa banyak tepuk tangan yang kau peroleh atau seberapa banyak orang yang menyanjungmu. Kebahagiaan datang saat kau percaya telah melaksanakan sesuatu yang benar-benar berharga –Martin Yan."
    },
    "2023-06-01": {
        "content": "Cinta adalah kebenaran terbesar dan kebaikan tertinggi. –Plato"
    },
    "2023-06-02": {
        "content": "Dunia itu seluas langkah kaki. Jelajahilah dan jangan pernah takut melangkah. Hanya dengan itu kita bisa mengerti kehidupan dan menyatu dengannya.–Soe Hok Gie"
    },
    "2023-06-03": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2023-06-04": {
        "content": "Kebanggaan kita yang terbesar adalah bukan tidak pernah gagal, tetapi bangkit kembali setiap kali kita jatuh. –Confusius"
    },
    "2023-06-05": {
        "content": "Cinta itu layaknya angin, aku tidak bisa melihatnya tetapi aku bisa merasakannya. –Nicholas Sparck"
    },
    "2023-06-06": {
        "content": "Semakin tinggi ilmu seseorang, maka semakin tinggi toleransinya. –Gus Dur"
    },
    "2023-06-07": {
        "content": "Hiduplah seolah-olah Anda akan mati besok. Belajarlah seolah Anda hidup selamanya. –Mahatma Gandhi"
    },
    "2023-06-08": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2023-06-09": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2023-06-10": {
        "content": "Tidak ada hal apapun di dunia ini yang lebih berharga dari persahabatan sejati –Thomas Aquinas"
    },
    "2023-06-11": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2023-06-12": {
        "content": "Ketika kamu mengubah pikiran, ingatlah untuk juga mengubah duniamu juga —Norman Vincent Peale"
    },
    "2023-06-13": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2023-06-14": {
        "content": "Kamu bisa menjadi segalanya. Kamu bisa menjadi orang yang tidak terbatas jumlahnya. ” –Kesha"
    },
    "2023-06-15": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2023-06-16": {
        "content": "Ingatlah bahwa kita hanya bisa hidup saat ini, dalam waktu yang sekarang. –Jean-Paul Sartre"
    },
    "2023-06-17": {
        "content": "Seseorang yang benar-benar hebat merupakan orang yang membuat tiap orang merasa hebat –G.K. Chesterton."
    },
    "2023-06-18": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2023-06-19": {
        "content": "Jangan khawatir tentang kegagalan, khawatirkan tentang peluang yang kamu lewatkan ketika kamu bahkan tidak mencoba. –Jack Canfield"
    },
    "2023-06-20": {
        "content": "Kebijaksanaan bukanlah produk dari sekolah tetapi dari usaha seumur hidup untuk mendapatkannya. –Albert Einstein"
    },
    "2023-06-21": {
        "content": "Kesuksesan biasanya datang pada mereka yang terlalu sibuk untuk mencarinya. -Henry David Thoreau"
    },
    "2023-06-22": {
        "content": "Tujuan hidup kita adalah untuk menjadi bahagia. -Dalai Lama"
    },
    "2023-06-23": {
        "content": "Bagaimana kau mengeja 'cinta'? tanya Piglet.Kau tak usah mengejanya….rasakan saja. Jawab Pooh. -A.A Milne"
    },
    "2023-06-24": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2023-06-25": {
        "content": "Keindahan persahabatan adalah bahwa kamu tahu kepada siapa kamu dapat mempercayakan rahasia –Alessandro Manzoni"
    },
    "2023-06-26": {
        "content": "Kesempurnaan adalah hasil dari usaha dan keteguhan dalam mengejar kebaikan. –Ibn Taymiyyah"
    },
    "2023-06-27": {
        "content": "Cinta adalah ketika kebahagiaan orang lain lebih penting dari kebahagiaanmu. -H. Jackson Brown"
    },
    "2023-06-28": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan –Shakuntala Devi"
    },
    "2023-06-29": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2023-06-30": {
        "content": "​Sukses adalah jumlah dari usaha-usaha kecil, berulang-ulang hari demi hari –Robert Collier"
    },
    "2023-07-01": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2023-07-02": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2023-07-03": {
        "content": "Kesabaran adalah kekuatan; kebitteran adalah kelemahan. –Immanuel Kant"
    },
    "2023-07-04": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2023-07-05": {
        "content": "Sesuatu akan terlihat berkesan jika hal tersebut dapat bermakna dan penuh warna –Joshua Foer."
    },
    "2023-07-06": {
        "content": "Cinta itu bukan hanya perasaan senang ketika bersamanya Cinta juga adalah komitmen untuk tetap bersamanya, ketika perasaan senang itu hilang –Merry Riana"
    },
    "2023-07-07": {
        "content": "Sukses bukanlah akhir, kegagalan bukanlah fatal, yang terpenting adalah keberanian untuk melanjutkan –Winston S. Churchill"
    },
    "2023-07-08": {
        "content": "Jangan biarkan rasa takut kalah lebih besar daripada kegembiraan saat menang –Robert Kiyosaki"
    },
    "2023-07-09": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2023-07-10": {
        "content": "Kamu tidak selalu membutuhkan rencana. Terkadang kamu hanya perlu bernapas, percaya, melepaskan dan melihat apa yang terjadi –Mandy Hale"
    },
    "2023-07-11": {
        "content": "Berjuang untuk sukses tanpa kerja keras seperti mencoba memanen ketika kamu belum menanam –David Bly"
    },
    "2023-07-12": {
        "content": "Anda memiliki otak di kepala Anda. Anda memiliki kaki di sepatu Anda. Anda dapat mengarahkan diri Anda ke arah mana pun yang Anda pilih. -Dr. Seuss"
    },
    "2023-07-13": {
        "content": "Aku menginginkanmu seutuhnya, selamanya, kamu dan aku, setiap hari. –The Notebook, Nicholas Sparks"
    },
    "2023-07-14": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2023-07-15": {
        "content": "Harta yang paling berharga adalah hati yang bertakwa. –Umar bin Khattab"
    },
    "2023-07-16": {
        "content": "Bekerja keraslah dengan diam. Kesuksesan adalah suaramu –Frank Ocean."
    },
    "2023-07-17": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2023-07-18": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2023-07-19": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2023-07-20": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2023-07-21": {
        "content": "Orang yang memiliki sasaran akan menjadi sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2023-07-22": {
        "content": "Aku memilih untuk membuat sisa hidupku menjadi yang terbaik dalam hidupku. -Louise Hay"
    },
    "2023-07-23": {
        "content": "Tidak ada yang tidak mungkin. Kata itu sendiri mengatakan 'Saya mungkin!'” —Audrey Hepburn"
    },
    "2023-07-24": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2023-07-25": {
        "content": "Cinta bukan mengajar kita lemah, tetapi membangkitkan kekuatan Cinta bukan mengajar kita menghinakan diri, tetapi mengembuskan kegagahan Cinta bukan melemahkan semangat, tetapi membangkitkan semangat –Buya Hamka"
    },
    "2023-07-26": {
        "content": "Cara terbaik untuk menyiapkan kehidupan adalah memulai hidup –Elbert Hubbard."
    },
    "2023-07-27": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2023-07-28": {
        "content": "Jadilah diri Anda sendiri; semua orang sudah menjadi orang lain. -Oscar Wilde"
    },
    "2023-07-29": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2023-07-30": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2023-07-31": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2023-08-01": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2023-08-02": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2023-08-03": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2023-08-04": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. –Wayne Huizenga"
    },
    "2023-08-05": {
        "content": "Hadiah terbesar dalam hidup ini adalah persahabatan, dan aku telah mendapatkannya –Hubert H Humphrey"
    },
    "2023-08-06": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan. -Shakuntala Devi"
    },
    "2023-08-07": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2023-08-08": {
        "content": "Tidak ada kata terlambat untuk menjadi dirimu yang seharusnya. –George Eliot"
    },
    "2023-08-09": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2023-08-10": {
        "content": "Lakukanlah sesuatu hari ini yang akan membuatmu berterima kasih kepada orang lain di masa depan nanti –Sean Patrick."
    },
    "2023-08-11": {
        "content": "Semakin kau tahu tentang jati diri dan sesuatu yang kau inginkan, semakin sedikit pula kau membiarkan berbagai hal yang membuatmu kesal berlalu –Stephanie Perkins."
    },
    "2023-08-12": {
        "content": "Hidup tanpa tujuan adalah seperti menelusuri lautan tanpa kompas. –Thomas Carlyle"
    },
    "2023-08-13": {
        "content": "Kabar buruknya adalah waktu berlalu begitu saja. Kabar baiknya adalah kamu adalah pilotnya. —Michael Altshuler"
    },
    "2023-08-14": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. -Zig Ziglar"
    },
    "2023-08-15": {
        "content": "Keadilan adalah tanda kekuatan dan keberanian. –Ali bin Abi Thalib"
    },
    "2023-08-16": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2023-08-17": {
        "content": "Sebab sahabat itu saling tumbuh, bukan yang hilang ketika satunya jatuh –Boy Candra"
    },
    "2023-08-18": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2023-08-19": {
        "content": "Jika kamu melihat apa yang kamu miliki dalam hidup, kamu akan selalu memiliki lebih banyak. Jika kamu melihat apa yang tidak kamu miliki dalam hidup, kamu tidak akan pernah merasa cukup. –Oprah Winfrey"
    },
    "2023-08-20": {
        "content": "Sebarkan cinta ke mana pun kamu pergi. Jangan biarkan orang lain mendatangimu tanpa mendapatkan hal yang lebih bahagia. –Bunda Teresa"
    },
    "2023-08-21": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2023-08-22": {
        "content": "Jika kamu berpikir kamu terlalu kecil untuk membuat sebuah perubahan, cobalah tidur di ruangan dengan seekor nyamuk. -Dalai Lama"
    },
    "2023-08-23": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2023-08-24": {
        "content": "Pikiran yang baik dan hati yang baik selalu merupakan kombinasi yang hebat –Nelson Mandela"
    },
    "2023-08-25": {
        "content": "Kejujuran adalah batu penjuru dari segala kesuksesan. Pengakuan adalah motivasi terkuat. Bahkan kritik dapat membangun rasa percaya diri saat 'disisipkan' di antara pujian. –May Kay Ash"
    },
    "2023-08-26": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2023-08-27": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2023-08-28": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2023-08-29": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2023-08-30": {
        "content": "Jangan katakan tidak punya cukup waktu. Anda memiliki jumlah jam yang persis sama per hari yang diberikan kepada Helen Keller, Pasteur, Michelangelo, Mother Teresa, Leonardo da Vinci, Thomas Jefferson, dan Albert Einstein. –H. Jackson Brown Jr"
    },
    "2023-08-31": {
        "content": "Kebahagiaan dirasakan oleh orang-orang yang dapat merasa puas kepada dirinya sendiri –Agatha Christie."
    },
    "2023-09-01": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2023-09-02": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2023-09-03": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2023-09-04": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2023-09-05": {
        "content": "Kamu dapat menipu beberapa orang setiap saat dan semua orang pada suatu waktu, tetapi kamu tidak dapat membodohi semua orang setiap saat. -Abraham Lincol"
    },
    "2023-09-06": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2023-09-07": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2023-09-08": {
        "content": "Kesuksesan bersumber dari perbuatan. Orang yang sukses terus melakukan usaha. Orang sukses bahkan membuat kesalahan, namun mereka tidak berhenti usaha –Cobrad Hilton"
    },
    "2023-09-09": {
        "content": "Ini adalah agamaku yang sederhana. Tak perlu ada kuil; tak perlu filosofi yang rumit. Otak kita sendiri, hati kita sendiri adalah kuil kita; filosofinya adalah kebaikan –Dalai Lama."
    },
    "2023-09-10": {
        "content": "Jika kamu ingin menjalani hidup yang bahagia, ikatlah dengan tujuan, bukan pada orang atau benda. –Albert Einstein"
    },
    "2023-09-11": {
        "content": "Saya telah gagal berkali-kali dalam hidup. Dan itulah mengapa saya berhasil. –Michael Jordan"
    },
    "2023-09-12": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2023-09-13": {
        "content": "Hidup yang baik adalah yang terinspirasi dari cinta dan dipandu oleh ilmu pengetahuan. –Bertrand Russell."
    },
    "2023-09-14": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2023-09-15": {
        "content": "Rasa bahagia dan tak bahagia bukan berasal dari apa yang kamu miliki, bukan pula berasal dari siapa diri kamu, atau apa yang kamu kerjakan. Bahagia dan tak bahagia berasal dari pikiran kamu. –Dale Carnegie"
    },
    "2023-09-16": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2023-09-17": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2023-09-18": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2023-09-19": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2023-09-20": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2023-09-21": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2023-09-22": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2023-09-23": {
        "content": "Orang bijak belajar ketika mereka bisa. Orang bodoh belajar ketika mereka dipaksa. -Arthur Wellesley"
    },
    "2023-09-24": {
        "content": "Jangan tinggalkan apa pun untuk hari esok yang bisa dilakukan hari ini. -Abraham Lincoln"
    },
    "2023-09-25": {
        "content": "Bukan gunung di depan untuk didaki yang membuatmu lelah, melainkan kerikil di sepatumu. –Muhammad Ali"
    },
    "2023-09-26": {
        "content": "Satu-satunya cara untuk mencapai yang tidak mungkin adalah percaya bahwa itu mungkin –Charles Kingsleigh"
    },
    "2023-09-27": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2023-09-28": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2023-09-29": {
        "content": "Menjadi diri sendiri di dunia yang terus-menerus berusaha menjadikan Anda orang lain adalah pencapaian terbesar. -Ralph Waldo Emerson"
    },
    "2023-09-30": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2023-10-01": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2023-10-02": {
        "content": "Banyak orang gagal dalam kehidupan, bukan karena kurangnya kemampuan, pengetahuan, atau keberanian, namun hanya karena mereka tidak pernah mengatur energinya pada sasaran –Elbert Hubbard"
    },
    "2023-10-03": {
        "content": "Kebijaksanaan sejati adalah mengenal diri sendiri. –Ali bin Abi Thalib"
    },
    "2023-10-04": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2023-10-05": {
        "content": "Orang paling kesepian memiliki kepribadian yang baik hati. Orang paling sedih memiliki senyum paling cerah. Orang paling rusak memiliki kebijaksanaan yang luas. Semua itu karena mereka tidak ingin orang lain menderita seperti mereka –Zarathustra."
    },
    "2023-10-06": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2023-10-07": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2023-10-08": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2023-10-09": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2023-10-10": {
        "content": "Anda tidak dapat menjelekkan tanah di mana Anda berdiri. –Arthur Schopenhauer"
    },
    "2023-10-11": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2023-10-12": {
        "content": "Jadilah seperti bebek. Tenang di permukaan tapi selalu mengayuh seperti ayam di bawahnya. -Michael Caine"
    },
    "2023-10-13": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2023-10-14": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. –Bunda Teresa"
    },
    "2023-10-15": {
        "content": "Tujuan pendidikan itu untuk mempertajam kecerdasan, memperkukuh kemauan serta memperhalus perasaan. –Tan Malaka"
    },
    "2023-10-16": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. –John F. Kennedy"
    },
    "2023-10-17": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2023-10-18": {
        "content": "Tuntutlah ilmu, tapi tidak melupakan ibadah. Kerjakanlah ibadah tapi tidak boleh lupa pada ilmu. –Hassan Al Bashri"
    },
    "2023-10-19": {
        "content": "Mimpi adalah jendela ke dalam alam bawah sadar. –Sigmund Freud"
    },
    "2023-10-20": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2023-10-21": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti. –Kahlil Gibran, Pujangga"
    },
    "2023-10-22": {
        "content": "Tindakan manusia dapat dimodifikasi sampai batas tertentu, tetapi sifat manusia tidak dapat diubah. –Abraham Lincoln"
    },
    "2023-10-23": {
        "content": "Berjuang sampai napas terakhir –William Shakespeare"
    },
    "2023-10-24": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2023-10-25": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. –Benjamin Franklin"
    },
    "2023-10-26": {
        "content": "Keyakinan menciptakan fakta yang sebenarnya –William James"
    },
    "2023-10-27": {
        "content": "Sahabat adalah keluarga yang Anda pilih –Jess C Scott"
    },
    "2023-10-28": {
        "content": "Percayalah, jika dia memang cinta sejati kau, mau semenyakitkan apa pun, mau seberapa sulit liku yang harus dilalui, dia tetap akan bersama kau kelak, suatu saat nanti. –Tere Liye"
    },
    "2023-10-29": {
        "content": "Anda akan menghadapi banyak kekalahan dalam hidup, tetapi jangan pernah membiarkan diri Anda dikalahkan. -Maya Angelou"
    },
    "2023-10-30": {
        "content": "Siapapun yang bahagia akan membuat orang lain juga bahagia. –Anne Frank"
    },
    "2023-10-31": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2023-11-01": {
        "content": "Kau harus membiarkan orang lain untuk pergi. Setiap orang yang ada dalam kehidupanmu ditakdirkan untuk berada dalam perjalanan, tetapi tidak semuanya dimaksudkan untuk tinggal hingga akhir –Vasco da Gama."
    },
    "2023-11-02": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2023-11-03": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2023-11-04": {
        "content": "Pengetahuan tidak memiliki nilai kecuali jika dipraktekkan. –Thomas Aquinas"
    },
    "2023-11-05": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2023-11-06": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2023-11-07": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2023-11-08": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2023-11-09": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2023-11-10": {
        "content": "Keselamatan adalah hasil akhir dari kesabaran. –Ibn Taymiyyah"
    },
    "2023-11-11": {
        "content": "Tidak masalah seberapa lambat kau berjalan asalkan kau tidak berhenti. -Confucius"
    },
    "2023-11-12": {
        "content": "Kegelapan tidak dapat mengusir kegelapan: hanya cahaya yang dapat melakukannya. Kebencian tidak dapat mengusir kebencian: hanya cinta yang dapat melakukannya. -Martin Luther King Jr."
    },
    "2023-11-13": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2023-11-14": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2023-11-15": {
        "content": "Kita adalah apa yang kita pikirkan. –Marcus Aurelius"
    },
    "2023-11-16": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2023-11-17": {
        "content": "Tiada awan di langit yang tetap selamanya. Tiada mungkin akan terus-menerus terang cuaca. Sehabis malam gelap gulita lahir pagi membawa keindahan. Kehidupan manusia serupa alam. –RA Kartini"
    },
    "2023-11-18": {
        "content": "Cinta yang tak dewasa: 'Aku mencintaimu karena aku membutuhkanmu' Cinta yang dewasa: 'Aku membutuhkanmu karena aku mencintaimu' –Erich Fromm"
    },
    "2023-11-19": {
        "content": "Karena cinta, duri menjadi mawar. Karena cinta, cuka menjelma anggur segar. –Jalaluddin Rumi"
    },
    "2023-11-20": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2023-11-21": {
        "content": "Banyak kegagalan dalam hidup adalah orang-orang yang tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. -Thomas A. Edison"
    },
    "2023-11-22": {
        "content": "Kemuliaan terbesar dalam hidup bukan terletak pada tidak pernah jatuh, tetapi bangkit setiap kali kita jatuh. -Nelson Mandela"
    },
    "2023-11-23": {
        "content": "Kehidupan adalah pengorbanan untuk mencapai kedamaian batin. –Ibn Hazm"
    },
    "2023-11-24": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2023-11-25": {
        "content": "Kamu menjadi apa yang kamu yakini. -Oprah Winfrey"
    },
    "2023-11-26": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2023-11-27": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2023-11-28": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2023-11-29": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2023-11-30": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2023-12-01": {
        "content": "Orang yang meninggal memperoleh lebih banyak bunga dibandingkan orang yang masih hidup karena penyesalan lebih kuat dibandingkan rasa syukur –Anne Frank."
    },
    "2023-12-02": {
        "content": "Cintai hidup yang kau jalani. Jalani hidup yang kau cintai. –Bob Marley"
    },
    "2023-12-03": {
        "content": "Janganlah menganggap remeh hal-hal yang terdekat dengan hatimu. Rangkullah mereka seperti sama berharganya dengan hidupmu, karena tanpa mereka hidup adalah sia-sia –Peribahasa Cina"
    },
    "2023-12-04": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2023-12-05": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2023-12-06": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2023-12-07": {
        "content": "Saya tidak memikirkan semua penderitaan, tetapi keindahan yang masih tersisa. –Anne Frank"
    },
    "2023-12-08": {
        "content": "Dunia ini adalah sebuah cermin bagi pikiran kita sendiri. –Baruch Spinoza"
    },
    "2023-12-09": {
        "content": "Jika tindakanmu menginspirasi orang lain untuk bermimpi lebih banyak, belajar lebih banyak, berbuat lebih banyak, dan menjadi lebih sukses, kamu adalah seorang pemimpin –John Quincy Adams"
    },
    "2023-12-10": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2023-12-11": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2023-12-12": {
        "content": "Sebelum apa pun, persiapan adalah kunci menuju kesuksesan. -Alexander Graham Bell"
    },
    "2023-12-13": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2023-12-14": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2023-12-15": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2023-12-16": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2023-12-17": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2023-12-18": {
        "content": "Ketika kesulitan datang, ingatlah bahwa Allah selalu bersamamu. –Jalal ad-Din Rumi"
    },
    "2023-12-19": {
        "content": "Lebih baik mempunyai kehidupan yang bermakna dan memberikan perbedaan dibandingkan hanya mempunyai umur yang panjang –Bryant H. Mc.Gill."
    },
    "2023-12-20": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2023-12-21": {
        "content": "Memaafkan belum tentu membuat kita lebih baik atau bahkan merasa lebih baik tetapi yang jelas membuka jalan kebaikan.–Eleanor Roosevelt"
    },
    "2023-12-22": {
        "content": "Pencarian ilmu adalah ibadah yang paling utama. –Abu Yusuf al-Qadi"
    },
    "2023-12-23": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2023-12-24": {
        "content": "Jika Anda tidak mampu berpikir secara jernih, tidak Anda mempunyai hak untuk bertindak. –Martin Heidegger"
    },
    "2023-12-25": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2023-12-26": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2023-12-27": {
        "content": "Bangun dan wujudkan mimpimu atau orang lain akan memperkerjakanmu untuk membangun mimpi mereka –Farrah Gray"
    },
    "2023-12-28": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2023-12-29": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2023-12-30": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2023-12-31": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2024-01-01": {
        "content": "Cara terbaik untuk menyiapkan kehidupan adalah memulai hidup –Elbert Hubbard."
    },
    "2024-01-02": {
        "content": "Jadilah seperti bebek. Tenang di permukaan tapi selalu mengayuh seperti ayam di bawahnya. -Michael Caine"
    },
    "2024-01-03": {
        "content": "Kita memahami dunia tidak sebagaimana ia adalah, tetapi sebagaimana kita. –Immanuel Kant"
    },
    "2024-01-04": {
        "content": "Sebab sahabat itu saling tumbuh, bukan yang hilang ketika satunya jatuh –Boy Candra"
    },
    "2024-01-05": {
        "content": "Sukses bukanlah akhir, kegagalan bukanlah fatal, yang terpenting adalah keberanian untuk melanjutkan –Winston S. Churchill"
    },
    "2024-01-06": {
        "content": "Mimpi adalah jendela ke dalam alam bawah sadar. –Sigmund Freud"
    },
    "2024-01-07": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. -Zig Ziglar"
    },
    "2024-01-08": {
        "content": "Tiga hal dalam hidup –kesehatanmu, misimu dan orang yang kamu cintai. –Naval Ravikant"
    },
    "2024-01-09": {
        "content": "Orang bijak belajar ketika mereka bisa. Orang bodoh belajar ketika mereka dipaksa. -Arthur Wellesley"
    },
    "2024-01-10": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2024-01-11": {
        "content": "Sulit untuk mengalahkan orang yang tidak pernah menyerah –Babe Ruth"
    },
    "2024-01-12": {
        "content": "Ketika kamu mengubah pikiran, ingatlah untuk juga mengubah duniamu juga —Norman Vincent Peale"
    },
    "2024-01-13": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2024-01-14": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2024-01-15": {
        "content": "Sesuatu akan terlihat berkesan jika hal tersebut dapat bermakna dan penuh warna –Joshua Foer."
    },
    "2024-01-16": {
        "content": "Diam adalah sumber dari kekuatan yang luar biasa. -Lao Tzu"
    },
    "2024-01-17": {
        "content": "Anda akan menghadapi banyak kekalahan dalam hidup, tetapi jangan pernah membiarkan diri Anda dikalahkan. -Maya Angelou"
    },
    "2024-01-18": {
        "content": "Memaafkan belum tentu membuat kita lebih baik atau bahkan merasa lebih baik tetapi yang jelas membuka jalan kebaikan.–Eleanor Roosevelt"
    },
    "2024-01-19": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2024-01-20": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2024-01-21": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2024-01-22": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. -Benjamin Franklin"
    },
    "2024-01-23": {
        "content": "Semakin tinggi ilmu seseorang, maka semakin tinggi toleransinya. –Gus Dur"
    },
    "2024-01-24": {
        "content": "Hidup adalah rangkaian pelajaran yang harus dijalani untuk dipahami. –Ralph Waldo Emerson"
    },
    "2024-01-25": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2024-01-26": {
        "content": "Keindahan persahabatan adalah bahwa kamu tahu kepada siapa kamu dapat mempercayakan rahasia –Alessandro Manzoni"
    },
    "2024-01-27": {
        "content": "Cinta adalah ketika kebahagiaan orang lain lebih penting dari kebahagiaanmu. -H. Jackson Brown"
    },
    "2024-01-28": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2024-01-29": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2024-01-30": {
        "content": "Kebahagiaan dirasakan oleh orang-orang yang dapat merasa puas kepada dirinya sendiri –Agatha Christie."
    },
    "2024-01-31": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2024-02-01": {
        "content": "Kita adalah apa yang kita pikirkan. –Marcus Aurelius"
    },
    "2024-02-02": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2024-02-03": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2024-02-04": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2024-02-05": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2024-02-06": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2024-02-07": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2024-02-08": {
        "content": "Aku menginginkanmu seutuhnya, selamanya, kamu dan aku, setiap hari. –The Notebook, Nicholas Sparks"
    },
    "2024-02-09": {
        "content": "Banyak kegagalan dalam hidup adalah orang-orang yang tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. -Thomas A. Edison"
    },
    "2024-02-10": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2024-02-11": {
        "content": "Tindakan manusia dapat dimodifikasi sampai batas tertentu, tetapi sifat manusia tidak dapat diubah. –Abraham Lincoln"
    },
    "2024-02-12": {
        "content": "Bila Anda gagal hari ini, jangan pernah menyerah Ulangi terus kegagalan Anda sampai atasan/komandan Anda menyerah –Mario Teguh"
    },
    "2024-02-13": {
        "content": "Keyakinan menciptakan fakta yang sebenarnya –William James"
    },
    "2024-02-14": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2024-02-15": {
        "content": "Jika tindakanmu menginspirasi orang lain untuk bermimpi lebih banyak, belajar lebih banyak, berbuat lebih banyak, dan menjadi lebih sukses, kamu adalah seorang pemimpin –John Quincy Adams"
    },
    "2024-02-16": {
        "content": "Cinta terjadi begitu singkat, namun melupakan memakan waktu begitu lama. -Pablo Neruda"
    },
    "2024-02-17": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2024-02-18": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2024-02-19": {
        "content": "Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.–Ibnu Sina"
    },
    "2024-02-20": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2024-02-21": {
        "content": "Ini adalah agamaku yang sederhana. Tak perlu ada kuil; tak perlu filosofi yang rumit. Otak kita sendiri, hati kita sendiri adalah kuil kita; filosofinya adalah kebaikan –Dalai Lama."
    },
    "2024-02-22": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2024-02-23": {
        "content": "Cinta yang tak dewasa: 'Aku mencintaimu karena aku membutuhkanmu' Cinta yang dewasa: 'Aku membutuhkanmu karena aku mencintaimu' –Erich Fromm"
    },
    "2024-02-24": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2024-02-25": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara. -Shah Rukh Khan"
    },
    "2024-02-26": {
        "content": "Jangan khawatir jika Anda tidak diakui, tetapi berusahalah untuk menjadi layak diakui. -Abraham Lincoln"
    },
    "2024-02-27": {
        "content": "Pada saat-saat tergelaplah kita harus fokus untuk melihat cahaya. -Aristoteles"
    },
    "2024-02-28": {
        "content": "Ingatlah bahwa kita hanya bisa hidup saat ini, dalam waktu yang sekarang. –Jean-Paul Sartre"
    },
    "2024-02-29": {
        "content": "Harta yang paling berharga adalah hati yang bertakwa. –Umar bin Khattab"
    },
    "2024-03-01": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2024-03-02": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2024-03-03": {
        "content": "Kejujuran adalah batu penjuru dari segala kesuksesan. Pengakuan adalah motivasi terkuat. Bahkan kritik dapat membangun rasa percaya diri saat 'disisipkan' di antara pujian. –May Kay Ash"
    },
    "2024-03-04": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2024-03-05": {
        "content": "Kebahagiaan sama dengan kenyataan dikurangi ekspektasi. -Tom Magliozzi"
    },
    "2024-03-06": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2024-03-07": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2024-03-08": {
        "content": "Tindakan menyalahkan hanya akan membuang waktu. Sebesar apapun kesalahan yang kamu timpakan ke orang lain, dan sebesar apapun kamu menyalahkannya, hal tersebut tidak akan mengubahmu –Wayne Dyer"
    },
    "2024-03-09": {
        "content": "Kamu tidak bisa kembali dan mengubah awal saat kamu memulainya, tapi kamu bisa memulainya lagi dari di mana kamu berada sekarang dan ubah akhirnya. -C.S Lewis"
    },
    "2024-03-10": {
        "content": "Untuk sukses, sikap sama pentingnya dengan kemampuan. -Walter Scott"
    },
    "2024-03-11": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2024-03-12": {
        "content": "Jangan habiskan waktu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. –Coco Canel"
    },
    "2024-03-13": {
        "content": "Keselamatan adalah hasil akhir dari kesabaran. –Ibn Taymiyyah"
    },
    "2024-03-14": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan –Shakuntala Devi"
    },
    "2024-03-15": {
        "content": "Tujuan pendidikan itu untuk mempertajam kecerdasan, memperkukuh kemauan serta memperhalus perasaan. –Tan Malaka"
    },
    "2024-03-16": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2024-03-17": {
        "content": "Janganlah menganggap remeh hal-hal yang terdekat dengan hatimu. Rangkullah mereka seperti sama berharganya dengan hidupmu, karena tanpa mereka hidup adalah sia-sia –Peribahasa Cina"
    },
    "2024-03-18": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2024-03-19": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2024-03-20": {
        "content": " Orang yang memiliki sasaran sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2024-03-21": {
        "content": "Cinta bukan mengajar kita lemah, tetapi membangkitkan kekuatan Cinta bukan mengajar kita menghinakan diri, tetapi mengembuskan kegagahan Cinta bukan melemahkan semangat, tetapi membangkitkan semangat –Buya Hamka"
    },
    "2024-03-22": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2024-03-23": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2024-03-24": {
        "content": "Kebahagiaan dari hidupmu tergantung dari kualitas pikiranmu –Marcus Aurelius."
    },
    "2024-03-25": {
        "content": "Suatu pekerjaan yang paling tak kunjung bisa diselesaikan adalah pekerjaan yang tak kunjung pernah dimulai. –JRR Tolkien"
    },
    "2024-03-26": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2024-03-27": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2024-03-28": {
        "content": "Jangan biarkan rasa takut kalah lebih besar daripada kegembiraan saat menang –Robert Kiyosaki"
    },
    "2024-03-29": {
        "content": "Bukan gunung di depan untuk didaki yang membuatmu lelah, melainkan kerikil di sepatumu. –Muhammad Ali"
    },
    "2024-03-30": {
        "content": "Ubah lukamu menjadi kebijaksanaan. –Oprah Winfrey"
    },
    "2024-03-31": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2024-04-01": {
        "content": "Rahasia kesuksesan adalah melakukan hal yang biasa dengan sangat baik. -John D. Rockefeller Jr."
    },
    "2024-04-02": {
        "content": "Kabar buruknya adalah waktu berlalu begitu saja. Kabar baiknya adalah kamu adalah pilotnya. —Michael Altshuler"
    },
    "2024-04-03": {
        "content": "​Sukses adalah jumlah dari usaha-usaha kecil, berulang-ulang hari demi hari –Robert Collier"
    },
    "2024-04-04": {
        "content": "Tujuan hidup kita adalah untuk menjadi bahagia. -Dalai Lama"
    },
    "2024-04-05": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2024-04-06": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2024-04-07": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2024-04-08": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2024-04-09": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2024-04-10": {
        "content": "Anda tidak dapat menjelekkan tanah di mana Anda berdiri. –Arthur Schopenhauer"
    },
    "2024-04-11": {
        "content": "Hadiah terbesar dalam hidup ini adalah persahabatan, dan aku telah mendapatkannya –Hubert H Humphrey"
    },
    "2024-04-12": {
        "content": "Jadilah diri Anda sendiri; semua orang sudah menjadi orang lain. -Oscar Wilde"
    },
    "2024-04-13": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2024-04-14": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2024-04-15": {
        "content": "Kesuksesan bukanlah hal yang final; kegagalan bukanlah hal yang fatal: Yang terpenting adalah keberanian untuk terus maju. -Winston S. Churchill"
    },
    "2024-04-16": {
        "content": "Dunia itu seluas langkah kaki. Jelajahilah dan jangan pernah takut melangkah. Hanya dengan itu kita bisa mengerti kehidupan dan menyatu dengannya.–Soe Hok Gie"
    },
    "2024-04-17": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2024-04-18": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2024-04-19": {
        "content": "Kamu tidak selalu membutuhkan rencana. Terkadang kamu hanya perlu bernapas, percaya, melepaskan dan melihat apa yang terjadi –Mandy Hale"
    },
    "2024-04-20": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. -Maya Angelou"
    },
    "2024-04-21": {
        "content": "Hadapkan wajahmu selalu ke arah matahari, sehingga bayangan akan jatuh di belakangmu. -Walt Whitman"
    },
    "2024-04-22": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2024-04-23": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2024-04-24": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2024-04-25": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2024-04-26": {
        "content": "Kesempatan emas yang kau cari ada di dalam dirimu sendiri –Orison Sweet Marden."
    },
    "2024-04-27": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2024-04-28": {
        "content": "Jangan menunggu orang lain membahagiakanmu. Kebahagiaan apa pun yang Anda peroleh, harus Anda ciptakan sendiri. -Alice Walker"
    },
    "2024-04-29": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2024-04-30": {
        "content": "Ketika kesulitan datang, ingatlah bahwa Allah selalu bersamamu. –Jalal ad-Din Rumi"
    },
    "2024-05-01": {
        "content": "Jalan menuju sukses dan jalan menuju kegagalan hampir persis sama –Colin R. Davis"
    },
    "2024-05-02": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2024-05-03": {
        "content": "Aku memilih untuk membuat sisa hidupku menjadi yang terbaik dalam hidupku. -Louise Hay"
    },
    "2024-05-04": {
        "content": "Belajarlah seolah-olah kamu akan hidup selamanya, hiduplah seolah-olah kamu akan mati besok. –Mahatma Gandhi"
    },
    "2024-05-05": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2024-05-06": {
        "content": "Jauhi orang-orang yang mencoba meremehkan ambisimu. Pikiran kecil akan selalu melakukan itu, tetapi pikiran besar akan memberi perasaan bahwa kamu juga bisa menjadi hebat –Mark Twain"
    },
    "2024-05-07": {
        "content": "Tidak ada kata terlambat untuk menjadi dirimu yang seharusnya. –George Eliot"
    },
    "2024-05-08": {
        "content": "Pengetahuan tidak memiliki nilai kecuali jika dipraktekkan. –Thomas Aquinas"
    },
    "2024-05-09": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2024-05-10": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2024-05-11": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2024-05-12": {
        "content": "Kebahagiaan berada di dalam. Hal itu tidak ada hubungannya dengan seberapa banyak tepuk tangan yang kau peroleh atau seberapa banyak orang yang menyanjungmu. Kebahagiaan datang saat kau percaya telah melaksanakan sesuatu yang benar-benar berharga –Martin Yan."
    },
    "2024-05-13": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2024-05-14": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. –Zig Ziglar"
    },
    "2024-05-15": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. –Bunda Teresa"
    },
    "2024-05-16": {
        "content": "Hiduplah seolah-olah Anda akan mati besok. Belajarlah seolah Anda hidup selamanya. –Mahatma Gandhi"
    },
    "2024-05-17": {
        "content": "Karena cinta, duri menjadi mawar. Karena cinta, cuka menjelma anggur segar. –Jalaluddin Rumi"
    },
    "2024-05-18": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2024-05-19": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2024-05-20": {
        "content": "Percayalah, jika dia memang cinta sejati kau, mau semenyakitkan apa pun, mau seberapa sulit liku yang harus dilalui, dia tetap akan bersama kau kelak, suatu saat nanti. –Tere Liye"
    },
    "2024-05-21": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2024-05-22": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2024-05-23": {
        "content": "Kegelapan tidak dapat mengusir kegelapan: hanya cahaya yang dapat melakukannya. Kebencian tidak dapat mengusir kebencian: hanya cinta yang dapat melakukannya. -Martin Luther King Jr."
    },
    "2024-05-24": {
        "content": "Jangan tinggalkan apa pun untuk hari esok yang bisa dilakukan hari ini. -Abraham Lincoln"
    },
    "2024-05-25": {
        "content": "Cobalah untuk tidak menjadi orang yang sukses. Sebaliknya, jadilah orang yang bernilai. -Albert Einstein"
    },
    "2024-05-26": {
        "content": "Aku telah belajar jika ada lebih banyak kekuatan di dalam sebuah pelukan yang kuat dibandingkan dengan seribu kata-kata –Ann Hood."
    },
    "2024-05-27": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2024-05-28": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2024-05-29": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2024-05-30": {
        "content": "Cinta selalu saja misterius Jangan diburu-buru, atau kau akan merusak jalan ceritanya sendiri –Tere Liye"
    },
    "2024-05-31": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2024-06-01": {
        "content": "Berjuang sampai napas terakhir –William Shakespeare"
    },
    "2024-06-02": {
        "content": "Pikirkanlah sebelum berbicara, karena perkataan memiliki kekuatan yang besar. –Ali bin Abi Thalib"
    },
    "2024-06-03": {
        "content": "Hidup merupakan suatu perjalanan yang dapat dijadikan pengalaman, bukan sekadar masalah yang harus diselesaikan –C.S. Lewis."
    },
    "2024-06-04": {
        "content": "Hidup adalah yang terjadi saat kamu sibuk membuat rencana lain. –John Lennon"
    },
    "2024-06-05": {
        "content": "Seseorang tak akan pernah tahu betapa dalam kadar cintanya sampai terjadi sebuah perpisahan. -Kahlil Gibran"
    },
    "2024-06-06": {
        "content": "Anda memiliki otak di kepala Anda. Anda memiliki kaki di sepatu Anda. Anda dapat mengarahkan diri Anda ke arah mana pun yang Anda pilih. -Dr. Seuss"
    },
    "2024-06-07": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2024-06-08": {
        "content": "Arahkan mata Anda pada bintang-bintang dengan kaki tetap berpijak pada tanah. -Theodore Roosevelt"
    },
    "2024-06-09": {
        "content": "Tetap menghadapkan wajah selalu ke arah sinar matahari, dan bayangan akan jatuh di belakangmu –Walt Whitman"
    },
    "2024-06-10": {
        "content": "Kemuliaan terbesar dalam hidup tidak terletak pada tidak pernah jatuh, tetapi dalam bangkit setiap kali kita jatuh. –Nelson Mandela"
    },
    "2024-06-11": {
        "content": "Jangan pernah bermimpi untuk sukses, tapi kerjakan sesuatu untuk meraih kesuksesan –Ester Lauder"
    },
    "2024-06-12": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2024-06-13": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2024-06-14": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2024-06-15": {
        "content": "Tuntutlah ilmu, tapi tidak melupakan ibadah. Kerjakanlah ibadah tapi tidak boleh lupa pada ilmu. –Hassan Al Bashri"
    },
    "2024-06-16": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2024-06-17": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2024-06-18": {
        "content": "Kedamaian hilang karena kita lupa jika sebenarnya memiliki satu sama lain –Bunda Maria Teresa Bojaxhiu."
    },
    "2024-06-19": {
        "content": "Seseorang yang benar-benar hebat merupakan orang yang membuat tiap orang merasa hebat –G.K. Chesterton."
    },
    "2024-06-20": {
        "content": "Kebijaksanaan bukanlah produk dari sekolah tetapi dari usaha seumur hidup untuk mendapatkannya. –Albert Einstein"
    },
    "2024-06-21": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2024-06-22": {
        "content": "Anda hanya hidup sekali, tetapi jika Anda melakukannya dengan benar, sekali saja sudah cukup. -Mae West"
    },
    "2024-06-23": {
        "content": "Jangan takut akan kesulitan, karena itu adalah kesempatan untuk tumbuh. –Albert Einstein"
    },
    "2024-06-24": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2024-06-25": {
        "content": "Aku tidak takut badai karena telah belajar berlayar dengan kapalku –Louisa May Alcott."
    },
    "2024-06-26": {
        "content": "Rasa bahagia dan tak bahagia bukan berasal dari apa yang kamu miliki, bukan pula berasal dari siapa diri kamu, atau apa yang kamu kerjakan. Bahagia dan tak bahagia berasal dari pikiran kamu. –Dale Carnegie"
    },
    "2024-06-27": {
        "content": "Suatu ide yang berguna lebih berharga daripada kekayaan. –Socrates"
    },
    "2024-06-28": {
        "content": "Sebelum apa pun, persiapan adalah kunci menuju kesuksesan. -Alexander Graham Bell"
    },
    "2024-06-29": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2024-06-30": {
        "content": "Tiada awan di langit yang tetap selamanya. Tiada mungkin akan terus-menerus terang cuaca. Sehabis malam gelap gulita lahir pagi membawa keindahan. Kehidupan manusia serupa alam. –RA Kartini"
    },
    "2024-07-01": {
        "content": "Jangan khawatir tentang kegagalan, khawatirkan tentang peluang yang kamu lewatkan ketika kamu bahkan tidak mencoba. –Jack Canfield"
    },
    "2024-07-02": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2024-07-03": {
        "content": "Satu-satunya sumber dari pengertahuan adalah pengalaman. -Albert Einstein"
    },
    "2024-07-04": {
        "content": "Gagal itu makanan sehari-hari. Itu biasa, yang penting bagimana kamu menyikapinya. Evaluasi. Bangkit. Gagal lagi? Bangkit lagi!” –Chairul Tanjung"
    },
    "2024-07-05": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2024-07-06": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2024-07-07": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2024-07-08": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2024-07-09": {
        "content": "Rahasia dari kesuksesan kita adalah bahwa kita tidak pernah menyerah. –Ilma Mankiller"
    },
    "2024-07-10": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2024-07-11": {
        "content": "Cinta tak berupa tatapan satu sama lain, tetapi memandang keluar bersama ke arah yang sama. –B.J. Habibie"
    },
    "2024-07-12": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2024-07-13": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2024-07-14": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2024-07-15": {
        "content": "Bagaimana kau mengeja 'cinta'? tanya Piglet.Kau tak usah mengejanya….rasakan saja. Jawab Pooh. -A.A Milne"
    },
    "2024-07-16": {
        "content": "Perjalanan seribu mil dimulai dengan satu langkah. –Lao Tzu"
    },
    "2024-07-17": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2024-07-18": {
        "content": "Ketika Anda jatuh cinta, kebahagiaan akan membuat Anda sulit tertidur karena kenyataan lebih baik dibandingkan mimpi Anda –Dr Seuss"
    },
    "2024-07-19": {
        "content": "Jangan merusak sesuatu yang kau miliki dengan mengharapkan sesuatu yang tidak akan pernah kau miliki; ingatlah jika sesuatu yang kau miliki saat ini pernah menjadi salah satu hal yang kau harapkan –Epicurus."
    },
    "2024-07-20": {
        "content": "Hidup tanpa tujuan adalah seperti menelusuri lautan tanpa kompas. –Thomas Carlyle"
    },
    "2024-07-21": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2024-07-22": {
        "content": "Semuanya kelihatan tidak mungkin sampai segala sesuatu selesai. -Nelson Mandela"
    },
    "2024-07-23": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti. –Kahlil Gibran, Pujangga"
    },
    "2024-07-24": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2024-07-25": {
        "content": "Belajar tidak pernah melelahkan pikiran –Leonardo Da Vinci"
    },
    "2024-07-26": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2024-07-27": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2024-07-28": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2024-07-29": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2024-07-30": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan. -Shakuntala Devi"
    },
    "2024-07-31": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2024-08-01": {
        "content": "Pikiran yang baik dan hati yang baik selalu merupakan kombinasi yang hebat –Nelson Mandela"
    },
    "2024-08-02": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2024-08-03": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2024-08-04": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2024-08-05": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2024-08-06": {
        "content": "Cinta tidak terlihat dengan mata, tetapi dengan hati.–William Shakespeare"
    },
    "2024-08-07": {
        "content": "Tidak ada yang tidak mungkin. Kata itu sendiri mengatakan 'Saya mungkin!'” —Audrey Hepburn"
    },
    "2024-08-08": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. -Bunda Teresa"
    },
    "2024-08-09": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2024-08-10": {
        "content": "Lakukanlah sesuatu hari ini yang akan membuatmu berterima kasih kepada orang lain di masa depan nanti –Sean Patrick."
    },
    "2024-08-11": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2024-08-12": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2024-08-13": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2024-08-14": {
        "content": "Kamu bisa menjadi segalanya. Kamu bisa menjadi orang yang tidak terbatas jumlahnya. ” –Kesha"
    },
    "2024-08-15": {
        "content": "Jika kamu ingin menjalani hidup yang bahagia, ikatlah dengan tujuan, bukan pada orang atau benda. –Albert Einstein"
    },
    "2024-08-16": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2024-08-17": {
        "content": "Hidup itu sebentar. Kamu harus bisa tersenyum saat merasakan kepedihan atau kita tak akan pernah melanjutkan hidup.–Jeff Ross"
    },
    "2024-08-18": {
        "content": "Sahabat adalah keluarga yang Anda pilih –Jess C Scott"
    },
    "2024-08-19": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2024-08-20": {
        "content": "Kamu menjadi apa yang kamu yakini. -Oprah Winfrey"
    },
    "2024-08-21": {
        "content": "Lebih baik mempunyai kehidupan yang bermakna dan memberikan perbedaan dibandingkan hanya mempunyai umur yang panjang –Bryant H. Mc.Gill."
    },
    "2024-08-22": {
        "content": "Tidak masalah seberapa lambat kau berjalan asalkan kau tidak berhenti. -Confucius"
    },
    "2024-08-23": {
        "content": "Hanya mereka yang berani mengambil risiko melangkah terlalu jauh, yang mungkin bisa mengetahui seberapa jauh seseorang bisa melangkah. –TS Eliot"
    },
    "2024-08-24": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2024-08-25": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2024-08-26": {
        "content": "Cinta itu seperti halnya angin. Kita tidak dapat melihatnya, tetapi dapat merasakannya –Nicholas Sparks."
    },
    "2024-08-27": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2024-08-28": {
        "content": "Jika kamu benar-benar menginginkan sesuatu, jangan menunggu untuk itu –ajari dirimu untuk tidak sabar –Gurbaksh Chahal"
    },
    "2024-08-29": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2024-08-30": {
        "content": "Jangan katakan tidak punya cukup waktu. Anda memiliki jumlah jam yang persis sama per hari yang diberikan kepada Helen Keller, Pasteur, Michelangelo, Mother Teresa, Leonardo da Vinci, Thomas Jefferson, dan Albert Einstein. –H. Jackson Brown Jr"
    },
    "2024-08-31": {
        "content": "Dia, yang tidak cukup berani untuk mengambil risiko, tidak akan mencapai apa pun dalam hidup. –Muhammad Ali"
    },
    "2024-09-01": {
        "content": "Dunia ini adalah sebuah cermin bagi pikiran kita sendiri. –Baruch Spinoza"
    },
    "2024-09-02": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2024-09-03": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2024-09-04": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2024-09-05": {
        "content": "Tidak ada hal apapun di dunia ini yang lebih berharga dari persahabatan sejati –Thomas Aquinas"
    },
    "2024-09-06": {
        "content": "Jika kamu melihat apa yang kamu miliki dalam hidup, kamu akan selalu memiliki lebih banyak. Jika kamu melihat apa yang tidak kamu miliki dalam hidup, kamu tidak akan pernah merasa cukup. –Oprah Winfrey"
    },
    "2024-09-07": {
        "content": "Kegigihan adalah kunci kesuksesan dalam pencapaian tujuan. –Abdul Qadir al-Jilani"
    },
    "2024-09-08": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2024-09-09": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2024-09-10": {
        "content": "Sebarkan cinta ke mana pun kamu pergi. Jangan biarkan orang lain mendatangimu tanpa mendapatkan hal yang lebih bahagia. –Bunda Teresa"
    },
    "2024-09-11": {
        "content": "Siapapun yang bahagia akan membuat orang lain juga bahagia. –Anne Frank"
    },
    "2024-09-12": {
        "content": "Banyak orang gagal dalam kehidupan, bukan karena kurangnya kemampuan, pengetahuan, atau keberanian, namun hanya karena mereka tidak pernah mengatur energinya pada sasaran –Elbert Hubbard"
    },
    "2024-09-13": {
        "content": "Orang yang memiliki sasaran akan menjadi sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2024-09-14": {
        "content": "Kesuksesan bersumber dari perbuatan. Orang yang sukses terus melakukan usaha. Orang sukses bahkan membuat kesalahan, namun mereka tidak berhenti usaha –Cobrad Hilton"
    },
    "2024-09-15": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2024-09-16": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2024-09-17": {
        "content": "Berjuang untuk sukses tanpa kerja keras seperti mencoba memanen ketika kamu belum menanam –David Bly"
    },
    "2024-09-18": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2024-09-19": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2024-09-20": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2024-09-21": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2024-09-22": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2024-09-23": {
        "content": "Gravitasi tidak bertanggung-jawab atas orang yang jatuh cinta –Albert Einstein"
    },
    "2024-09-24": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2024-09-25": {
        "content": "Hidup yang baik adalah yang terinspirasi dari cinta dan dipandu oleh ilmu pengetahuan. –Bertrand Russell."
    },
    "2024-09-26": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2024-09-27": {
        "content": "Kebenaran adalah cahaya yang menghilangkan kegelapan. –al-Ghazali"
    },
    "2024-09-28": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2024-09-29": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2024-09-30": {
        "content": "Jika kamu berpikir kamu terlalu kecil untuk membuat sebuah perubahan, cobalah tidur di ruangan dengan seekor nyamuk. -Dalai Lama"
    },
    "2024-10-01": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2024-10-02": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2024-10-03": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2024-10-04": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2024-10-05": {
        "content": "Bangun dan wujudkan mimpimu atau orang lain akan memperkerjakanmu untuk membangun mimpi mereka –Farrah Gray"
    },
    "2024-10-06": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2024-10-07": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2024-10-08": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2024-10-09": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2024-10-10": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2024-10-11": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2024-10-12": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2024-10-13": {
        "content": "Cara paling mendasar dan kuat untuk terhubung dengan orang lain adalah dengan mendengarkan. Cukup Dengarkan. Mungkin hal terpenting yang bisa kita berikan kepada orang lain adalah perhatian kita –Rachel Naomi Remen"
    },
    "2024-10-14": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2024-10-15": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2024-10-16": {
        "content": "Cinta itu layaknya angin, aku tidak bisa melihatnya tetapi aku bisa merasakannya. –Nicholas Sparck"
    },
    "2024-10-17": {
        "content": "Kesuksesan biasanya datang pada mereka yang terlalu sibuk untuk mencarinya. -Henry David Thoreau"
    },
    "2024-10-18": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2024-10-19": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2024-10-20": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2024-10-21": {
        "content": "Kekuatan dan perkembangan datang hanya dari usaha dan perjuangan yang terus menerus. –Napoleon Hill"
    },
    "2024-10-22": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2024-10-23": {
        "content": "Menjadi diri sendiri di dunia yang terus-menerus berusaha menjadikan Anda orang lain adalah pencapaian terbesar. -Ralph Waldo Emerson"
    },
    "2024-10-24": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2024-10-25": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2024-10-26": {
        "content": "Kebahagiaan kita tergantung kepada diri kita sendiri –Aristoteles."
    },
    "2024-10-27": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2024-10-28": {
        "content": "Jika Anda tidak mampu berpikir secara jernih, tidak Anda mempunyai hak untuk bertindak. –Martin Heidegger"
    },
    "2024-10-29": {
        "content": "Hubungan asmara itu seperti kaca. Terkadang lebih baik meninggalkannya dalam keadaan pecah. Daripada menyakiti dirimu dengan cara menyatukan mereka kembali. -D.Love"
    },
    "2024-10-30": {
        "content": "Jangan habiskan waktumu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. -Coco Canel"
    },
    "2024-10-31": {
        "content": "Orang yang meninggal memperoleh lebih banyak bunga dibandingkan orang yang masih hidup karena penyesalan lebih kuat dibandingkan rasa syukur –Anne Frank."
    },
    "2024-11-01": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2024-11-02": {
        "content": "Ingatlah selalu bahwa kamu benar-benar unik. Sama seperti orang lain. –Margaret Mead"
    },
    "2024-11-03": {
        "content": "Keadilan adalah tanda kekuatan dan keberanian. –Ali bin Abi Thalib"
    },
    "2024-11-04": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2024-11-05": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2024-11-06": {
        "content": "Pencarian ilmu adalah ibadah yang paling utama. –Abu Yusuf al-Qadi"
    },
    "2024-11-07": {
        "content": "Cinta adalah kebenaran terbesar dan kebaikan tertinggi. –Plato"
    },
    "2024-11-08": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2024-11-09": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2024-11-10": {
        "content": "Orang paling kesepian memiliki kepribadian yang baik hati. Orang paling sedih memiliki senyum paling cerah. Orang paling rusak memiliki kebijaksanaan yang luas. Semua itu karena mereka tidak ingin orang lain menderita seperti mereka –Zarathustra."
    },
    "2024-11-11": {
        "content": "Cintai hidup yang kau jalani. Jalani hidup yang kau cintai. –Bob Marley"
    },
    "2024-11-12": {
        "content": "Kesempurnaan adalah hasil dari usaha dan keteguhan dalam mengejar kebaikan. –Ibn Taymiyyah"
    },
    "2024-11-13": {
        "content": "Saya tidak memikirkan semua penderitaan, tetapi keindahan yang masih tersisa. –Anne Frank"
    },
    "2024-11-14": {
        "content": "Semakin kau tahu tentang jati diri dan sesuatu yang kau inginkan, semakin sedikit pula kau membiarkan berbagai hal yang membuatmu kesal berlalu –Stephanie Perkins."
    },
    "2024-11-15": {
        "content": "Hidup adalah perjalanan spiritual menuju kebenaran mutlak. –Rumi"
    },
    "2024-11-16": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2024-11-17": {
        "content": "Kamu dapat menipu beberapa orang setiap saat dan semua orang pada suatu waktu, tetapi kamu tidak dapat membodohi semua orang setiap saat. -Abraham Lincol"
    },
    "2024-11-18": {
        "content": "Beri nilai dari usahanya jangan dari hasilnya. Baru kita bisa menilai kehidupan. -Albert Einstein"
    },
    "2024-11-19": {
        "content": "Sejauh apapun perpisahan, sesibuk apapun kita nanti, dan meski langkah kita berbeda, waktu tidak akan dapat memisahkan gelar 'sahabat' di antara kita –mnurulnatasha"
    },
    "2024-11-20": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. –John F. Kennedy"
    },
    "2024-11-21": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2024-11-22": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2024-11-23": {
        "content": "Anda ingin mengetahui siapa diri Anda? Jangan bertanya. Beraksilah! -Thomas Jefferson "
    },
    "2024-11-24": {
        "content": "Kita tidak hidup dari apa yang kita miliki, tetapi dari apa yang kita berikan. –Pablo Picasso"
    },
    "2024-11-25": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti.–Kahlil Gibran, Pujangga"
    },
    "2024-11-26": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2024-11-27": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2024-11-28": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2024-11-29": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2024-11-30": {
        "content": "Satu-satunya cara untuk mencapai yang tidak mungkin adalah percaya bahwa itu mungkin –Charles Kingsleigh"
    },
    "2024-12-01": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. –Benjamin Franklin"
    },
    "2024-12-02": {
        "content": "Penemuan terbesar sepanjang masa adalah bahwa seseorang bisa mengubah masa depannya hanya dengan mengubah sikapnya saat ini. -Oprah Winfrey"
    },
    "2024-12-03": {
        "content": "Kau harus membiarkan orang lain untuk pergi. Setiap orang yang ada dalam kehidupanmu ditakdirkan untuk berada dalam perjalanan, tetapi tidak semuanya dimaksudkan untuk tinggal hingga akhir –Vasco da Gama."
    },
    "2024-12-04": {
        "content": "Kemuliaan terbesar dalam hidup bukan terletak pada tidak pernah jatuh, tetapi bangkit setiap kali kita jatuh. -Nelson Mandela"
    },
    "2024-12-05": {
        "content": "Satu-satunya tempat di mana kesuksesan datang sebelum bekerja adalah di kamus. -Vidal Sassoon"
    },
    "2024-12-06": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2024-12-07": {
        "content": "Dia yang mengetahui semua jawaban belum pernah memperoleh pertanyaan –Confucius."
    },
    "2024-12-08": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2024-12-09": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2024-12-10": {
        "content": "Kita harus belajar untuk hidup bersama sebagai saudara atau kita akan binasa bersama-sama sebagai orang bodoh. –Martin Luther King"
    },
    "2024-12-11": {
        "content": "Tidak apa-apa merayakan kesuksesan, tapi lebih penting untuk memperhatikan pelajaran tentang kegagalan.  –Bill Gates"
    },
    "2024-12-12": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2024-12-13": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2024-12-14": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2024-12-15": {
        "content": "Kebebasan adalah hak setiap individu. –John Locke"
    },
    "2024-12-16": {
        "content": "Kebijaksanaan sejati adalah mengenal diri sendiri. –Ali bin Abi Thalib"
    },
    "2024-12-17": {
        "content": "Kesabaran adalah kekuatan; kebitteran adalah kelemahan. –Immanuel Kant"
    },
    "2024-12-18": {
        "content": "Apa yang kita pikirkan menentukan apa yang akan terjadi pada kita. Jadi jika kita ingin mengubah hidup, kita perlu sedikit mengubah pikiran kita. –Wayne Dyer."
    },
    "2024-12-19": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2024-12-20": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2024-12-21": {
        "content": "Kehidupan adalah pengorbanan untuk mencapai kedamaian batin. –Ibn Hazm"
    },
    "2024-12-22": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2024-12-23": {
        "content": "Kamu hanya gagal saat kamu berhenti mencoba –Albert Einstein"
    },
    "2024-12-24": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2024-12-25": {
        "content": "Tidak ada akhir untuk pendidikan. Bukan berarti Anda membaca buku, lulus ujian, dan menyelesaikan pendidikan. Seluruh kehidupan, dari saat Anda lahir hingga saat Anda mati, adalah proses pembelajaran. –Jiddu Krishnamurti"
    },
    "2024-12-26": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2024-12-27": {
        "content": "Cinta itu bukan hanya perasaan senang ketika bersamanya Cinta juga adalah komitmen untuk tetap bersamanya, ketika perasaan senang itu hilang –Merry Riana"
    },
    "2024-12-28": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2024-12-29": {
        "content": "Kau tidak pernah dilahirkan untuk hidup kalah, tertekan, bersalah, malu, terkutuk, dan tidak layak. Kau dilahirkan untuk menjadi seorang pemenang –Mikhail Sergeyevich Gorbachev."
    },
    "2024-12-30": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2024-12-31": {
        "content": "Bekerja keraslah dengan diam. Kesuksesan adalah suaramu –Frank Ocean."
    },
    "2025-01-01": {
        "content": "Kebebasan adalah hak setiap individu. –John Locke"
    },
    "2025-01-02": {
        "content": "Tetap menghadapkan wajah selalu ke arah sinar matahari, dan bayangan akan jatuh di belakangmu –Walt Whitman"
    },
    "2025-01-03": {
        "content": "Apa yang kita pikirkan menentukan apa yang akan terjadi pada kita. Jadi jika kita ingin mengubah hidup, kita perlu sedikit mengubah pikiran kita. –Wayne Dyer."
    },
    "2025-01-04": {
        "content": "Sebab sahabat itu saling tumbuh, bukan yang hilang ketika satunya jatuh –Boy Candra"
    },
    "2025-01-05": {
        "content": "Aku telah belajar jika ada lebih banyak kekuatan di dalam sebuah pelukan yang kuat dibandingkan dengan seribu kata-kata –Ann Hood."
    },
    "2025-01-06": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2025-01-07": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2025-01-08": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2025-01-09": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2025-01-10": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2025-01-11": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan –Shakuntala Devi"
    },
    "2025-01-12": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2025-01-13": {
        "content": "Sukses bukanlah akhir, kegagalan bukanlah fatal, yang terpenting adalah keberanian untuk melanjutkan –Winston S. Churchill"
    },
    "2025-01-14": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2025-01-15": {
        "content": "Keselamatan adalah hasil akhir dari kesabaran. –Ibn Taymiyyah"
    },
    "2025-01-16": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. –Wayne Huizenga"
    },
    "2025-01-17": {
        "content": "Kebahagiaan dari hidupmu tergantung dari kualitas pikiranmu –Marcus Aurelius."
    },
    "2025-01-18": {
        "content": "Jadilah seperti bebek. Tenang di permukaan tapi selalu mengayuh seperti ayam di bawahnya. -Michael Caine"
    },
    "2025-01-19": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2025-01-20": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2025-01-21": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2025-01-22": {
        "content": "Hiduplah seolah-olah Anda akan mati besok. Belajarlah seolah Anda hidup selamanya. –Mahatma Gandhi"
    },
    "2025-01-23": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2025-01-24": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2025-01-25": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2025-01-26": {
        "content": "Anda memiliki otak di kepala Anda. Anda memiliki kaki di sepatu Anda. Anda dapat mengarahkan diri Anda ke arah mana pun yang Anda pilih. -Dr. Seuss"
    },
    "2025-01-27": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2025-01-28": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. -Benjamin Franklin"
    },
    "2025-01-29": {
        "content": "Cinta bukan mengajar kita lemah, tetapi membangkitkan kekuatan Cinta bukan mengajar kita menghinakan diri, tetapi mengembuskan kegagahan Cinta bukan melemahkan semangat, tetapi membangkitkan semangat –Buya Hamka"
    },
    "2025-01-30": {
        "content": "Sahabat adalah keluarga yang Anda pilih –Jess C Scott"
    },
    "2025-01-31": {
        "content": "Perjalanan seribu mil dimulai dengan satu langkah. –Lao Tzu"
    },
    "2025-02-01": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2025-02-02": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. –John F. Kennedy"
    },
    "2025-02-03": {
        "content": "Kemuliaan terbesar dalam hidup tidak terletak pada tidak pernah jatuh, tetapi dalam bangkit setiap kali kita jatuh. –Nelson Mandela"
    },
    "2025-02-04": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2025-02-05": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2025-02-06": {
        "content": "Keadilan adalah tanda kekuatan dan keberanian. –Ali bin Abi Thalib"
    },
    "2025-02-07": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. -Maya Angelou"
    },
    "2025-02-08": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2025-02-09": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2025-02-10": {
        "content": "Kamu tidak selalu membutuhkan rencana. Terkadang kamu hanya perlu bernapas, percaya, melepaskan dan melihat apa yang terjadi –Mandy Hale"
    },
    "2025-02-11": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2025-02-12": {
        "content": "Kegigihan adalah kunci kesuksesan dalam pencapaian tujuan. –Abdul Qadir al-Jilani"
    },
    "2025-02-13": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2025-02-14": {
        "content": "Kita harus belajar untuk hidup bersama sebagai saudara atau kita akan binasa bersama-sama sebagai orang bodoh. –Martin Luther King"
    },
    "2025-02-15": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2025-02-16": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2025-02-17": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2025-02-18": {
        "content": "Siapapun yang bahagia akan membuat orang lain juga bahagia. –Anne Frank"
    },
    "2025-02-19": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2025-02-20": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2025-02-21": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. –Zig Ziglar"
    },
    "2025-02-22": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2025-02-23": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2025-02-24": {
        "content": "Tiga hal dalam hidup –kesehatanmu, misimu dan orang yang kamu cintai. –Naval Ravikant"
    },
    "2025-02-25": {
        "content": "Tindakan manusia dapat dimodifikasi sampai batas tertentu, tetapi sifat manusia tidak dapat diubah. –Abraham Lincoln"
    },
    "2025-02-26": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2025-02-27": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2025-02-28": {
        "content": "Sulit untuk mengalahkan orang yang tidak pernah menyerah –Babe Ruth"
    },
    "2025-03-01": {
        "content": "Jika tindakanmu menginspirasi orang lain untuk bermimpi lebih banyak, belajar lebih banyak, berbuat lebih banyak, dan menjadi lebih sukses, kamu adalah seorang pemimpin –John Quincy Adams"
    },
    "2025-03-02": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2025-03-03": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2025-03-04": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2025-03-05": {
        "content": "Jauhi orang-orang yang mencoba meremehkan ambisimu. Pikiran kecil akan selalu melakukan itu, tetapi pikiran besar akan memberi perasaan bahwa kamu juga bisa menjadi hebat –Mark Twain"
    },
    "2025-03-06": {
        "content": "Kesuksesan biasanya datang pada mereka yang terlalu sibuk untuk mencarinya. -Henry David Thoreau"
    },
    "2025-03-07": {
        "content": "Rasa bahagia dan tak bahagia bukan berasal dari apa yang kamu miliki, bukan pula berasal dari siapa diri kamu, atau apa yang kamu kerjakan. Bahagia dan tak bahagia berasal dari pikiran kamu. –Dale Carnegie"
    },
    "2025-03-08": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2025-03-09": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2025-03-10": {
        "content": "Saya tidak memikirkan semua penderitaan, tetapi keindahan yang masih tersisa. –Anne Frank"
    },
    "2025-03-11": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2025-03-12": {
        "content": "Jika kamu benar-benar menginginkan sesuatu, jangan menunggu untuk itu –ajari dirimu untuk tidak sabar –Gurbaksh Chahal"
    },
    "2025-03-13": {
        "content": "Bagaimana kau mengeja 'cinta'? tanya Piglet.Kau tak usah mengejanya….rasakan saja. Jawab Pooh. -A.A Milne"
    },
    "2025-03-14": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2025-03-15": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2025-03-16": {
        "content": "Kita adalah apa yang kita pikirkan. –Marcus Aurelius"
    },
    "2025-03-17": {
        "content": "Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.–Ibnu Sina"
    },
    "2025-03-18": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2025-03-19": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2025-03-20": {
        "content": "Gagal itu makanan sehari-hari. Itu biasa, yang penting bagimana kamu menyikapinya. Evaluasi. Bangkit. Gagal lagi? Bangkit lagi!” –Chairul Tanjung"
    },
    "2025-03-21": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2025-03-22": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2025-03-23": {
        "content": "Jangan biarkan rasa takut kalah lebih besar daripada kegembiraan saat menang –Robert Kiyosaki"
    },
    "2025-03-24": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2025-03-25": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2025-03-26": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2025-03-27": {
        "content": "Ketahuilah bahwa hidup ini adalah ujian yang sebenarnya. –Ibn Qayyim al-Jawziyya"
    },
    "2025-03-28": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2025-03-29": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2025-03-30": {
        "content": "Tindakan menyalahkan hanya akan membuang waktu. Sebesar apapun kesalahan yang kamu timpakan ke orang lain, dan sebesar apapun kamu menyalahkannya, hal tersebut tidak akan mengubahmu –Wayne Dyer"
    },
    "2025-03-31": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara. -Shah Rukh Khan"
    },
    "2025-04-01": {
        "content": "Pencarian ilmu adalah ibadah yang paling utama. –Abu Yusuf al-Qadi"
    },
    "2025-04-02": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2025-04-03": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2025-04-04": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2025-04-05": {
        "content": "Lebih baik mempunyai kehidupan yang bermakna dan memberikan perbedaan dibandingkan hanya mempunyai umur yang panjang –Bryant H. Mc.Gill."
    },
    "2025-04-06": {
        "content": "Gravitasi tidak bertanggung-jawab atas orang yang jatuh cinta –Albert Einstein"
    },
    "2025-04-07": {
        "content": "Jangan takut akan kesulitan, karena itu adalah kesempatan untuk tumbuh. –Albert Einstein"
    },
    "2025-04-08": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2025-04-09": {
        "content": "Satu-satunya tempat di mana kesuksesan datang sebelum bekerja adalah di kamus. -Vidal Sassoon"
    },
    "2025-04-10": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2025-04-11": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2025-04-12": {
        "content": "Berjuang sampai napas terakhir –William Shakespeare"
    },
    "2025-04-13": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2025-04-14": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2025-04-15": {
        "content": "Kesempurnaan adalah hasil dari usaha dan keteguhan dalam mengejar kebaikan. –Ibn Taymiyyah"
    },
    "2025-04-16": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2025-04-17": {
        "content": "Hidup tanpa tujuan adalah seperti menelusuri lautan tanpa kompas. –Thomas Carlyle"
    },
    "2025-04-18": {
        "content": "Cintai hidup yang kau jalani. Jalani hidup yang kau cintai. –Bob Marley"
    },
    "2025-04-19": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2025-04-20": {
        "content": "Suatu ide yang berguna lebih berharga daripada kekayaan. –Socrates"
    },
    "2025-04-21": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2025-04-22": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2025-04-23": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2025-04-24": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2025-04-25": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2025-04-26": {
        "content": "Kamu hanya gagal saat kamu berhenti mencoba –Albert Einstein"
    },
    "2025-04-27": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2025-04-28": {
        "content": "Kesempatan emas yang kau cari ada di dalam dirimu sendiri –Orison Sweet Marden."
    },
    "2025-04-29": {
        "content": "Jika kamu ingin menjalani hidup yang bahagia, ikatlah dengan tujuan, bukan pada orang atau benda. –Albert Einstein"
    },
    "2025-04-30": {
        "content": "Tujuan pendidikan itu untuk mempertajam kecerdasan, memperkukuh kemauan serta memperhalus perasaan. –Tan Malaka"
    },
    "2025-05-01": {
        "content": "Hanya mereka yang berani mengambil risiko melangkah terlalu jauh, yang mungkin bisa mengetahui seberapa jauh seseorang bisa melangkah. –TS Eliot"
    },
    "2025-05-02": {
        "content": "Bila Anda gagal hari ini, jangan pernah menyerah Ulangi terus kegagalan Anda sampai atasan/komandan Anda menyerah –Mario Teguh"
    },
    "2025-05-03": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2025-05-04": {
        "content": "Dia yang mengetahui semua jawaban belum pernah memperoleh pertanyaan –Confucius."
    },
    "2025-05-05": {
        "content": "Ingatlah selalu bahwa kamu benar-benar unik. Sama seperti orang lain. –Margaret Mead"
    },
    "2025-05-06": {
        "content": "Orang yang meninggal memperoleh lebih banyak bunga dibandingkan orang yang masih hidup karena penyesalan lebih kuat dibandingkan rasa syukur –Anne Frank."
    },
    "2025-05-07": {
        "content": "Berjuang untuk sukses tanpa kerja keras seperti mencoba memanen ketika kamu belum menanam –David Bly"
    },
    "2025-05-08": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2025-05-09": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2025-05-10": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2025-05-11": {
        "content": "Keindahan persahabatan adalah bahwa kamu tahu kepada siapa kamu dapat mempercayakan rahasia –Alessandro Manzoni"
    },
    "2025-05-12": {
        "content": "Untuk sukses, sikap sama pentingnya dengan kemampuan. -Walter Scott"
    },
    "2025-05-13": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2025-05-14": {
        "content": "Menjadi diri sendiri di dunia yang terus-menerus berusaha menjadikan Anda orang lain adalah pencapaian terbesar. -Ralph Waldo Emerson"
    },
    "2025-05-15": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2025-05-16": {
        "content": "Rahasia kesuksesan adalah melakukan hal yang biasa dengan sangat baik. -John D. Rockefeller Jr."
    },
    "2025-05-17": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2025-05-18": {
        "content": "Jangan khawatir jika Anda tidak diakui, tetapi berusahalah untuk menjadi layak diakui. -Abraham Lincoln"
    },
    "2025-05-19": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2025-05-20": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti. –Kahlil Gibran, Pujangga"
    },
    "2025-05-21": {
        "content": "Hidup adalah rangkaian pelajaran yang harus dijalani untuk dipahami. –Ralph Waldo Emerson"
    },
    "2025-05-22": {
        "content": "Dia, yang tidak cukup berani untuk mengambil risiko, tidak akan mencapai apa pun dalam hidup. –Muhammad Ali"
    },
    "2025-05-23": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2025-05-24": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2025-05-25": {
        "content": "Hidup itu sebentar. Kamu harus bisa tersenyum saat merasakan kepedihan atau kita tak akan pernah melanjutkan hidup.–Jeff Ross"
    },
    "2025-05-26": {
        "content": "Cinta adalah ketika kebahagiaan orang lain lebih penting dari kebahagiaanmu. -H. Jackson Brown"
    },
    "2025-05-27": {
        "content": "Seseorang tak akan pernah tahu betapa dalam kadar cintanya sampai terjadi sebuah perpisahan. -Kahlil Gibran"
    },
    "2025-05-28": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2025-05-29": {
        "content": "Hubungan asmara itu seperti kaca. Terkadang lebih baik meninggalkannya dalam keadaan pecah. Daripada menyakiti dirimu dengan cara menyatukan mereka kembali. -D.Love"
    },
    "2025-05-30": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2025-05-31": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2025-06-01": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2025-06-02": {
        "content": "Kegelapan tidak dapat mengusir kegelapan: hanya cahaya yang dapat melakukannya. Kebencian tidak dapat mengusir kebencian: hanya cinta yang dapat melakukannya. -Martin Luther King Jr."
    },
    "2025-06-03": {
        "content": "Kebahagiaan kita tergantung kepada diri kita sendiri –Aristoteles."
    },
    "2025-06-04": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2025-06-05": {
        "content": "Semua mimpi kita bisa jadi kenyataan, jika kita memiliki keberanian untuk mengejarnya –Walt Disney"
    },
    "2025-06-06": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. –Benjamin Franklin"
    },
    "2025-06-07": {
        "content": "Penemuan terbesar sepanjang masa adalah bahwa seseorang bisa mengubah masa depannya hanya dengan mengubah sikapnya saat ini. -Oprah Winfrey"
    },
    "2025-06-08": {
        "content": "Kau harus membiarkan orang lain untuk pergi. Setiap orang yang ada dalam kehidupanmu ditakdirkan untuk berada dalam perjalanan, tetapi tidak semuanya dimaksudkan untuk tinggal hingga akhir –Vasco da Gama."
    },
    "2025-06-09": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2025-06-10": {
        "content": "Jangan menunggu orang lain membahagiakanmu. Kebahagiaan apa pun yang Anda peroleh, harus Anda ciptakan sendiri. -Alice Walker"
    },
    "2025-06-11": {
        "content": "Semakin kau tahu tentang jati diri dan sesuatu yang kau inginkan, semakin sedikit pula kau membiarkan berbagai hal yang membuatmu kesal berlalu –Stephanie Perkins."
    },
    "2025-06-12": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2025-06-13": {
        "content": "Jangan habiskan waktu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. –Coco Canel"
    },
    "2025-06-14": {
        "content": "Ingatlah bahwa kita hanya bisa hidup saat ini, dalam waktu yang sekarang. –Jean-Paul Sartre"
    },
    "2025-06-15": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2025-06-16": {
        "content": "Karena cinta, duri menjadi mawar. Karena cinta, cuka menjelma anggur segar. –Jalaluddin Rumi"
    },
    "2025-06-17": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2025-06-18": {
        "content": "Janganlah menganggap remeh hal-hal yang terdekat dengan hatimu. Rangkullah mereka seperti sama berharganya dengan hidupmu, karena tanpa mereka hidup adalah sia-sia –Peribahasa Cina"
    },
    "2025-06-19": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2025-06-20": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2025-06-21": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2025-06-22": {
        "content": "Jangan merusak sesuatu yang kau miliki dengan mengharapkan sesuatu yang tidak akan pernah kau miliki; ingatlah jika sesuatu yang kau miliki saat ini pernah menjadi salah satu hal yang kau harapkan –Epicurus."
    },
    "2025-06-23": {
        "content": "Memaafkan belum tentu membuat kita lebih baik atau bahkan merasa lebih baik tetapi yang jelas membuka jalan kebaikan.–Eleanor Roosevelt"
    },
    "2025-06-24": {
        "content": "Anda akan menghadapi banyak kekalahan dalam hidup, tetapi jangan pernah membiarkan diri Anda dikalahkan. -Maya Angelou"
    },
    "2025-06-25": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2025-06-26": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2025-06-27": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2025-06-28": {
        "content": "Kebahagiaan dirasakan oleh orang-orang yang dapat merasa puas kepada dirinya sendiri –Agatha Christie."
    },
    "2025-06-29": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2025-06-30": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2025-07-01": {
        "content": "Kamu menjadi apa yang kamu yakini. -Oprah Winfrey"
    },
    "2025-07-02": {
        "content": "Kesuksesan bersumber dari perbuatan. Orang yang sukses terus melakukan usaha. Orang sukses bahkan membuat kesalahan, namun mereka tidak berhenti usaha –Cobrad Hilton"
    },
    "2025-07-03": {
        "content": "Kesabaran adalah kekuatan; kebitteran adalah kelemahan. –Immanuel Kant"
    },
    "2025-07-04": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2025-07-05": {
        "content": "Anda ingin mengetahui siapa diri Anda? Jangan bertanya. Beraksilah! -Thomas Jefferson "
    },
    "2025-07-06": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2025-07-07": {
        "content": "Jika kamu melihat apa yang kamu miliki dalam hidup, kamu akan selalu memiliki lebih banyak. Jika kamu melihat apa yang tidak kamu miliki dalam hidup, kamu tidak akan pernah merasa cukup. –Oprah Winfrey"
    },
    "2025-07-08": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2025-07-09": {
        "content": "Cobalah untuk tidak menjadi orang yang sukses. Sebaliknya, jadilah orang yang bernilai. -Albert Einstein"
    },
    "2025-07-10": {
        "content": "Banyak orang gagal dalam kehidupan, bukan karena kurangnya kemampuan, pengetahuan, atau keberanian, namun hanya karena mereka tidak pernah mengatur energinya pada sasaran –Elbert Hubbard"
    },
    "2025-07-11": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2025-07-12": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2025-07-13": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2025-07-14": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2025-07-15": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2025-07-16": {
        "content": "Arahkan mata Anda pada bintang-bintang dengan kaki tetap berpijak pada tanah. -Theodore Roosevelt"
    },
    "2025-07-17": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2025-07-18": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2025-07-19": {
        "content": "Pada saat-saat tergelaplah kita harus fokus untuk melihat cahaya. -Aristoteles"
    },
    "2025-07-20": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2025-07-21": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2025-07-22": {
        "content": "Cara terbaik untuk menyiapkan kehidupan adalah memulai hidup –Elbert Hubbard."
    },
    "2025-07-23": {
        "content": "Kebijaksanaan bukanlah produk dari sekolah tetapi dari usaha seumur hidup untuk mendapatkannya. –Albert Einstein"
    },
    "2025-07-24": {
        "content": "Kau tidak pernah dilahirkan untuk hidup kalah, tertekan, bersalah, malu, terkutuk, dan tidak layak. Kau dilahirkan untuk menjadi seorang pemenang –Mikhail Sergeyevich Gorbachev."
    },
    "2025-07-25": {
        "content": "Satu-satunya cara untuk mencapai yang tidak mungkin adalah percaya bahwa itu mungkin –Charles Kingsleigh"
    },
    "2025-07-26": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2025-07-27": {
        "content": "Dunia ini adalah sebuah cermin bagi pikiran kita sendiri. –Baruch Spinoza"
    },
    "2025-07-28": {
        "content": " Orang yang memiliki sasaran sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2025-07-29": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. -Bunda Teresa"
    },
    "2025-07-30": {
        "content": "Keyakinan menciptakan fakta yang sebenarnya –William James"
    },
    "2025-07-31": {
        "content": "Cinta terjadi begitu singkat, namun melupakan memakan waktu begitu lama. -Pablo Neruda"
    },
    "2025-08-01": {
        "content": "Orang paling kesepian memiliki kepribadian yang baik hati. Orang paling sedih memiliki senyum paling cerah. Orang paling rusak memiliki kebijaksanaan yang luas. Semua itu karena mereka tidak ingin orang lain menderita seperti mereka –Zarathustra."
    },
    "2025-08-02": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2025-08-03": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2025-08-04": {
        "content": "Diam adalah sumber dari kekuatan yang luar biasa. -Lao Tzu"
    },
    "2025-08-05": {
        "content": "Tidak masalah seberapa lambat kau berjalan asalkan kau tidak berhenti. -Confucius"
    },
    "2025-08-06": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2025-08-07": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2025-08-08": {
        "content": "Cinta itu bukan hanya perasaan senang ketika bersamanya Cinta juga adalah komitmen untuk tetap bersamanya, ketika perasaan senang itu hilang –Merry Riana"
    },
    "2025-08-09": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2025-08-10": {
        "content": "Kebahagiaan berada di dalam. Hal itu tidak ada hubungannya dengan seberapa banyak tepuk tangan yang kau peroleh atau seberapa banyak orang yang menyanjungmu. Kebahagiaan datang saat kau percaya telah melaksanakan sesuatu yang benar-benar berharga –Martin Yan."
    },
    "2025-08-11": {
        "content": "Tiada awan di langit yang tetap selamanya. Tiada mungkin akan terus-menerus terang cuaca. Sehabis malam gelap gulita lahir pagi membawa keindahan. Kehidupan manusia serupa alam. –RA Kartini"
    },
    "2025-08-12": {
        "content": "Ubah lukamu menjadi kebijaksanaan. –Oprah Winfrey"
    },
    "2025-08-13": {
        "content": "Kekuatan dan perkembangan datang hanya dari usaha dan perjuangan yang terus menerus. –Napoleon Hill"
    },
    "2025-08-14": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2025-08-15": {
        "content": "Tidak apa-apa merayakan kesuksesan, tapi lebih penting untuk memperhatikan pelajaran tentang kegagalan.  –Bill Gates"
    },
    "2025-08-16": {
        "content": "Semuanya kelihatan tidak mungkin sampai segala sesuatu selesai. -Nelson Mandela"
    },
    "2025-08-17": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2025-08-18": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2025-08-19": {
        "content": "Harta yang paling berharga adalah hati yang bertakwa. –Umar bin Khattab"
    },
    "2025-08-20": {
        "content": "Jangan khawatir tentang kegagalan, khawatirkan tentang peluang yang kamu lewatkan ketika kamu bahkan tidak mencoba. –Jack Canfield"
    },
    "2025-08-21": {
        "content": "Sesuatu akan terlihat berkesan jika hal tersebut dapat bermakna dan penuh warna –Joshua Foer."
    },
    "2025-08-22": {
        "content": "Belajar tidak pernah melelahkan pikiran –Leonardo Da Vinci"
    },
    "2025-08-23": {
        "content": "Pikiran yang baik dan hati yang baik selalu merupakan kombinasi yang hebat –Nelson Mandela"
    },
    "2025-08-24": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2025-08-25": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2025-08-26": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2025-08-27": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2025-08-28": {
        "content": "Sebarkan cinta ke mana pun kamu pergi. Jangan biarkan orang lain mendatangimu tanpa mendapatkan hal yang lebih bahagia. –Bunda Teresa"
    },
    "2025-08-29": {
        "content": "Sebab sahabat itu saling tumbuh, bukan yang hilang ketika satunya jatuh –Boy Candra"
    },
    "2025-08-30": {
        "content": "Kamu dapat menipu beberapa orang setiap saat dan semua orang pada suatu waktu, tetapi kamu tidak dapat membodohi semua orang setiap saat. -Abraham Lincol"
    },
    "2025-08-31": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2025-09-01": {
        "content": "Tuntutlah ilmu, tapi tidak melupakan ibadah. Kerjakanlah ibadah tapi tidak boleh lupa pada ilmu. –Hassan Al Bashri"
    },
    "2025-09-02": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2025-09-03": {
        "content": "Cara paling mendasar dan kuat untuk terhubung dengan orang lain adalah dengan mendengarkan. Cukup Dengarkan. Mungkin hal terpenting yang bisa kita berikan kepada orang lain adalah perhatian kita –Rachel Naomi Remen"
    },
    "2025-09-04": {
        "content": "Cinta tidak terlihat dengan mata, tetapi dengan hati.–William Shakespeare"
    },
    "2025-09-05": {
        "content": "Sebelum apa pun, persiapan adalah kunci menuju kesuksesan. -Alexander Graham Bell"
    },
    "2025-09-06": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2025-09-07": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2025-09-08": {
        "content": "Cinta tak berupa tatapan satu sama lain, tetapi memandang keluar bersama ke arah yang sama. –B.J. Habibie"
    },
    "2025-09-09": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2025-09-10": {
        "content": "Pengetahuan tidak memiliki nilai kecuali jika dipraktekkan. –Thomas Aquinas"
    },
    "2025-09-11": {
        "content": "Tidak ada kata terlambat untuk menjadi dirimu yang seharusnya. –George Eliot"
    },
    "2025-09-12": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2025-09-13": {
        "content": "Jadilah diri Anda sendiri; semua orang sudah menjadi orang lain. -Oscar Wilde"
    },
    "2025-09-14": {
        "content": "Ketika kamu mengubah pikiran, ingatlah untuk juga mengubah duniamu juga —Norman Vincent Peale"
    },
    "2025-09-15": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2025-09-16": {
        "content": "Kedamaian hilang karena kita lupa jika sebenarnya memiliki satu sama lain –Bunda Maria Teresa Bojaxhiu."
    },
    "2025-09-17": {
        "content": "Cinta itu seperti halnya angin. Kita tidak dapat melihatnya, tetapi dapat merasakannya –Nicholas Sparks."
    },
    "2025-09-18": {
        "content": "Saya telah gagal berkali-kali dalam hidup. Dan itulah mengapa saya berhasil. –Michael Jordan"
    },
    "2025-09-19": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2025-09-20": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2025-09-21": {
        "content": "Kita memahami dunia tidak sebagaimana ia adalah, tetapi sebagaimana kita. –Immanuel Kant"
    },
    "2025-09-22": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2025-09-23": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2025-09-24": {
        "content": "Jangan tinggalkan apa pun untuk hari esok yang bisa dilakukan hari ini. -Abraham Lincoln"
    },
    "2025-09-25": {
        "content": "Jika kamu berpikir kamu terlalu kecil untuk membuat sebuah perubahan, cobalah tidur di ruangan dengan seekor nyamuk. -Dalai Lama"
    },
    "2025-09-26": {
        "content": "Kesuksesan bukanlah hal yang final; kegagalan bukanlah hal yang fatal: Yang terpenting adalah keberanian untuk terus maju. -Winston S. Churchill"
    },
    "2025-09-27": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2025-09-28": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2025-09-29": {
        "content": "Hidup yang baik adalah yang terinspirasi dari cinta dan dipandu oleh ilmu pengetahuan. –Bertrand Russell."
    },
    "2025-09-30": {
        "content": "Beri nilai dari usahanya jangan dari hasilnya. Baru kita bisa menilai kehidupan. -Albert Einstein"
    },
    "2025-10-01": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2025-10-02": {
        "content": "Mimpi adalah jendela ke dalam alam bawah sadar. –Sigmund Freud"
    },
    "2025-10-03": {
        "content": "Kita tidak hidup dari apa yang kita miliki, tetapi dari apa yang kita berikan. –Pablo Picasso"
    },
    "2025-10-04": {
        "content": "Cinta selalu saja misterius Jangan diburu-buru, atau kau akan merusak jalan ceritanya sendiri –Tere Liye"
    },
    "2025-10-05": {
        "content": "Hidup merupakan suatu perjalanan yang dapat dijadikan pengalaman, bukan sekadar masalah yang harus diselesaikan –C.S. Lewis."
    },
    "2025-10-06": {
        "content": "Kabar buruknya adalah waktu berlalu begitu saja. Kabar baiknya adalah kamu adalah pilotnya. —Michael Altshuler"
    },
    "2025-10-07": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2025-10-08": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2025-10-09": {
        "content": "Kebanggaan kita yang terbesar adalah bukan tidak pernah gagal, tetapi bangkit kembali setiap kali kita jatuh. –Confusius"
    },
    "2025-10-10": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2025-10-11": {
        "content": "Kamu tidak bisa kembali dan mengubah awal saat kamu memulainya, tapi kamu bisa memulainya lagi dari di mana kamu berada sekarang dan ubah akhirnya. -C.S Lewis"
    },
    "2025-10-12": {
        "content": "Aku menginginkanmu seutuhnya, selamanya, kamu dan aku, setiap hari. –The Notebook, Nicholas Sparks"
    },
    "2025-10-13": {
        "content": "Lakukanlah sesuatu hari ini yang akan membuatmu berterima kasih kepada orang lain di masa depan nanti –Sean Patrick."
    },
    "2025-10-14": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2025-10-15": {
        "content": "Seseorang yang benar-benar hebat merupakan orang yang membuat tiap orang merasa hebat –G.K. Chesterton."
    },
    "2025-10-16": {
        "content": "Dunia itu seluas langkah kaki. Jelajahilah dan jangan pernah takut melangkah. Hanya dengan itu kita bisa mengerti kehidupan dan menyatu dengannya.–Soe Hok Gie"
    },
    "2025-10-17": {
        "content": "Tidak ada akhir untuk pendidikan. Bukan berarti Anda membaca buku, lulus ujian, dan menyelesaikan pendidikan. Seluruh kehidupan, dari saat Anda lahir hingga saat Anda mati, adalah proses pembelajaran. –Jiddu Krishnamurti"
    },
    "2025-10-18": {
        "content": "Aku tidak takut badai karena telah belajar berlayar dengan kapalku –Louisa May Alcott."
    },
    "2025-10-19": {
        "content": "Jika Anda tidak mampu berpikir secara jernih, tidak Anda mempunyai hak untuk bertindak. –Martin Heidegger"
    },
    "2025-10-20": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2025-10-21": {
        "content": "Kejujuran adalah batu penjuru dari segala kesuksesan. Pengakuan adalah motivasi terkuat. Bahkan kritik dapat membangun rasa percaya diri saat 'disisipkan' di antara pujian. –May Kay Ash"
    },
    "2025-10-22": {
        "content": "Bukan gunung di depan untuk didaki yang membuatmu lelah, melainkan kerikil di sepatumu. –Muhammad Ali"
    },
    "2025-10-23": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2025-10-24": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2025-10-25": {
        "content": "Banyak kegagalan dalam hidup adalah orang-orang yang tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. -Thomas A. Edison"
    },
    "2025-10-26": {
        "content": "Orang bijak belajar ketika mereka bisa. Orang bodoh belajar ketika mereka dipaksa. -Arthur Wellesley"
    },
    "2025-10-27": {
        "content": "Hadiah terbesar dalam hidup ini adalah persahabatan, dan aku telah mendapatkannya –Hubert H Humphrey"
    },
    "2025-10-28": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2025-10-29": {
        "content": "Anda hanya hidup sekali, tetapi jika Anda melakukannya dengan benar, sekali saja sudah cukup. -Mae West"
    },
    "2025-10-30": {
        "content": "Bangun dan wujudkan mimpimu atau orang lain akan memperkerjakanmu untuk membangun mimpi mereka –Farrah Gray"
    },
    "2025-10-31": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2025-11-01": {
        "content": "Semakin tinggi ilmu seseorang, maka semakin tinggi toleransinya. –Gus Dur"
    },
    "2025-11-02": {
        "content": "Aku memilih untuk membuat sisa hidupku menjadi yang terbaik dalam hidupku. -Louise Hay"
    },
    "2025-11-03": {
        "content": "Ini adalah agamaku yang sederhana. Tak perlu ada kuil; tak perlu filosofi yang rumit. Otak kita sendiri, hati kita sendiri adalah kuil kita; filosofinya adalah kebaikan –Dalai Lama."
    },
    "2025-11-04": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2025-11-05": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2025-11-06": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2025-11-07": {
        "content": "Pikirkanlah sebelum berbicara, karena perkataan memiliki kekuatan yang besar. –Ali bin Abi Thalib"
    },
    "2025-11-08": {
        "content": "Belajarlah seolah-olah kamu akan hidup selamanya, hiduplah seolah-olah kamu akan mati besok. –Mahatma Gandhi"
    },
    "2025-11-09": {
        "content": "​Sukses adalah jumlah dari usaha-usaha kecil, berulang-ulang hari demi hari –Robert Collier"
    },
    "2025-11-10": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2025-11-11": {
        "content": "Anda tidak dapat menjelekkan tanah di mana Anda berdiri. –Arthur Schopenhauer"
    },
    "2025-11-12": {
        "content": "Kebijaksanaan sejati adalah mengenal diri sendiri. –Ali bin Abi Thalib"
    },
    "2025-11-13": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2025-11-14": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2025-11-15": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2025-11-16": {
        "content": "Hidup adalah perjalanan spiritual menuju kebenaran mutlak. –Rumi"
    },
    "2025-11-17": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2025-11-18": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2025-11-19": {
        "content": "Hadapkan wajahmu selalu ke arah matahari, sehingga bayangan akan jatuh di belakangmu. -Walt Whitman"
    },
    "2025-11-20": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2025-11-21": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2025-11-22": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2025-11-23": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2025-11-24": {
        "content": "Orang yang memiliki sasaran akan menjadi sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2025-11-25": {
        "content": "Cinta itu layaknya angin, aku tidak bisa melihatnya tetapi aku bisa merasakannya. –Nicholas Sparck"
    },
    "2025-11-26": {
        "content": "Jangan katakan tidak punya cukup waktu. Anda memiliki jumlah jam yang persis sama per hari yang diberikan kepada Helen Keller, Pasteur, Michelangelo, Mother Teresa, Leonardo da Vinci, Thomas Jefferson, dan Albert Einstein. –H. Jackson Brown Jr"
    },
    "2025-11-27": {
        "content": "Tujuan hidup kita adalah untuk menjadi bahagia. -Dalai Lama"
    },
    "2025-11-28": {
        "content": "Kemuliaan terbesar dalam hidup bukan terletak pada tidak pernah jatuh, tetapi bangkit setiap kali kita jatuh. -Nelson Mandela"
    },
    "2025-11-29": {
        "content": "Jangan pernah bermimpi untuk sukses, tapi kerjakan sesuatu untuk meraih kesuksesan –Ester Lauder"
    },
    "2025-11-30": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2025-12-01": {
        "content": "Cinta adalah kebenaran terbesar dan kebaikan tertinggi. –Plato"
    },
    "2025-12-02": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. -Zig Ziglar"
    },
    "2025-12-03": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan. -Shakuntala Devi"
    },
    "2025-12-04": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2025-12-05": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2025-12-06": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2025-12-07": {
        "content": "Cinta yang tak dewasa: 'Aku mencintaimu karena aku membutuhkanmu' Cinta yang dewasa: 'Aku membutuhkanmu karena aku mencintaimu' –Erich Fromm"
    },
    "2025-12-08": {
        "content": "Suatu pekerjaan yang paling tak kunjung bisa diselesaikan adalah pekerjaan yang tak kunjung pernah dimulai. –JRR Tolkien"
    },
    "2025-12-09": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2025-12-10": {
        "content": "Hidup adalah yang terjadi saat kamu sibuk membuat rencana lain. –John Lennon"
    },
    "2025-12-11": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2025-12-12": {
        "content": "Jalan menuju sukses dan jalan menuju kegagalan hampir persis sama –Colin R. Davis"
    },
    "2025-12-13": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2025-12-14": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2025-12-15": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2025-12-16": {
        "content": "Ketika Anda jatuh cinta, kebahagiaan akan membuat Anda sulit tertidur karena kenyataan lebih baik dibandingkan mimpi Anda –Dr Seuss"
    },
    "2025-12-17": {
        "content": "Kehidupan adalah pengorbanan untuk mencapai kedamaian batin. –Ibn Hazm"
    },
    "2025-12-18": {
        "content": "Kamu bisa menjadi segalanya. Kamu bisa menjadi orang yang tidak terbatas jumlahnya. ” –Kesha"
    },
    "2025-12-19": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2025-12-20": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2025-12-21": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2025-12-22": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2025-12-23": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2025-12-24": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2025-12-25": {
        "content": "Sejauh apapun perpisahan, sesibuk apapun kita nanti, dan meski langkah kita berbeda, waktu tidak akan dapat memisahkan gelar 'sahabat' di antara kita –mnurulnatasha"
    },
    "2025-12-26": {
        "content": "Jangan habiskan waktumu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. -Coco Canel"
    },
    "2025-12-27": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2025-12-28": {
        "content": "Percayalah, jika dia memang cinta sejati kau, mau semenyakitkan apa pun, mau seberapa sulit liku yang harus dilalui, dia tetap akan bersama kau kelak, suatu saat nanti. –Tere Liye"
    },
    "2025-12-29": {
        "content": "Ketika kesulitan datang, ingatlah bahwa Allah selalu bersamamu. –Jalal ad-Din Rumi"
    },
    "2025-12-30": {
        "content": "Kebenaran adalah cahaya yang menghilangkan kegelapan. –al-Ghazali"
    },
    "2025-12-31": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2026-01-01": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2026-01-02": {
        "content": "Cinta itu seperti halnya angin. Kita tidak dapat melihatnya, tetapi dapat merasakannya –Nicholas Sparks."
    },
    "2026-01-03": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2026-01-04": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2026-01-05": {
        "content": "Aku memilih untuk membuat sisa hidupku menjadi yang terbaik dalam hidupku. -Louise Hay"
    },
    "2026-01-06": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2026-01-07": {
        "content": "Kau harus membiarkan orang lain untuk pergi. Setiap orang yang ada dalam kehidupanmu ditakdirkan untuk berada dalam perjalanan, tetapi tidak semuanya dimaksudkan untuk tinggal hingga akhir –Vasco da Gama."
    },
    "2026-01-08": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2026-01-09": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2026-01-10": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2026-01-11": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2026-01-12": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2026-01-13": {
        "content": "Kita adalah apa yang kita pikirkan. –Marcus Aurelius"
    },
    "2026-01-14": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2026-01-15": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2026-01-16": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2026-01-17": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2026-01-18": {
        "content": "Hidup yang baik adalah yang terinspirasi dari cinta dan dipandu oleh ilmu pengetahuan. –Bertrand Russell."
    },
    "2026-01-19": {
        "content": "Percayalah, jika dia memang cinta sejati kau, mau semenyakitkan apa pun, mau seberapa sulit liku yang harus dilalui, dia tetap akan bersama kau kelak, suatu saat nanti. –Tere Liye"
    },
    "2026-01-20": {
        "content": "Kejujuran adalah batu penjuru dari segala kesuksesan. Pengakuan adalah motivasi terkuat. Bahkan kritik dapat membangun rasa percaya diri saat 'disisipkan' di antara pujian. –May Kay Ash"
    },
    "2026-01-21": {
        "content": "Pada saat-saat tergelaplah kita harus fokus untuk melihat cahaya. -Aristoteles"
    },
    "2026-01-22": {
        "content": "Kamu tidak selalu membutuhkan rencana. Terkadang kamu hanya perlu bernapas, percaya, melepaskan dan melihat apa yang terjadi –Mandy Hale"
    },
    "2026-01-23": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2026-01-24": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2026-01-25": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2026-01-26": {
        "content": "Jika Anda tidak mampu berpikir secara jernih, tidak Anda mempunyai hak untuk bertindak. –Martin Heidegger"
    },
    "2026-01-27": {
        "content": "Anda tidak dapat menjelekkan tanah di mana Anda berdiri. –Arthur Schopenhauer"
    },
    "2026-01-28": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2026-01-29": {
        "content": "Siapapun yang bahagia akan membuat orang lain juga bahagia. –Anne Frank"
    },
    "2026-01-30": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2026-01-31": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2026-02-01": {
        "content": "Aku menginginkanmu seutuhnya, selamanya, kamu dan aku, setiap hari. –The Notebook, Nicholas Sparks"
    },
    "2026-02-02": {
        "content": "Ingatlah bahwa kita hanya bisa hidup saat ini, dalam waktu yang sekarang. –Jean-Paul Sartre"
    },
    "2026-02-03": {
        "content": "Pencarian ilmu adalah ibadah yang paling utama. –Abu Yusuf al-Qadi"
    },
    "2026-02-04": {
        "content": "Sukses bukanlah akhir, kegagalan bukanlah fatal, yang terpenting adalah keberanian untuk melanjutkan –Winston S. Churchill"
    },
    "2026-02-05": {
        "content": "Cinta yang tak dewasa: 'Aku mencintaimu karena aku membutuhkanmu' Cinta yang dewasa: 'Aku membutuhkanmu karena aku mencintaimu' –Erich Fromm"
    },
    "2026-02-06": {
        "content": "Bila Anda gagal hari ini, jangan pernah menyerah Ulangi terus kegagalan Anda sampai atasan/komandan Anda menyerah –Mario Teguh"
    },
    "2026-02-07": {
        "content": "Jika itu penting bagi Anda, Anda akan menemukan jalan. Jika tidak, Anda akan menemukan alasan. –Ryan Blair"
    },
    "2026-02-08": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. –Zig Ziglar"
    },
    "2026-02-09": {
        "content": "Kamu hanya gagal saat kamu berhenti mencoba –Albert Einstein"
    },
    "2026-02-10": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2026-02-11": {
        "content": "Semua mimpi kita bisa jadi kenyataan, jika kita memiliki keberanian untuk mengejarnya –Walt Disney"
    },
    "2026-02-12": {
        "content": "Banyak orang gagal dalam kehidupan, bukan karena kurangnya kemampuan, pengetahuan, atau keberanian, namun hanya karena mereka tidak pernah mengatur energinya pada sasaran –Elbert Hubbard"
    },
    "2026-02-13": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2026-02-14": {
        "content": "Hiduplah seolah-olah Anda akan mati besok. Belajarlah seolah Anda hidup selamanya. –Mahatma Gandhi"
    },
    "2026-02-15": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2026-02-16": {
        "content": "Bukan gunung di depan untuk didaki yang membuatmu lelah, melainkan kerikil di sepatumu. –Muhammad Ali"
    },
    "2026-02-17": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2026-02-18": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2026-02-19": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2026-02-20": {
        "content": "Keraguan akan membunuh lebih banyak mimpi dibandingkan kegagalan –Francis Bacon."
    },
    "2026-02-21": {
        "content": "Ketika Anda jatuh cinta, kebahagiaan akan membuat Anda sulit tertidur karena kenyataan lebih baik dibandingkan mimpi Anda –Dr Seuss"
    },
    "2026-02-22": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2026-02-23": {
        "content": "Tujuan hidup kita adalah untuk menjadi bahagia. -Dalai Lama"
    },
    "2026-02-24": {
        "content": "Kita tidak hidup dari apa yang kita miliki, tetapi dari apa yang kita berikan. –Pablo Picasso"
    },
    "2026-02-25": {
        "content": "Hadapkan wajahmu selalu ke arah matahari, sehingga bayangan akan jatuh di belakangmu. -Walt Whitman"
    },
    "2026-02-26": {
        "content": "Banyak kegagalan dalam hidup adalah orang-orang yang tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. -Thomas A. Edison"
    },
    "2026-02-27": {
        "content": "Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.–Ibnu Sina"
    },
    "2026-02-28": {
        "content": "Kita harus belajar untuk hidup bersama sebagai saudara atau kita akan binasa bersama-sama sebagai orang bodoh. –Martin Luther King"
    },
    "2026-03-01": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2026-03-02": {
        "content": "Cinta tidak terlihat dengan mata, tetapi dengan hati.–William Shakespeare"
    },
    "2026-03-03": {
        "content": "Anda memiliki otak di kepala Anda. Anda memiliki kaki di sepatu Anda. Anda dapat mengarahkan diri Anda ke arah mana pun yang Anda pilih. -Dr. Seuss"
    },
    "2026-03-04": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2026-03-05": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2026-03-06": {
        "content": "Keselamatan adalah hasil akhir dari kesabaran. –Ibn Taymiyyah"
    },
    "2026-03-07": {
        "content": "Satu-satunya sumber dari pengertahuan adalah pengalaman. -Albert Einstein"
    },
    "2026-03-08": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2026-03-09": {
        "content": "Jika tindakanmu menginspirasi orang lain untuk bermimpi lebih banyak, belajar lebih banyak, berbuat lebih banyak, dan menjadi lebih sukses, kamu adalah seorang pemimpin –John Quincy Adams"
    },
    "2026-03-10": {
        "content": "Kamu tidak perlu menjadi luar biasa untuk memulai, tapi kamu harus memulai untuk menjadi luar biasa. -Zig Ziglar"
    },
    "2026-03-11": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2026-03-12": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti.–Kahlil Gibran, Pujangga"
    },
    "2026-03-13": {
        "content": "Saya telah gagal berkali-kali dalam hidup. Dan itulah mengapa saya berhasil. –Michael Jordan"
    },
    "2026-03-14": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2026-03-15": {
        "content": "Kebijaksanaan bukanlah produk dari sekolah tetapi dari usaha seumur hidup untuk mendapatkannya. –Albert Einstein"
    },
    "2026-03-16": {
        "content": "Keindahan persahabatan adalah bahwa kamu tahu kepada siapa kamu dapat mempercayakan rahasia –Alessandro Manzoni"
    },
    "2026-03-17": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2026-03-18": {
        "content": "Aku tidak takut badai karena telah belajar berlayar dengan kapalku –Louisa May Alcott."
    },
    "2026-03-19": {
        "content": "Ketika kamu mengubah pikiran, ingatlah untuk juga mengubah duniamu juga —Norman Vincent Peale"
    },
    "2026-03-20": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2026-03-21": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2026-03-22": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2026-03-23": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2026-03-24": {
        "content": "Sulit untuk mengalahkan orang yang tidak pernah menyerah –Babe Ruth"
    },
    "2026-03-25": {
        "content": "Sakit dalam perjuangan itu hanya sementara. Bisa jadi kamu rasakan dalam semenit, sejam, sehari, atau setahun. Namun jika menyerah, rasa sakit itu akan terasa selamanya. –Lance Armstrong"
    },
    "2026-03-26": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2026-03-27": {
        "content": "Hadiah terbesar dalam hidup ini adalah persahabatan, dan aku telah mendapatkannya –Hubert H Humphrey"
    },
    "2026-03-28": {
        "content": "Perjalanan seribu mil dimulai dengan satu langkah. –Lao Tzu"
    },
    "2026-03-29": {
        "content": "Jika kamu berpikir kamu terlalu kecil untuk membuat sebuah perubahan, cobalah tidur di ruangan dengan seekor nyamuk. -Dalai Lama"
    },
    "2026-03-30": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semuanya harus dirasakan dengan hati. –Helen Keller"
    },
    "2026-03-31": {
        "content": "Ubah lukamu menjadi kebijaksanaan. –Oprah Winfrey"
    },
    "2026-04-01": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2026-04-02": {
        "content": "Kekuatan dan perkembangan datang hanya dari usaha dan perjuangan yang terus menerus. –Napoleon Hill"
    },
    "2026-04-03": {
        "content": "Hitunglah umurmu dengan teman, bukan tahun. Hitunglah hidupmu dengan senyum, bukan air mata. -John Lennon"
    },
    "2026-04-04": {
        "content": "Hidup merupakan suatu perjalanan yang dapat dijadikan pengalaman, bukan sekadar masalah yang harus diselesaikan –C.S. Lewis."
    },
    "2026-04-05": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2026-04-06": {
        "content": "​Sukses adalah jumlah dari usaha-usaha kecil, berulang-ulang hari demi hari –Robert Collier"
    },
    "2026-04-07": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2026-04-08": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2026-04-09": {
        "content": "Jangan takut akan kesulitan, karena itu adalah kesempatan untuk tumbuh. –Albert Einstein"
    },
    "2026-04-10": {
        "content": "Hal yang paling menyakitkan adalah kehilangan jati dirimu saat engkau terlalu mencintai seseorang. Serta lupa bahwa sebenarnya engkau juga spesial. -Ernest Hemingway"
    },
    "2026-04-11": {
        "content": "Ambillah risiko yang lebih besar dari apa yang dipikirkan orang lain aman. Berilah perhatian lebih dari apa yang orang lain pikir bijak. Bermimpilah lebih dari apa yang orang lain pikir masuk akal –Claude T. Bissell"
    },
    "2026-04-12": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2026-04-13": {
        "content": "Mimpi adalah jendela ke dalam alam bawah sadar. –Sigmund Freud"
    },
    "2026-04-14": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2026-04-15": {
        "content": "Hidup adalah yang terjadi saat kamu sibuk membuat rencana lain. –John Lennon"
    },
    "2026-04-16": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2026-04-17": {
        "content": "Kebahagiaan dirasakan oleh orang-orang yang dapat merasa puas kepada dirinya sendiri –Agatha Christie."
    },
    "2026-04-18": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan. -Shakuntala Devi"
    },
    "2026-04-19": {
        "content": "Jadilah seperti bebek. Tenang di permukaan tapi selalu mengayuh seperti ayam di bawahnya. -Michael Caine"
    },
    "2026-04-20": {
        "content": "Lakukanlah sesuatu hari ini yang akan membuatmu berterima kasih kepada orang lain di masa depan nanti –Sean Patrick."
    },
    "2026-04-21": {
        "content": "Ini adalah agamaku yang sederhana. Tak perlu ada kuil; tak perlu filosofi yang rumit. Otak kita sendiri, hati kita sendiri adalah kuil kita; filosofinya adalah kebaikan –Dalai Lama."
    },
    "2026-04-22": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2026-04-23": {
        "content": "Hidup itu sendiri adalah dongeng yang paling indah. -Hans Christian Andersen"
    },
    "2026-04-24": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. -Maya Angelou"
    },
    "2026-04-25": {
        "content": "Kebahagiaan berada di dalam. Hal itu tidak ada hubungannya dengan seberapa banyak tepuk tangan yang kau peroleh atau seberapa banyak orang yang menyanjungmu. Kebahagiaan datang saat kau percaya telah melaksanakan sesuatu yang benar-benar berharga –Martin Yan."
    },
    "2026-04-26": {
        "content": "Janganlah pernah menyerah ketika kamu masih mampu berusaha lagi. Tidak ada kata berakhir sampai kamu berhenti mencoba –Brian Dyson"
    },
    "2026-04-27": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2026-04-28": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2026-04-29": {
        "content": "Seseorang yang benar-benar hebat merupakan orang yang membuat tiap orang merasa hebat –G.K. Chesterton."
    },
    "2026-04-30": {
        "content": "Sesuatu akan terlihat berkesan jika hal tersebut dapat bermakna dan penuh warna –Joshua Foer."
    },
    "2026-05-01": {
        "content": "Hidup tanpa tujuan adalah seperti menelusuri lautan tanpa kompas. –Thomas Carlyle"
    },
    "2026-05-02": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2026-05-03": {
        "content": "Hanya mereka yang berani mengambil risiko melangkah terlalu jauh, yang mungkin bisa mengetahui seberapa jauh seseorang bisa melangkah. –TS Eliot"
    },
    "2026-05-04": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2026-05-05": {
        "content": "Belajar tidak pernah melelahkan pikiran –Leonardo Da Vinci"
    },
    "2026-05-06": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2026-05-07": {
        "content": "Cinta adalah ketika kebahagiaan orang lain lebih penting dari kebahagiaanmu. -H. Jackson Brown"
    },
    "2026-05-08": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. –Bunda Teresa"
    },
    "2026-05-09": {
        "content": "Pekerjaan-pekerjaan kecil yang selesai dilakukan, lebih baik daripada rencana-rencana besar yang hanya didiskusikan. -Peter Marshall"
    },
    "2026-05-10": {
        "content": "Keyakinan merupakan suatu pengetahuan di dalam hati, jauh tak terjangkau oleh bukti. –Kahlil Gibran, Pujangga"
    },
    "2026-05-11": {
        "content": "Kebahagiaan dari hidupmu tergantung dari kualitas pikiranmu –Marcus Aurelius."
    },
    "2026-05-12": {
        "content": "Kehidupan adalah pengorbanan untuk mencapai kedamaian batin. –Ibn Hazm"
    },
    "2026-05-13": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2026-05-14": {
        "content": "Seseorang tak akan pernah tahu betapa dalam kadar cintanya sampai terjadi sebuah perpisahan. -Kahlil Gibran"
    },
    "2026-05-15": {
        "content": "Tidak ada yang akan berhasil kecuali kau melakukannya. –Maya Angelou"
    },
    "2026-05-16": {
        "content": "Tetap menghadapkan wajah selalu ke arah sinar matahari, dan bayangan akan jatuh di belakangmu –Walt Whitman"
    },
    "2026-05-17": {
        "content": "Satu-satunya cara untuk mencapai yang tidak mungkin adalah percaya bahwa itu mungkin –Charles Kingsleigh"
    },
    "2026-05-18": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2026-05-19": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2026-05-20": {
        "content": "Semakin kau tahu tentang jati diri dan sesuatu yang kau inginkan, semakin sedikit pula kau membiarkan berbagai hal yang membuatmu kesal berlalu –Stephanie Perkins."
    },
    "2026-05-21": {
        "content": "Ingatlah selalu bahwa kamu benar-benar unik. Sama seperti orang lain. –Margaret Mead"
    },
    "2026-05-22": {
        "content": "Tidak masalah seberapa lambat kau berjalan asalkan kau tidak berhenti. -Confucius"
    },
    "2026-05-23": {
        "content": "Diam adalah sumber dari kekuatan yang luar biasa. -Lao Tzu"
    },
    "2026-05-24": {
        "content": "Jangan pernah bermimpi untuk sukses, tapi kerjakan sesuatu untuk meraih kesuksesan –Ester Lauder"
    },
    "2026-05-25": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. -Wayne Huizenga"
    },
    "2026-05-26": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2026-05-27": {
        "content": "Jangan tinggalkan apa pun untuk hari esok yang bisa dilakukan hari ini. -Abraham Lincoln"
    },
    "2026-05-28": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. –John F. Kennedy"
    },
    "2026-05-29": {
        "content": "Cinta tak berupa tatapan satu sama lain, tetapi memandang keluar bersama ke arah yang sama. –B.J. Habibie"
    },
    "2026-05-30": {
        "content": "Berjuang untuk sukses tanpa kerja keras seperti mencoba memanen ketika kamu belum menanam –David Bly"
    },
    "2026-05-31": {
        "content": "Setiap ada kefokusan, di situlah akan ada energi yang akan mengalir –Tony Robbins."
    },
    "2026-06-01": {
        "content": "Pikirkanlah sebelum berbicara, karena perkataan memiliki kekuatan yang besar. –Ali bin Abi Thalib"
    },
    "2026-06-02": {
        "content": "Tidak ada yang tidak mungkin bagi mereka yang mau berusaha –Alexander yang Agung"
    },
    "2026-06-03": {
        "content": "Usaha dan keberanian tidak cukup tanpa tujuan dan arah perencanaan. -John F. Kennedy"
    },
    "2026-06-04": {
        "content": "Kesuksesan biasanya datang pada mereka yang terlalu sibuk untuk mencarinya. -Henry David Thoreau"
    },
    "2026-06-05": {
        "content": "Jika kamu ingin bahagia, tetapkan sasaran yang membangkitkan pikiran, membebaskan energi, dan menginspirasi harapanmu –Andrew Carnegie"
    },
    "2026-06-06": {
        "content": "Jika kamu ingin menjalani hidup yang bahagia, ikatlah dengan tujuan, bukan pada orang atau benda. –Albert Einstein"
    },
    "2026-06-07": {
        "content": "Jalan menuju sukses dan jalan menuju kegagalan hampir persis sama –Colin R. Davis"
    },
    "2026-06-08": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2026-06-09": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2026-06-10": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2026-06-11": {
        "content": "Orang yang meninggal memperoleh lebih banyak bunga dibandingkan orang yang masih hidup karena penyesalan lebih kuat dibandingkan rasa syukur –Anne Frank."
    },
    "2026-06-12": {
        "content": "Rahasia kesuksesan adalah melakukan hal yang biasa dengan sangat baik. -John D. Rockefeller Jr."
    },
    "2026-06-13": {
        "content": "Keyakinan menciptakan fakta yang sebenarnya –William James"
    },
    "2026-06-14": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2026-06-15": {
        "content": "Kita memahami dunia tidak sebagaimana ia adalah, tetapi sebagaimana kita. –Immanuel Kant"
    },
    "2026-06-16": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2026-06-17": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2026-06-18": {
        "content": "Ketika kita memahami diri kita sendiri, kita mendekati pemahaman tentang Tuhan. –Ibn Sina"
    },
    "2026-06-19": {
        "content": "Kamu dapat menipu beberapa orang setiap saat dan semua orang pada suatu waktu, tetapi kamu tidak dapat membodohi semua orang setiap saat. -Abraham Lincol"
    },
    "2026-06-20": {
        "content": "Hubungan asmara itu seperti kaca. Terkadang lebih baik meninggalkannya dalam keadaan pecah. Daripada menyakiti dirimu dengan cara menyatukan mereka kembali. -D.Love"
    },
    "2026-06-21": {
        "content": "Tindakan menyalahkan hanya akan membuang waktu. Sebesar apapun kesalahan yang kamu timpakan ke orang lain, dan sebesar apapun kamu menyalahkannya, hal tersebut tidak akan mengubahmu –Wayne Dyer"
    },
    "2026-06-22": {
        "content": "Tuntutlah ilmu, tapi tidak melupakan ibadah. Kerjakanlah ibadah tapi tidak boleh lupa pada ilmu. –Hassan Al Bashri"
    },
    "2026-06-23": {
        "content": "Kemuliaan terbesar dalam hidup tidak terletak pada tidak pernah jatuh, tetapi dalam bangkit setiap kali kita jatuh. –Nelson Mandela"
    },
    "2026-06-24": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2026-06-25": {
        "content": "Tidak apa-apa merayakan kesuksesan, tapi lebih penting untuk memperhatikan pelajaran tentang kegagalan.  –Bill Gates"
    },
    "2026-06-26": {
        "content": "Tidak ada hal apapun di dunia ini yang lebih berharga dari persahabatan sejati –Thomas Aquinas"
    },
    "2026-06-27": {
        "content": "Tindakan manusia dapat dimodifikasi sampai batas tertentu, tetapi sifat manusia tidak dapat diubah. –Abraham Lincoln"
    },
    "2026-06-28": {
        "content": "Tak ada yang terasa semengerikan dulu saat kau sudah punya teman sejati –Bill Watterson"
    },
    "2026-06-29": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2026-06-30": {
        "content": "Pendidikan adalah senjata paling ampuh untuk mengubah dunia. –Nelson Mandela"
    },
    "2026-07-01": {
        "content": "Bangun dan wujudkan mimpimu atau orang lain akan memperkerjakanmu untuk membangun mimpi mereka –Farrah Gray"
    },
    "2026-07-02": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2026-07-03": {
        "content": "Jauhi orang-orang yang mencoba meremehkan ambisimu. Pikiran kecil akan selalu melakukan itu, tetapi pikiran besar akan memberi perasaan bahwa kamu juga bisa menjadi hebat –Mark Twain"
    },
    "2026-07-04": {
        "content": "Arahkan mata Anda pada bintang-bintang dengan kaki tetap berpijak pada tanah. -Theodore Roosevelt"
    },
    "2026-07-05": {
        "content": "Jika kamu melihat apa yang kamu miliki dalam hidup, kamu akan selalu memiliki lebih banyak. Jika kamu melihat apa yang tidak kamu miliki dalam hidup, kamu tidak akan pernah merasa cukup. –Oprah Winfrey"
    },
    "2026-07-06": {
        "content": "Cinta bukanlah bertahan seberapa lama. Tetapi seberapa jelas dan ke arah mana. –Emha Ainun Nadjib"
    },
    "2026-07-07": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2026-07-08": {
        "content": "Jika kamu benar-benar menginginkan sesuatu, jangan menunggu untuk itu –ajari dirimu untuk tidak sabar –Gurbaksh Chahal"
    },
    "2026-07-09": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2026-07-10": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2026-07-11": {
        "content": "Cinta terjadi begitu singkat, namun melupakan memakan waktu begitu lama. -Pablo Neruda"
    },
    "2026-07-12": {
        "content": "Aku telah belajar jika ada lebih banyak kekuatan di dalam sebuah pelukan yang kuat dibandingkan dengan seribu kata-kata –Ann Hood."
    },
    "2026-07-13": {
        "content": "Dunia ini adalah sebuah cermin bagi pikiran kita sendiri. –Baruch Spinoza"
    },
    "2026-07-14": {
        "content": "Orang yang memiliki sasaran akan menjadi sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2026-07-15": {
        "content": "Doa adalah senjata terbesar umat manusia. –Abu Hamid al-Ghazali"
    },
    "2026-07-16": {
        "content": "Ketahuilah bahwa hidup ini adalah ujian yang sebenarnya. –Ibn Qayyim al-Jawziyya"
    },
    "2026-07-17": {
        "content": "Kesempatan kamu untuk sukses di setiap kondisi selalu dapat diukur oleh seberapa besar kepercayaan kamu pada diri sendiri.–Robert Collier"
    },
    "2026-07-18": {
        "content": "Bekerja keraslah dengan diam. Kesuksesan adalah suaramu –Frank Ocean."
    },
    "2026-07-19": {
        "content": "Hidup adalah perjalanan spiritual menuju kebenaran mutlak. –Rumi"
    },
    "2026-07-20": {
        "content": "Suatu pekerjaan yang paling tak kunjung bisa diselesaikan adalah pekerjaan yang tak kunjung pernah dimulai. –JRR Tolkien"
    },
    "2026-07-21": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2026-07-22": {
        "content": "Memaafkan belum tentu membuat kita lebih baik atau bahkan merasa lebih baik tetapi yang jelas membuka jalan kebaikan.–Eleanor Roosevelt"
    },
    "2026-07-23": {
        "content": "Yang membuatku terus berkembang adalah tujuan-tujuan hidupku. -Muhammad Ali"
    },
    "2026-07-24": {
        "content": "Kegigihan adalah kunci kesuksesan dalam pencapaian tujuan. –Abdul Qadir al-Jilani"
    },
    "2026-07-25": {
        "content": "Hidup adalah rangkaian pelajaran yang harus dijalani untuk dipahami. –Ralph Waldo Emerson"
    },
    "2026-07-26": {
        "content": "Cinta itu layaknya angin, aku tidak bisa melihatnya tetapi aku bisa merasakannya. –Nicholas Sparck"
    },
    "2026-07-27": {
        "content": "Dunia ini cukup untuk memenuhi kebutuhan manusia, bukan untuk memenuhi keserakahan manusia. -Mahatma Gandhi"
    },
    "2026-07-28": {
        "content": "Jangan merusak sesuatu yang kau miliki dengan mengharapkan sesuatu yang tidak akan pernah kau miliki; ingatlah jika sesuatu yang kau miliki saat ini pernah menjadi salah satu hal yang kau harapkan –Epicurus."
    },
    "2026-07-29": {
        "content": "Kesempatan emas yang kau cari ada di dalam dirimu sendiri –Orison Sweet Marden."
    },
    "2026-07-30": {
        "content": "Bagaimana kau mengeja 'cinta'? tanya Piglet.Kau tak usah mengejanya….rasakan saja. Jawab Pooh. -A.A Milne"
    },
    "2026-07-31": {
        "content": "Tidak ada akhir untuk pendidikan. Bukan berarti Anda membaca buku, lulus ujian, dan menyelesaikan pendidikan. Seluruh kehidupan, dari saat Anda lahir hingga saat Anda mati, adalah proses pembelajaran. –Jiddu Krishnamurti"
    },
    "2026-08-01": {
        "content": "Sejauh apapun perpisahan, sesibuk apapun kita nanti, dan meski langkah kita berbeda, waktu tidak akan dapat memisahkan gelar 'sahabat' di antara kita –mnurulnatasha"
    },
    "2026-08-02": {
        "content": "Sebab sahabat itu saling tumbuh, bukan yang hilang ketika satunya jatuh –Boy Candra"
    },
    "2026-08-03": {
        "content": "Kebijaksanaan sejati adalah mengenal diri sendiri. –Ali bin Abi Thalib"
    },
    "2026-08-04": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2026-08-05": {
        "content": "Cinta bukan melepas tapi merelakan Bukan memaksa tapi memperjuangkan Bukan menyerah tapi mengikhlaskan Bukan merantai tapi memberi sayap –Fiersa Besari"
    },
    "2026-08-06": {
        "content": "Sebelum apa pun, persiapan adalah kunci menuju kesuksesan. -Alexander Graham Bell"
    },
    "2026-08-07": {
        "content": "Sahabat adalah keluarga yang Anda pilih –Jess C Scott"
    },
    "2026-08-08": {
        "content": "Satu-satunya batasan untuk meraih mimpi kita adalah keragu-raguan kita akan hari ini. Marilah kita maju dengan keyakinan yang aktif dan kuat –Franklin Roosevelt"
    },
    "2026-08-09": {
        "content": "Gagal itu makanan sehari-hari. Itu biasa, yang penting bagimana kamu menyikapinya. Evaluasi. Bangkit. Gagal lagi? Bangkit lagi!” –Chairul Tanjung"
    },
    "2026-08-10": {
        "content": "Anda tidak bisa pergi dari tanggung jawab esok hari dengan menghindarinya hari ini. -Abraham Lincoln"
    },
    "2026-08-11": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2026-08-12": {
        "content": "Suatu ide yang berguna lebih berharga daripada kekayaan. –Socrates"
    },
    "2026-08-13": {
        "content": "Jika seseorang mengambil tanggung jawab tanpa paksaan, itulah cinta –Radhanath Swami."
    },
    "2026-08-14": {
        "content": "Jangan habiskan waktu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. –Coco Canel"
    },
    "2026-08-15": {
        "content": "Pengetahuan tidak memiliki nilai kecuali jika dipraktekkan. –Thomas Aquinas"
    },
    "2026-08-16": {
        "content": "Pendidikan bukan cuma pergi ke sekolah dan mendapatkan gelar. Tapi, juga soal memperluas pengetahuan dan menyerap ilmu kehidupan –Shakuntala Devi"
    },
    "2026-08-17": {
        "content": "Kesenangan dalam sebuah pekerjaan membuat kesempurnaan pada hasil yang dicapai. -Aristoteles"
    },
    "2026-08-18": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2026-08-19": {
        "content": "Jadilah diri Anda sendiri; semua orang sudah menjadi orang lain. -Oscar Wilde"
    },
    "2026-08-20": {
        "content": "Kebebasan adalah hak setiap individu. –John Locke"
    },
    "2026-08-21": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2026-08-22": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2026-08-23": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2026-08-24": {
        "content": "Orang yang hebat tidak dilahirkan begitu saja, tetapi mereka tumbuh menjadi lebih hebat –Mario Puzo."
    },
    "2026-08-25": {
        "content": "Janganlah menganggap remeh hal-hal yang terdekat dengan hatimu. Rangkullah mereka seperti sama berharganya dengan hidupmu, karena tanpa mereka hidup adalah sia-sia –Peribahasa Cina"
    },
    "2026-08-26": {
        "content": "Jadilah orang yang kuat, tetapi tidak kasar. Bersikaplah baik, tetapi jangan lemah. Bersikaplah rendah hati, tetapi tidak menjadi pemalu. Percaya dirilah, tetapi tidak sombong –Thomas Robert Malthus."
    },
    "2026-08-27": {
        "content": "Kita tidak bisa memecahkan masalah dengan cara berpikir yang kita gunakan ketika kita menemukan masalah itu –Albert Einstein"
    },
    "2026-08-28": {
        "content": "Untuk sukses, sikap sama pentingnya dengan kemampuan. -Walter Scott"
    },
    "2026-08-29": {
        "content": "Seorang pemenang adalah pemimpi yang tidak pernah menyerah –Nelson Mandela"
    },
    "2026-08-30": {
        "content": "Cara untuk memulai adalah dengan berhenti berbicara dan mulai melakukan. -Walt Disney"
    },
    "2026-08-31": {
        "content": "Ketika kesulitan datang, ingatlah bahwa Allah selalu bersamamu. –Jalal ad-Din Rumi"
    },
    "2026-09-01": {
        "content": " Hidup memiliki semua tikungan dan belokan itu. Kamu harus berpegangan erat-erat dan pergilah. –Nicole Kidman"
    },
    "2026-09-02": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2026-09-03": {
        "content": "Beberapa orang akan pergi dari hidupmu, tapi itu bukan akhir dari ceritamu. Itu cuma akhir dari bagian mereka di ceritamu. -Faraaz Kazi"
    },
    "2026-09-04": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2026-09-05": {
        "content": "Cinta bukan mengajar kita lemah, tetapi membangkitkan kekuatan Cinta bukan mengajar kita menghinakan diri, tetapi mengembuskan kegagahan Cinta bukan melemahkan semangat, tetapi membangkitkan semangat –Buya Hamka"
    },
    "2026-09-06": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2026-09-07": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2026-09-08": {
        "content": "Dunia itu seluas langkah kaki. Jelajahilah dan jangan pernah takut melangkah. Hanya dengan itu kita bisa mengerti kehidupan dan menyatu dengannya.–Soe Hok Gie"
    },
    "2026-09-09": {
        "content": "Lebih baik mempunyai kehidupan yang bermakna dan memberikan perbedaan dibandingkan hanya mempunyai umur yang panjang –Bryant H. Mc.Gill."
    },
    "2026-09-10": {
        "content": "Menjadi diri sendiri di dunia yang terus-menerus berusaha menjadikan Anda orang lain adalah pencapaian terbesar. -Ralph Waldo Emerson"
    },
    "2026-09-11": {
        "content": "Pertama kita membentuk kebiasaan dan kebiasaan akan membentuk kita. Kalahkan kebiasaan burumu, atau mereka akan mengalahkanmu dengan mudah –Dr. Rob Gilbert"
    },
    "2026-09-12": {
        "content": "Kebahagiaan sejati hanya dapat ditemukan dalam kebijaksanaan. –Epicurus"
    },
    "2026-09-13": {
        "content": "Kau tidak pernah dilahirkan untuk hidup kalah, tertekan, bersalah, malu, terkutuk, dan tidak layak. Kau dilahirkan untuk menjadi seorang pemenang –Mikhail Sergeyevich Gorbachev."
    },
    "2026-09-14": {
        "content": "Semakin tinggi ilmu seseorang, maka semakin tinggi toleransinya. –Gus Dur"
    },
    "2026-09-15": {
        "content": "Apapun dirimu, jadilah yang terbaik –Abraham Lincoln"
    },
    "2026-09-16": {
        "content": "Kesempurnaan adalah hasil dari usaha dan keteguhan dalam mengejar kebaikan. –Ibn Taymiyyah"
    },
    "2026-09-17": {
        "content": "Kesuksesan bukanlah hal yang final; kegagalan bukanlah hal yang fatal: Yang terpenting adalah keberanian untuk terus maju. -Winston S. Churchill"
    },
    "2026-09-18": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2026-09-19": {
        "content": "Jika kamu menginginkan sesuatu yang belum pernah dimiliki dalam hidupmu. Kamu harus melakukan sesuatu yang belum pernah dilakukan sebelumnya –JD Houston"
    },
    "2026-09-20": {
        "content": "Rahasia dari kesuksesan kita adalah bahwa kita tidak pernah menyerah. –Ilma Mankiller"
    },
    "2026-09-21": {
        "content": "Hanya ketika kita mengambil peluang, ketika hidup kita membaik. Risiko awal dan paling sulit yang perlu kita ambil adalah menjadi jujur. —Walter Anderson"
    },
    "2026-09-22": {
        "content": "Begitu kita menerima batasan kita, kita melampauinya. –Albert Einstein"
    },
    "2026-09-23": {
        "content": "Tujuan dari belajar adalah terus tumbuh Akal tidak sama dengan tubuh, akal terus bertumbuh selama kita hidup –Martimer Adler"
    },
    "2026-09-24": {
        "content": "Cinta itu seperti suatu peperangan. Mudah untuk memulainya, tetapi sangat sulit untuk menyudahinya –H.L. Mencken."
    },
    "2026-09-25": {
        "content": "Jangan menilai setiap hari dengan panen yang kamu tuai, tetapi dengan benih yang kamu tanam. –Robert Louis Stevenson"
    },
    "2026-09-26": {
        "content": "Teman yang baik tak akan membiarkamu melakukan hal-hal bodoh sendirian –Ain Eineziz"
    },
    "2026-09-27": {
        "content": "Cara paling mendasar dan kuat untuk terhubung dengan orang lain adalah dengan mendengarkan. Cukup Dengarkan. Mungkin hal terpenting yang bisa kita berikan kepada orang lain adalah perhatian kita –Rachel Naomi Remen"
    },
    "2026-09-28": {
        "content": "Rasa bahagia dan tak bahagia bukan berasal dari apa yang kamu miliki, bukan pula berasal dari siapa diri kamu, atau apa yang kamu kerjakan. Bahagia dan tak bahagia berasal dari pikiran kamu. –Dale Carnegie"
    },
    "2026-09-29": {
        "content": "Hal-hal terbaik dan terindah di dunia tidak dapat dilihat atau bahkan disentuh –semua itu harus dirasakan dengan hati. -Helen Keller"
    },
    "2026-09-30": {
        "content": "Kesuksesan bersumber dari perbuatan. Orang yang sukses terus melakukan usaha. Orang sukses bahkan membuat kesalahan, namun mereka tidak berhenti usaha –Cobrad Hilton"
    },
    "2026-10-01": {
        "content": "Saya tidak memikirkan semua penderitaan, tetapi keindahan yang masih tersisa. –Anne Frank"
    },
    "2026-10-02": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2026-10-03": {
        "content": "Kebahagiaan kita tergantung kepada diri kita sendiri –Aristoteles."
    },
    "2026-10-04": {
        "content": "Gravitasi tidak bertanggung-jawab atas orang yang jatuh cinta –Albert Einstein"
    },
    "2026-10-05": {
        "content": "Anda mungkin bisa menunda, tapi waktu tidak akan menunggu. –Benjamin Franklin"
    },
    "2026-10-06": {
        "content": "Kegelapan tidak dapat mengusir kegelapan: hanya cahaya yang dapat melakukannya. Kebencian tidak dapat mengusir kebencian: hanya cinta yang dapat melakukannya. -Martin Luther King Jr."
    },
    "2026-10-07": {
        "content": "Kamu tidak bisa kembali dan mengubah awal saat kamu memulainya, tapi kamu bisa memulainya lagi dari di mana kamu berada sekarang dan ubah akhirnya. -C.S Lewis"
    },
    "2026-10-08": {
        "content": "Cinta adalah kebenaran terbesar dan kebaikan tertinggi. –Plato"
    },
    "2026-10-09": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2026-10-10": {
        "content": "Lakukanlah sesuatu yang harus kau lakukan hingga kau tidak dapat melakukan sesuatu yang kau inginkan –Oprah Winfrey."
    },
    "2026-10-11": {
        "content": "Jangan menunggu orang lain membahagiakanmu. Kebahagiaan apa pun yang Anda peroleh, harus Anda ciptakan sendiri. -Alice Walker"
    },
    "2026-10-12": {
        "content": "Kabar buruknya adalah waktu berlalu begitu saja. Kabar baiknya adalah kamu adalah pilotnya. —Michael Altshuler"
    },
    "2026-10-13": {
        "content": "Kedamaian hilang karena kita lupa jika sebenarnya memiliki satu sama lain –Bunda Maria Teresa Bojaxhiu."
    },
    "2026-10-14": {
        "content": "Jangan biarkan ambisimu yang besar menghalangi berbagai pencapaian kecil yang jauh lebih bermakna –Bryant H. Mc.Gill"
    },
    "2026-10-15": {
        "content": "Dia, yang tidak cukup berani untuk mengambil risiko, tidak akan mencapai apa pun dalam hidup. –Muhammad Ali"
    },
    "2026-10-16": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2026-10-17": {
        "content": "Anda hanya hidup sekali, tetapi jika Anda melakukannya dengan benar, sekali saja sudah cukup. -Mae West"
    },
    "2026-10-18": {
        "content": "Jangan habiskan waktumu memukuli dinding dan berharap bisa mengubahnya menjadi pintu. -Coco Canel"
    },
    "2026-10-19": {
        "content": "Tindakan merupakan kunci dasar untuk memperoleh semua kesuksesan –Pablo Picasso."
    },
    "2026-10-20": {
        "content": "Pikiran yang baik dan hati yang baik selalu merupakan kombinasi yang hebat –Nelson Mandela"
    },
    "2026-10-21": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    },
    "2026-10-22": {
        "content": "Tanpa sasaran dan rencana meraihnya, kamu seperti kapal yang berlayar tanpa tujuan –Fitzhugh Dodson"
    },
    "2026-10-23": {
        "content": "Seribu orang tua bisa bermimpi, satu orang pemuda bisa mengubah dunia. -Soekarno"
    },
    "2026-10-24": {
        "content": "Cintai hidup yang kau jalani. Jalani hidup yang kau cintai. –Bob Marley"
    },
    "2026-10-25": {
        "content": "Anda ingin mengetahui siapa diri Anda? Jangan bertanya. Beraksilah! -Thomas Jefferson "
    },
    "2026-10-26": {
        "content": "Tidak ada kata terlambat untuk menjadi dirimu yang seharusnya. –George Eliot"
    },
    "2026-10-27": {
        "content": "Ketika dirimu tidak memperoleh solusi untuk mengatasi permasalahan, itu mungkin bukanlah masalah yang harus diatasi, melainkan kebenaran yang harus diterima –Mao Zedong."
    },
    "2026-10-28": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2026-10-29": {
        "content": "Tujuan hidup adalah untuk hidup dengan bijaksana dan merenung. –Plato"
    },
    "2026-10-30": {
        "content": "Cinta itu seperti angin. Kau tak dapat melihatnya, tapi kau dapat merasakannya. -Nicholas Sparks"
    },
    "2026-10-31": {
        "content": "Aku hanya ingin orang lain mengambil langkah mundur, mengambil napas yang dalam, dan benar-benar mengamati sesuatu dengan cara yang berbeda. Namun, mayoritas orang tidak akan pernah melakukannya –Brian Mc.Knight."
    },
    "2026-11-01": {
        "content": "Keadilan adalah tanda kekuatan dan keberanian. –Ali bin Abi Thalib"
    },
    "2026-11-02": {
        "content": "Anda akan menghadapi banyak kekalahan dalam hidup, tetapi jangan pernah membiarkan diri Anda dikalahkan. -Maya Angelou"
    },
    "2026-11-03": {
        "content": "Ketika saya melepaskan diri saya yang sekarang, saya menjadi apa yang saya inginkan. –Lao Tzu"
    },
    "2026-11-04": {
        "content": "Bukankah kehidupan sendiri adalah bahagia dan sedih? Bahagia karena napas mengalir dan jantung berdetak, sedih karena pikiran diliputi bayang-bayang. –WS Rendra"
    },
    "2026-11-05": {
        "content": "Jadilah diri kamu sendiri. Siapa lagi yang bisa melakukannya lebih baik ketimbang diri kamu sendiri? –Frank J Giblin"
    },
    "2026-11-06": {
        "content": "Penemuan terbesar sepanjang masa adalah bahwa seseorang bisa mengubah masa depannya hanya dengan mengubah sikapnya saat ini. -Oprah Winfrey"
    },
    "2026-11-07": {
        "content": "Hidup itu sebentar. Kamu harus bisa tersenyum saat merasakan kepedihan atau kita tak akan pernah melanjutkan hidup.–Jeff Ross"
    },
    "2026-11-08": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2026-11-09": {
        "content": "Kemuliaan terbesar dalam hidup bukan terletak pada tidak pernah jatuh, tetapi bangkit setiap kali kita jatuh. -Nelson Mandela"
    },
    "2026-11-10": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2026-11-11": {
        "content": "Seseorang pernah bertanya kepadaku,Mengapa kau selalu bersikukuh mengambil jalan tersulit?” Aku menjawabnya,Mengapa kau menganggapku memilih dua jalan?” –John Fitzgerald Kennedy."
    },
    "2026-11-12": {
        "content": "Pikiran kamu bagaikan api yang perlu dinyalakan, bukan bejana yang menanti untuk diisi. –Dorothea Brande"
    },
    "2026-11-13": {
        "content": "Ketika memberikan kegembiraan kepada orang lain, kamu mendapatkan lebih banyak kegembiraan sebagai balasannya. Kamu harus memikirkan kebahagiaan yang bisa kamu berikan–Eleanor Roosevelt"
    },
    "2026-11-14": {
        "content": "Asmara bukan hanya sekadar saling memandang satu sama lain. Tapi, juga sama sama melihat ke satu arah yang sama. -Antoine de Saint-Exupéry"
    },
    "2026-11-15": {
        "content": "Cara terbaik untuk menyiapkan kehidupan adalah memulai hidup –Elbert Hubbard."
    },
    "2026-11-16": {
        "content": "Jika Anda ingin mewujudkan impian Anda, hal pertama yang harus Anda lakukan adalah bangun. -J.M. Power"
    },
    "2026-11-17": {
        "content": "Satu-satunya tempat di mana kesuksesan datang sebelum bekerja adalah di kamus. -Vidal Sassoon"
    },
    "2026-11-18": {
        "content": "Bukan melakukan apa yang kamu sukai, tetapi menyukai apa yang kamu lakukan adalah rahasia kebahagiaan. -JM Barrie"
    },
    "2026-11-19": {
        "content": "Kesabaran adalah kekuatan; kebitteran adalah kelemahan. –Immanuel Kant"
    },
    "2026-11-20": {
        "content": "Beberapa orang memimpikan kesuksesan, sementara yang lain bangun setiap pagi untuk mewujudkannya. –Wayne Huizenga"
    },
    "2026-11-21": {
        "content": "Banyak dari kegagalan hidup adalah karena mereka tidak menyadari betapa dekatnya mereka dengan kesuksesan ketika mereka menyerah. –Thomas A. Edison"
    },
    "2026-11-22": {
        "content": "Aku tidak tahu semua yang mungkin akan datang, tetapi apa pun yang akan terjadi, aku akan melakukannya sambil tertawa –Herman Melville"
    },
    "2026-11-23": {
        "content": "Cobalah untuk tidak menjadi orang yang sukses. Sebaliknya, jadilah orang yang bernilai. -Albert Einstein"
    },
    "2026-11-24": {
        "content": "Cinta selalu saja misterius Jangan diburu-buru, atau kau akan merusak jalan ceritanya sendiri –Tere Liye"
    },
    "2026-11-25": {
        "content": "Ia yang mengerjakan lebih dari apa yang dibayar pada suatu saat akan dibayar lebih dari apa yang ia kerjakan. –Napoleon Hill"
    },
    "2026-11-26": {
        "content": "Kebenaran adalah cahaya yang menghilangkan kegelapan. –al-Ghazali"
    },
    "2026-11-27": {
        "content": "Saya tidak bisa mengubah arah angin, namun saya bisa menyesuaikan pelayaran saya untuk selalu menggapai tujuan saya –Jimmy Dean"
    },
    "2026-11-28": {
        "content": "Jangan biarkan rasa takut kalah lebih besar daripada kegembiraan saat menang –Robert Kiyosaki"
    },
    "2026-11-29": {
        "content": "Dalam hidup ini kita tidak bisa melakukan hal-hal besar. Kita hanya bisa melakukan hal-hal kecil dengan cinta yang besar. -Bunda Teresa"
    },
    "2026-11-30": {
        "content": "Dia yang mengetahui semua jawaban belum pernah memperoleh pertanyaan –Confucius."
    },
    "2026-12-01": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2026-12-02": {
        "content": "Kebanggaan kita yang terbesar adalah bukan tidak pernah gagal, tetapi bangkit kembali setiap kali kita jatuh. –Confusius"
    },
    "2026-12-03": {
        "content": "Berjuang sampai napas terakhir –William Shakespeare"
    },
    "2026-12-04": {
        "content": "Ketika memiliki mimpi, kamu harus meraihnya dan tidak pernah melepaskannya –Carol Burnett"
    },
    "2026-12-05": {
        "content": "Beri nilai dari usahanya jangan dari hasilnya. Baru kita bisa menilai kehidupan. -Albert Einstein"
    },
    "2026-12-06": {
        "content": "Jangan khawatir jika Anda tidak diakui, tetapi berusahalah untuk menjadi layak diakui. -Abraham Lincoln"
    },
    "2026-12-07": {
        "content": "Kebijaksanaan adalah lebih berharga daripada kekayaan. –Heraclitus"
    },
    "2026-12-08": {
        "content": " Orang yang memiliki sasaran sukses karena mereka tahu ke mana akan pergi –Earl Nightingale"
    },
    "2026-12-09": {
        "content": "Sebarkan cinta ke mana pun kamu pergi. Jangan biarkan orang lain mendatangimu tanpa mendapatkan hal yang lebih bahagia. –Bunda Teresa"
    },
    "2026-12-10": {
        "content": "Tiga hal dalam hidup –kesehatanmu, misimu dan orang yang kamu cintai. –Naval Ravikant"
    },
    "2026-12-11": {
        "content": "Jangan tanyakan pada dirimu apa yang dibutuhkan dunia. Bertanyalah apa yang membuat kamu merasa hidup, kemudian kerjakan. Karena yang dibutuhkan dunia adalah orang yang antusias –Harold Whitman"
    },
    "2026-12-12": {
        "content": "Pelajarilah dengan sungguh-sungguh apa yang paling menarik minatmu dengan cara yang paling tidak disiplin, tidak sopan, dan orisinal –Richard Feynmann"
    },
    "2026-12-13": {
        "content": "Saya suka mereka yang bisa tersenyum dalam kesulitan. –Leonardo da Vinci"
    },
    "2026-12-14": {
        "content": "Optimisme adalah iman yang menuntunmu ke pencapaian –Helen Keller"
    },
    "2026-12-15": {
        "content": "Hidup bukanlah untuk menunggu badai berlalu, tetapi belajar menari di tengah hujan –Henry Ford."
    },
    "2026-12-16": {
        "content": "Kita harus berarti untuk diri kita sendiri dulu sebelum kita menjadi orang yang berharga bagi orang lain. -Ralph Waldo Emerson"
    },
    "2026-12-17": {
        "content": "Orang paling kesepian memiliki kepribadian yang baik hati. Orang paling sedih memiliki senyum paling cerah. Orang paling rusak memiliki kebijaksanaan yang luas. Semua itu karena mereka tidak ingin orang lain menderita seperti mereka –Zarathustra."
    },
    "2026-12-18": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2026-12-19": {
        "content": "Tujuan pendidikan itu untuk mempertajam kecerdasan, memperkukuh kemauan serta memperhalus perasaan. –Tan Malaka"
    },
    "2026-12-20": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara. -Shah Rukh Khan"
    },
    "2026-12-21": {
        "content": "Kamu menjadi apa yang kamu yakini. -Oprah Winfrey"
    },
    "2026-12-22": {
        "content": "Dalam tiga kata, saya dapat menyimpulkan semua yang telah saya pelajari tentang kehidupan: hidup terus berjalan. -Robert Frost"
    },
    "2026-12-23": {
        "content": "Orang bijak belajar ketika mereka bisa. Orang bodoh belajar ketika mereka dipaksa. -Arthur Wellesley"
    },
    "2026-12-24": {
        "content": "Kesuksesan dan kegagalan adalah sama-sama bagian dalam hidup. Keduanya hanyalah sementara.–Shah Rukh Khan"
    },
    "2026-12-25": {
        "content": "Jangan takut atas kebenaran, meskipun kebenaran akan menyakitkan. –Ibn Arabi"
    },
    "2026-12-26": {
        "content": "Tiada awan di langit yang tetap selamanya. Tiada mungkin akan terus-menerus terang cuaca. Sehabis malam gelap gulita lahir pagi membawa keindahan. Kehidupan manusia serupa alam. –RA Kartini"
    },
    "2026-12-27": {
        "content": "Belajarlah seolah-olah kamu akan hidup selamanya, hiduplah seolah-olah kamu akan mati besok. –Mahatma Gandhi"
    },
    "2026-12-28": {
        "content": "Jangan katakan tidak punya cukup waktu. Anda memiliki jumlah jam yang persis sama per hari yang diberikan kepada Helen Keller, Pasteur, Michelangelo, Mother Teresa, Leonardo da Vinci, Thomas Jefferson, dan Albert Einstein. –H. Jackson Brown Jr"
    },
    "2026-12-29": {
        "content": "Jangan takut untuk takut. -Maurice Chevalier"
    },
    "2026-12-30": {
        "content": "Orang beriman adalah orang yang berpikir sebelum bertindak. –Hasan al-Banna"
    },
    "2026-12-31": {
        "content": "Seseorang yang berani membuang satu jam waktunya tidak mengetahui nilai dari kehidupan. –Charles Darwin."
    }
}