<template>
    <div class="container">
        <div class="header">
            <!-- <div> -->
            <div class="name">{{ langObj.hello }}</div>
            <div class="todayClick" @click="toToday">{{langObj.today}}</div>
        </div>
        <div class="calendarMonth">
            <div style="display: flex">
                <div class="dropdown" @click="openMonth">
                    <div style="display: flex; margin-bottom: 8px;margin-right: 20px;">
                        <input readonly v-model="selected" class="dropdown-button" />
                        <div class="down-arrow2">
                        </div>
                    </div>
                    <div v-show="isOpenMonth" class="dropdown-menu" id="monthsBox">
                        <button :class="{ active: selected == item.name }" v-for="item in monthEn" :key="item.id"
                            @click="selectMonth(item)" :value="item.id">
                            {{ item.name }}
                        </button>
                    </div>
                </div>
                <div class="dropdown" @click="openYear">
                    <div style="display: flex; margin-bottom: 8px;margin-right: 20px; width: 50%">
                        <input readonly v-model="selectedYear" class="dropdown-button" />
                        <div class="down-arrow2">
                        </div>
                    </div>
                    <div v-show="isOpenYear" class="dropdown-menu" id="yearsBox">
                        <button :class="{ active: Number(selectedYear) == Number(year) }" v-for="year in years"
                            :key="year" :value="year" @click="selectYear(year)">
                            {{ year }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="item" v-for="item in weeksRow" :key="item"
                :class="{ weekend: item.name == 'Sa' || item.name == 'Su' }">{{ item.name }}</div>
            <div class="item" v-for="item in firstDateDay" :key="item"></div>
            <!-- 显示当前的一月 -->
            <div class="item" :style="itemStyle" @click="itemClick(item)"
                :class="{ today: realDate.year == nowYear && realDate.month == nowMonth && realDate.date == item, selected: selectedDate == item, weekend: weekendDays.includes(Number(item)) }"
                v-for="item in days" :key="item">
                {{ item }}
            </div>
        </div>

        <!-- 每日一言 -->
        <div class="props">
            <div style="display: flex; justify-content: space-between">
                <div style="display: flex; justify-content: space-around">
                    <div class="day">
                        {{ parseInt(selectedDate) < 10 && !(/^0\d?$/.test(selectedDate))  ?"0"+selectedDate: selectedDate}}
                    </div>
                    <div class="year">
                        <p>{{ getWeek(selectedWeek) }}</p>
                        <p>{{ getMonth(selectedMonth) }} {{ selectedYear }}</p>
                    </div>
                </div>
                <div class="star">
                    <img style="width: 17px; height: 17px; vertical-align: text-top; margin-right: 5px"
                        :src="getAssetsFile(customColor+star.img)" alt="" />
                    <span style="font-weight: bolder">{{ star.name }}</span>
                </div>
            </div>
            <div class="quotation" v-show="isShow">
                <div class="bg">
                    <p style="font-size: 18px;font-weight: bolder; margin-bottom: 5px; padding-top: 20px">
                        {{langObj.dayword}}</p>
                    <p style="font-size:14px;line-height: 2;">{{ quotation.replace(/"/g, '') }}</p>
                </div>
            </div>

            <div class="holiday" v-show="selectedYear == realDate.year  || selectedYear == realDate.year + 1">
                <p style="margin: 30px 10px 10px 0px;font-weight:bolder">{{langObj.holiday}}</p>
                <p class='holidaySub' style="margin: -9px 10px 16px 0px; font-size: 12px;">{{langObj.holidaySub}}</p>
                <ul>
                    <li class="item" v-if="Object.keys(currentHoliday).length!=0">
                        <p class="name">{{ currentHoliday.name }}</p>
                        <div class="days">
                            <!-- <span style="font-size: 12px;padding-right: 20px;">>>></span> -->
                            <span style="font-size: 12px">{{langObj.today}}</span>
                        </div>
                    </li>
                    <li v-for="(holiday, index) in nearestHolidays" :key="index" class="item">
                        <p class="name">{{ holiday.nextHoliday }}</p>
                        <div class="days">
                            <!-- <span style="font-size: 12px;padding-right: 20px;">>>></span> -->
                            <span
                                style="font-size: 24px;font-weight: bolder;width:48px;display:inline-block;text-align: center;">{{ holiday.minDistance>=10? holiday.minDistance:"0"+holiday.minDistance}}</span>
                            <span
                                style="font-size: 12px;width:23px;display: inline-block">{{ holiday.minDistance==1?langObj.day: langObj.days}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue";
import dayjs from "dayjs";
import { dateYinList, dateXiList, starList, festivalYinList, festivalYinListCurrent, festivalXiList } from "./source";
import { getLang } from "../lang.js"
let langObj = ref({})
const scrollToSelectedOption = (menuSelector, optionValue) => {
    nextTick(() => {
        const menu = document.querySelector(menuSelector);
        const selectedOptionElement = menu.querySelector(`button[value="${optionValue}"]`);
        if (menu && selectedOptionElement) {
            // 计算选中选项相对于菜单顶部的距离
            let topOffset = selectedOptionElement.offsetTop;
            menu.scrollTop = topOffset;
        }
    });
};

// 点击弹窗外部关闭弹窗的函数
function outsideClick(event) {
    if ((isOpenMonth.value || isOpenYear.value) && !Array.from(modalOverlay.value).some((el) => el.contains(event.target))) {
        isOpenMonth.value = false;
        isOpenYear.value = false;
    }
}

const modalOverlay = ref(null);
onUnmounted(() => {
    document.body.removeEventListener("click", outsideClick);
});
// 获取assets静态资源
const getAssetsFile = (url) => {
    return new URL(`/img/${url}`, import.meta.url).href;
};
let isOpenYear = ref(false);
let isOpenMonth = ref(false);
let selected = ref("");
function openYear() {
    isOpenYear.value = !isOpenYear.value;
    if (isOpenYear.value) {
        isOpenMonth.value = false;
        scrollToSelectedOption("#yearsBox", selectedYear.value.toString());
    }
}
function openMonth($event) {
    isOpenMonth.value = !isOpenMonth.value;
    if (isOpenMonth.value) {
        isOpenYear.value = false;
        scrollToSelectedOption("#monthsBox", selectedMonth.value.toString());
    }
}

function getFormatDate() {
    return `${selectedYear.value}-${selectedMonth.value}-${selectedDate.value}`;
}
function selectYear(option) {
    selectedYear.value = option;
    let current = getFormatDate();
    selectedWeek.value = dayjs(current).day();
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
}
function selectMonth(option) {
    selected.value = option.name;
    selectedMonth.value = option.id;
    let current = getFormatDate();
    selectedWeek.value = dayjs(current).day();
    isOpenMonth.value = false;
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
}
const itemStyle = ref({
    height: '',
    lineHeight: '',
});
let quotationList = ref({})
let festivalList = ref([])
let selDay = ''
let customColor = ref("blue")
onMounted(() => {
    // 兼容小部件跳转携带的日期
    const params = new URLSearchParams(window.location.search);
    const paramDate = params.get('date');
    const paramColor = params.get('color');
    const today = dayjs().format("YYYY-MM-DD");
    if (paramDate) {
        selDay = paramDate
        selectedYear.value = new Date(selDay).getFullYear()
        selectedMonth.value = new Date(selDay).getMonth() + 1
        selectedWeek.value = new Date(selDay).getDay()
        selectedDate.value = new Date(selDay).getDate()
    } else {
        selDay = today
    }
    langObj.value = getLang()
    nextTick(() => {
        const height = Math.floor((window.innerWidth * 0.8) / 7) + "px";
        itemStyle.value.height = height
        itemStyle.value.lineHeight = height
    })
    // 样式 
    let colorArr = ['black', 'orange', 'blue']
    if (langObj.value && langObj.value.customColor && colorArr.includes(langObj.value.customColor)) {
        customColor.value = langObj.value.customColor
    } else if (paramColor && colorArr.includes(paramColor)) {
        customColor.value = paramColor
    } else {
        customColor.value = 'blue'
    }
    setTimeout(() => {
        setColor(customColor.value)
    }, 150)
    weeksRow.value = langObj.value.weeksRow
    monthName.value = langObj.value.monthName
    monthEn.value = langObj.value.monthEn
    //  水平7天
    currentWeekDays.value = getWeekDates(selDay);
    // 星座
    star.value = getZodiac(selDay);
    // 节日
    if (langObj.value.lang == "Yin") {
        quotationList.value = dateYinList
        festivalList.value = festivalYinList
    } else if (langObj.value.lang == "Xi") {
        quotationList.value = dateXiList
        festivalList.value = festivalXiList
    }
    currentHoliday.value = getHolidayCurrent(selDay.slice(5, 10))
    nearestHolidays.value = getNextHolidayDistance(selDay, festivalList.value)
    //鸡汤
    quotation.value = getQuotation(selDay, quotationList.value);
    selected.value = getMonthEn(selectedMonth.value);
    // 为每个元素添加点击事件监听器
    document.body.addEventListener("click", outsideClick);
    modalOverlay.value = document.querySelectorAll(".dropdown-button, .down-arrow2");
});
const setColor = (color) => {
    let hearderClass = document.querySelector('.header');
    let containerClass = document.querySelector('.container');
    let todayClickClass = document.querySelector('.todayClick');
    let starClass = document.querySelector('.star');
    let yearClass = document.querySelector('.year');
    let holidaySubClass = document.querySelector('.holidaySub');
    let quatationClass = document.querySelector('.quotation');
    // 获取页面中的第一个<ul>元素
    const ulElement = document.querySelector('ul');
    const liElements = ulElement.querySelectorAll('li');
    // model
    let modelClass = document.querySelector('.calendarMonth');
    let dropdownMenuClass = document.querySelector('.dropdown-menu');
    const buttonElements = document.querySelectorAll('button');

    if (color == 'black') {
        hearderClass.style.setProperty('--block-color', '#333331');
        containerClass.style.setProperty('--bg-color', '#1d1d1d');
        containerClass.style.setProperty('--bg-textColor', '#fff');
        todayClickClass.style.setProperty('--theme-color', '#ffdd39');
        todayClickClass.style.setProperty('--bg-color', '#1d1d1d');
        starClass.style.setProperty('--bg-textColor', '#fff');
        starClass.style.setProperty('--block-color', '#333331');
        quatationClass.style.setProperty('--quatation-color', '#4a4a4a');
        quatationClass.style.setProperty('--quatation-textColor', '#fff');
        quatationClass.style.setProperty('--qua-bg', `url(${getAssetsFile('blackQuaBg.png')})`);
        modelClass.style.setProperty('--block-color', '#333331');
        dropdownMenuClass.style.setProperty('--block-color', '#333331');
        dropdownMenuClass.style.setProperty('--bg-textColor', '#fff');
        yearClass.style.setProperty('--block-color', '#94a3b8');
        holidaySubClass.style.setProperty('--block-color', '#94a3b8');
        Array.from(document.getElementsByClassName('days')).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#fff');
        });
        Array.from(document.getElementsByClassName('selected')).forEach(function (element) {
            element.style.setProperty('--block-color', '#4a4a4a');
        });
        Array.from(liElements).forEach(function (element) {
            element.style.setProperty('--theme-color', '#ffdd39');
            element.style.setProperty('--block-color', '#333331');
        });
        Array.from(buttonElements).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#fff');
            element.style.setProperty('--block-color', '#4a4a4a');
        });
        Array.from(document.querySelectorAll('button.active')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#ffdd39');
        });
        Array.from(document.getElementsByClassName('today')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#ffdd39');
            element.style.setProperty('--block-color', '#333331');
            element.style.setProperty('--theme-bg', '#ffdd39');
            element.style.setProperty('--themeDot-color', '#de496e');
        });
    } else if (color == 'orange') {
        hearderClass.style.setProperty('--block-color', '#f7f7f7');
        containerClass.style.setProperty('--bg-color', '#fff');
        containerClass.style.setProperty('--bg-textColor', '#1E293B');
        todayClickClass.style.setProperty('--theme-color', '#FFA5A526');
        todayClickClass.style.setProperty('--bg-color', '#ED3726');
        starClass.style.setProperty('--bg-textColor', '#ED3726');
        starClass.style.setProperty('--block-color', '#f9f9f9');
        quatationClass.style.setProperty('--quatation-color', '#f9f9f9');
        quatationClass.style.setProperty('--quatation-textColor', '#1D1D1D');
        quatationClass.style.setProperty('--qua-bg', `url(${getAssetsFile('orangeQuaBg.png')})`);
        modelClass.style.setProperty('--block-color', '#fff');
        dropdownMenuClass.style.setProperty('--block-color', '#fff');
        dropdownMenuClass.style.setProperty('--bg-textColor', '#1d1d1d');
        yearClass.style.setProperty('--block-color', '#575a61');
        holidaySubClass.style.setProperty('--block-color', '#575a61');
        Array.from(document.getElementsByClassName('days')).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#ED3726');
        });
        Array.from(document.getElementsByClassName('selected')).forEach(function (element) {
            element.style.setProperty('--block-color', '#f0f0f0');
        });
        Array.from(liElements).forEach(function (element) {
            element.style.setProperty('--theme-color', '#ED3726');
            element.style.setProperty('--block-color', '#f9f9f9');
        });
        Array.from(buttonElements).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#1d1d1d');
            element.style.setProperty('--block-color', '#fff');
        });
        // model-dreopDown
        Array.from(document.querySelectorAll('button.active')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#ED3726');
        });
        Array.from(document.getElementsByClassName('today')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#FFA5A526');
            element.style.setProperty('--block-color', '#ED3726');
            element.style.setProperty('--theme-bg', '#FFA5A526');
            element.style.setProperty('--themeDot-color', '#de496e');
        });
    } else if (color == 'blue') {
        hearderClass.style.setProperty('--block-color', '#f0f2fa');
        containerClass.style.setProperty('--bg-color', '#fff');
        containerClass.style.setProperty('--bg-textColor', '#1E293B');
        todayClickClass.style.setProperty('--theme-color', '#dfe4f1');
        todayClickClass.style.setProperty('--bg-color', '#428BFF');
        starClass.style.setProperty('--bg-textColor', '#428BFF');
        starClass.style.setProperty('--block-color', '#f9f9f9');
        quatationClass.style.setProperty('--quatation-color', '#f0f2fa');
        quatationClass.style.setProperty('--quatation-textColor', '#1D1D1D');
        quatationClass.style.setProperty('--qua-bg', `url(${getAssetsFile('blueQuaBg.png')})`);
        modelClass.style.setProperty('--block-color', '#fff');
        dropdownMenuClass.style.setProperty('--block-color', '#fff');
        dropdownMenuClass.style.setProperty('--bg-textColor', '#1d1d1d');
        // confirmlClass.style.setProperty('--theme-color', '#428BFF');
        // confirmlClass.style.setProperty('--block-color', '#fff');
        yearClass.style.setProperty('--block-color', '#575a61');
        holidaySubClass.style.setProperty('--block-color', '#575a61');
        Array.from(document.getElementsByClassName('days')).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#428BFF');
        });
        Array.from(document.getElementsByClassName('selected')).forEach(function (element) {
            element.style.setProperty('--block-color', '#f0f0f0');
        });
        // days
        Array.from(liElements).forEach(function (element) {
            element.style.setProperty('--theme-color', '#428BFF');
            element.style.setProperty('--block-color', '#f0f2fa');
        });
        Array.from(buttonElements).forEach(function (element) {
            element.style.setProperty('--bg-textColor', '#1d1d1d');
            element.style.setProperty('--block-color', '#fff');
        });
        // model-dreopDown
        Array.from(document.querySelectorAll('button.active')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#428BFF');
        });
        Array.from(document.getElementsByClassName('today')).forEach(function (element) {
            element.style.setProperty('--theme-color', '#428BFF');
            element.style.setProperty('--block-color', '#fff');
            element.style.setProperty('--theme-bg', '#428BFF');
            element.style.setProperty('--themeDot-color', '#fff');
        });
    }
}
const weekendDays = computed(() => {
    const daysInMonth = new Date(selectedYear.value, selectedMonth.value - 1, 0).getDate();
    const weekends = [];
    for (let day = 1; day <= daysInMonth; day++) {
        const dayOfWeek = new Date(selectedYear.value, selectedMonth.value - 1, day).getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            // 周六或周日
            weekends.push(day);
        }
    }
    return weekends;
});
let dialog = ref(false);
const closeDialog = () => {
    dialog.value = false;
    isOpenMonth.value = false;
    isOpenYear.value = false;
    document.body.style.overflow = "";
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
};
let weeksRow = ref([]);
let monthName = ref([])
const monthEn = ref([])

function getWeek(id) {
    let week = "";
    weeksRow.value.forEach((item) => {
        if (id == item.id) {
            week = item.name;
        }
    });
    return week;
}
function getMonth(id) {
    let month = "";
    monthName.value.forEach((item) => {
        if (id == item.id) {
            month = item.name;
        }
    });
    return month;
}
function getMonthEn(id) {
    let month = "";
    monthEn.value.forEach((item) => {
        if (id == item.id) {
            month = item.name;
        }
    });
    return month;
}
//鸡汤
let quotation = ref("");
function getQuotation(date, dateList) {
    let quotation = "";
    for (const day in dateList) {
        if (day == date) {
            quotation = dateList[date].content;
        }
    }
    return quotation;
}
// 星座
const star = ref({});
function getZodiac(date) {
    const monthDay = date.slice(5); // 从日期字符串中提取 "MM-DD"
    for (const zodiac of starList) {
        let star = {};
        if (monthDay >= zodiac.start && monthDay <= zodiac.end) {
            if (langObj.value.lang == "Yin") {
                if (zodiac.nameYin == "Capricorn1" || zodiac.nameYin == "Capricorn2") {
                    star.name = "Capricorn"
                    star.img = zodiac.img
                    return star;
                }
                star.name = zodiac.nameYin
                star.img = zodiac.img
                return star;
            }
            if (langObj.value.lang == "Xi") {
                if (zodiac.nameXi == "Capricornio1" || zodiac.nameXi == "Capricornio2") {
                    star.name = "Capricornio"
                    star.img = zodiac.img
                    return star;
                }
                star.name = zodiac.nameXi
                star.img = zodiac.img
                return star;
            }
        }
    }
}
// 获取当天节日
let currentHoliday = ref("")
function getHolidayCurrent(date) {
    // 所选日期，如果过去了，显示距离今天,
    // const selectedDate = new Date(`${realDate.year}-${date}`);
    const selectedDate = new Date(`${selectedYear.value}-${date}`);
    // 获取当前日期
    const now = new Date();
    // 检查所选日期是否已经过去
    let obj = {}
    if (dayjs(selectedDate).format("YYYY-MM-DD") >= dayjs(now).format("YYYY-MM-DD")) {
        if (langObj.value.lang == "Yin") {
            festivalYinListCurrent.forEach(item => {
                if (item.date == date) {
                    obj = item
                }
            })
        } else if (langObj.value.lang == "Xi") {
            festivalList.value.forEach(item => {
                if (item.date == date) {
                    obj = item
                }
            })
        }

    }
    return obj
}
// 距离下一个节日
function isDatePassed(selectedDate) {
    const currentDate = new Date();
    const selected = new Date(selectedDate);

    // 确保所选日期不在未来
    if (selected > currentDate) {
        return false;
    }
    return true;
}
let nearestHolidays = ref([]);
let daySel = ref(""); // 判断距离这天
function getNextHolidayDistance(date, holidays) {
    // 2024 所选日期，如果过去了，显示距离今天,
    const isPass = isDatePassed(dayjs(date).format("YYYY-MM-DD"));
    if (isPass) {
        daySel.value = dayjs().format("YYYY-MM-DD");
    } else {
        daySel.value = dayjs(date).format("YYYY-MM-DD");
    }
    const holidayRealYearDistances = holidays
        .map((holiday) => {
            let obj = {};
            const day = realDate.year + "-" + holiday.date;
            const holidayDate = dayjs(`${realDate.year}-${holiday.date}`);
            const distance = holidayDate.diff(daySel.value, "day");
            obj = {
                holiday,
                day,
                distance: distance > 0 ? distance : Infinity,
            };
            return obj;
        })
        .sort((a, b) => a.distance - b.distance);
    // 2025
    const holidayRealYearAdd1Distances = holidays
        .map((holiday) => {
            let obj = {};
            const day = realDate.year + 1 + "-" + holiday.date;
            const holidayDate = dayjs(`${realDate.year + 1}-${holiday.date}`);
            const distance = holidayDate.diff(daySel.value, "day");
            obj = {
                holiday,
                day,
                distance: distance > 0 ? distance : Infinity,
            };
            return obj;
        })
        .sort((a, b) => a.distance - b.distance);
    const holidayDistances = holidayRealYearDistances
        .concat(holidayRealYearAdd1Distances)
        .filter((item) => item.distance > 0 && item.distance != Infinity)
        .sort((a, b) => a.distance - b.distance);
    if (isPass) {
        return holidayDistances.slice(0, 4).map((item) => ({
            nextHoliday: item.holiday.name,
            minDistance: item.distance + Math.abs(dayjs(date).diff(daySel.value, "day")),
            day: item.day,
        }))
    } else {
        return holidayDistances.slice(0, 4).map((item) => ({
            nextHoliday: item.holiday.name,
            minDistance: item.distance,
            day: item.day,
        }))
    }
}
let now = ref(new Date());
const selectedYear = ref(now.value.getFullYear());
const selectedMonth = ref(now.value.getMonth() + 1);
const selectedDate = ref(now.value.getDate()); // 当前选中的日期
const selectedWeek = ref(now.value.getDay()); // 当前选中的日期周几
const isCollapsed = ref(true); // 默认为收起状态
const isShow = ref(true); // 默认为收起状态
const currentWeekDays = ref([]); // 日期所在水平7天
// 获取日期所在水平7天
function getWeekDates(selectedDate) {
    // 将字符串日期转换为 dayjs 对象
    const date = dayjs(selectedDate);
    // 获取所选日期是星期几（0-6），dayjs 中 0 是周日
    const weekday = date.day();
    // const checkedMonth = date.month();
    // 获取所选日期所在周的第一天（周日）和最后一天（周六）
    const startOfWeek = date.subtract(weekday, "day");
    const endOfWeek = startOfWeek.add(6, "day");
    const weekDates = [];
    // 生成所选日期所在周的所有日期
    for (let i = 0; i < 7; i++) {
        const day = startOfWeek.add(i, "day").format("DD");
        const mm = startOfWeek.add(i, "day").format("MM");
        const yy = startOfWeek.add(i, "day").format("YYYY");
        weekDates.push({
            day,
            year: yy,
            month: mm,
        });
    }
    // console.log(weekDates, "weekDates")
    return weekDates;
}

const years = computed(() => {
    const startYear = new Date().getFullYear() - 10; // 假设年份范围为过去100年
    const endYear = new Date().getFullYear() + 10;
    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
});
//  这个月的第一天是周几
const firstDateDay = computed(() => {
    return new Date(selectedYear.value, selectedMonth.value - 1, 1).getDay();
});
//  当前的月份有多少天
const days = computed(() => {
    return new Date(selectedYear.value, selectedMonth.value, 0).getDate();
});
// 监听选中的年份和月份，当它们变化时更新日历
watch([selectedYear, selectedMonth], () => {
    if (!selectedDate.value) {
        selectedDate.value = now.value.getDate(); // 更新选中的日期为当前月份的日期
    }
    if (selectedDate.value == 31 && new Date(selectedYear.value, selectedMonth.value, 0).getDate() < 31) {
        now.value = new Date(selectedYear.value, selectedMonth.value - 1, new Date(selectedYear.value, selectedMonth.value, 0).getDate());
    } else {
        now.value = new Date(selectedYear.value, selectedMonth.value - 1, selectedDate.value);
    }
    // now.value = new Date(`${selectedYear.value}-${selectedMonth.value}-${selectedDate.value}`);
    console.log(now.value, "now.value")
    currentWeekDays.value = getWeekDates(dayjs(now.value).format('YYYY-MM-DD'));
    if (selectedYear.value <= realDate.year + 1 && selectedYear.value >= realDate.year - 1) {
        isShow.value = true
        star.value = getZodiac(dayjs(now.value).format('YYYY-MM-DD'))
        currentHoliday.value = getHolidayCurrent(dayjs(now.value).format('YYYY-MM-DD').slice(5, 10))
        nearestHolidays.value = getNextHolidayDistance(dayjs(now.value).format('YYYY-MM-DD'), festivalList.value)
        quotation.value = getQuotation(dayjs(now.value).format('YYYY-MM-DD'), quotationList.value)
    } else {
        isShow.value = false;
        star.value = getZodiac(dayjs(now.value).format("YYYY-MM-DD"));
    }
});

// 今天的年月日
const realDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    date: new Date().getDate(),
    weekday: new Date().getDay(),
};
// 实时计算当前页面显示的年份
const nowYear = computed(() => {
    return now.value.getFullYear();
});
// 实时计算当前页面显示的周几
const nowWeek = computed(() => {
    return now.value.getDay();
});
// 实时计算当前页面显示的月份
const nowMonth = computed(() => {
    return now.value.getMonth() + 1;
});

const itemClick = (item, month = "", yy = "") => {
    selectedDate.value = item; // 更新选中的日期
    let current = null;
    if (month && yy) {
        selectedMonth.value = month;
        selected.value = getMonthEn(month);
        selectedYear.value = yy;
        current = dayjs(yy + "-" + month + "-" + item).format("YYYY-MM-DD");
    } else {
        current = dayjs(now.value.getFullYear() + "-" + (now.value.getMonth() + 1) + "-" + item).format("YYYY-MM-DD");
    }
    selectedWeek.value = dayjs(current).day();
    currentWeekDays.value = getWeekDates(current);
    star.value = getZodiac(current)
    currentHoliday.value = getHolidayCurrent(current.slice(5, 10))
    nearestHolidays.value = getNextHolidayDistance(current, festivalList.value)
    quotation.value = getQuotation(current, quotationList.value)
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
    console.log(current, "selectedDate.value", selectedDate.value);
};
const changeCollpse = () => {
    isCollapsed.value = !isCollapsed.value
    // dialog.value = true;
    // document.body.style.overflow = "hidden";
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
};

// 回到今天
const toToday = () => {
    now.value = new Date();
    currentWeekDays.value = getWeekDates(dayjs().format('YYYY-MM-DD'));
    selectedYear.value = now.value.getFullYear()
    selectedMonth.value = now.value.getMonth() + 1
    selectedWeek.value = now.value.getDay()
    selectedDate.value = now.value.getDate()
    setTimeout(() => {
        setColor(customColor.value)
    }, 100)
};
</script>

<style>
:root {
  --bg-color: #fff
  --bg-textColor:#1d1d1d
  --theme-color:#D73122
  --theme-bg:#f9ebeb
  --icon-color:#D73122
  --block-color:#f7f7f7
--quatation-color:#f9f9f9;
--themeDot-color:#D73122
--qua-bg:url("/img/blackQuaBg.png")
--quatation-textColor:#D73122
}
.weekend {
    color: #ff3b30;
}
.dropdown {
    position: relative;
}
.confirm{
    background-color:var(--theme-color);
    color:var(--block-color)
}

.dropdown-button {
    border: 1px solid #ccc;
    padding: 10px;
    /* cursor: pointer; */
    border: none;
    outline: none;
    width: 65%;
    font-size: 18px;
    font-weight: bolder;
    background-color:var(--block-color);
    color:var(--bg-textColor)
}

.dropdown-menu {
    position: absolute;
    top: 80%;
    left: 0;
    background-color:var(--block-color);
    /* background-color: #fff; */

    /* border: 1px solid #ccc; */
    border-top: none;
    max-height: 250px;
    overflow-y: auto;
    z-index: 9999;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 1px 0px 2px rgba(0, 0, 0, 0.1), -1px 3px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu button {
    background-color:var(--block-color);
    color:var(--bg-textColor);
    white-space: nowrap;
    padding: 10px;
    /* cursor: pointer; */
    width: 100%;
    border: none;
    z-index: 99999999;
    font-size: 16px;
}
.dropdown-menu button.active {
    white-space: nowrap;
    padding: 10px;
    /* cursor: pointer; */
    width: 100%;
    border: none;
    z-index: 99999999;
    font-size: 16px;
    /* background-color:var(--block-color); */
    color:var(--theme-color);
}
.dropdown-menu button:active {
    white-space: nowrap;
    padding: 10px;
    /* cursor: pointer; */
    width: 100%;
    border: none;
    background: #de496e;
    color: #fff;
    z-index: 99999999;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}
.props {
    padding: 10px 20px 20px;
}
.props .day {
    font-size: 42px;
    line-height: 42px;
    margin-right: 10px;
    font-weight: bolder;
}
.props .year {
    color: var(--block-color);
}
.props .quotation {
    min-height: 150px;
    /* border-radius: 15px; */
    /* padding: 15px 20px; */
    font-size: 14px;

    position: relative;
    /* z-index: 10; */
}
.props .quotation:before {
    content: " ";
    min-height: 150px;
    border-radius: 10px;
    /* padding: 15px 20px; */
    font-size: 14px;
    color: var(--quatation-textColor);
    background-color: var(--quatation-color);
    background-image: url("/img/bgLine.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
}
.props .quotation .bg {
    min-height: 150px;
    position: inherit;
    z-index: 10;
    margin: 0px 20px;
    padding-bottom: 20px;
}
.props .quotation:after {
    content: " ";
    position: absolute;
    top: 10px;
    width: 96%;
    left: 50%;
    transform: translateX(-50%);
    background-image: var(--qua-bg);
    background-repeat: no-repeat;
    /* background-size: cover; */
    height: 100%;
    border-radius: 10px;
    z-index: 0;
}
.props .star {
    /* color: #7360f0;
    background: #ededed; */
    color:var(--bg-textColor);
    background:var(--block-color);
    line-height: 35px;
    padding: 1px 14px;
    margin-bottom: 10px;
    border-radius: 8px;
}
.props .holiday .item {
    display: flex;
    justify-content: space-between;
    /* background: #ededed; */
    padding: 15px;
    margin-bottom: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
ul > li {
    border-left: 3px solid var(--theme-color);
    background: var(--block-color);
}
/* ul > li:nth-child(1) {
    border-left: 3px solid #c44efb;
}
ul > li:nth-child(2) {
    border-left: 3px solid #4ad2c9;
}
ul > li:nth-child(3) {
    border-left: 3px solid #536eff;
}
ul > li:nth-child(4) {
    border-left: 3px solid #ffc374;
}
ul > li:nth-child(5) {
    border-left: 3px solid #de496e;
} */
.props .holiday .item .days {
    color: var(--bg-textColor);
}
.props .holiday .holidaySub{
    color:var(--block-color)
}
.container .calendar .item.selected {
    background-color: var(--block-color);
}
.select select {
    margin: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.select select option {
    font-size: 12px;
}
.container .header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0px;
    background-color:var(--block-color);
    /* background-image: url("/img/bg.png");
    background-repeat: no-repeat;
    background-size: cover; */
}
.container .header .name {
    font-size: 26px;
    font-weight: bolder;
}
.container .header .todayClick {
    font-size: 14px;
    color: var(--bg-color);
    padding: 6px 10px;
    border-radius: 6px;
    background: var(--theme-color);
}
.container .header .title {
    font-size: 26px;
    font-weight: 600;
}
.container .header .btns button {
    height: 26px;
    border-radius: 0;
    margin: 0 2px;
    /* cursor: pointer; */
    border-width: 1px;
}
.container .calendar {
    background:var(--bg-color);
    display: flex;
    flex-wrap: wrap;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px 10px 0px;
    margin-top: -10px;
}

.container .calendar .item {
    width: calc(95% / 7);
    height: 40px;
    text-align: center;
    font-weight: bolder;
    /* cursor: pointer; */
    padding-top: 15px;
    font-size: 18px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    border-radius: 10px;
    position: relative;
    margin-right: 2px;
}

.container .calendar .itemWeek {
    width: calc(98% / 7);
    height: 22px;
    text-align: center;
    font-size: 14px;
    color: #94a3b8;
    /* cursor: pointer; */
    /* padding-bottom: 15px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.container .calendar .today::after {
    content: "";
    position: absolute;
    top: 80%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: var(--themeDot-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
.container .calendar .today {
    font-weight: 600;
    /* color: #de496e !important;
    background: #fff0f0 !important; */
    background: var(--theme-color) !important;
    color: var(--block-color) !important;
}
.container .calendar .todayWeek {
    font-weight: 600;
    /* color: #de496e;
    background: #fff0f0; */
    background: var(--theme-bg) !important;
    color: var(--theme-color) !important;
}

.container .calendarMonth {
    /* width: 84%; */
    background: var(--block-color);
    display: flex;
    flex-wrap: wrap;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: -10px;
    padding: 8px 20px 0px;
}

.container .calendarMonth .item {
    width: calc(98% / 7);
    height: 37px;
    text-align: center;
    font-size: 18px;
    position: relative;
}
.container .calendarMonth .itemWeek {
    width: calc(98% / 7);
    height: 40px;
    text-align: center;
    /* cursor: pointer; */
    padding-top: 15px;
    font-size: 18px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    border-radius: 10px;
    position: relative;
    color: #94a3b8;
}

.container .calendarMonth .today {
    font-weight: 600;
    background: var(--theme-bg) !important;
    color: var(--block-color) !important;
    border-radius: 10px;
}
.container .calendarMonth .today::after {
    content: "";
    position: absolute;
    top: 80%;
    left: 50%;
    width: 4px;
    height: 4px;
    background-color: var(--themeDot-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
.container .calendarMonth .selected {
    background-color: var(--block-color);
    border-radius: 10px;
}
.container {
    width: 100%;
    min-height:1000px;
    background:var(--bg-color);
    color:var(--bg-textColor)
}
.down-arrow {
    text-align: center;
    margin-left: -20px;
    color: #ededed;
    font-size: 16px;
}
.down-arrow img {
    height: 10px;
    width: 16px;
}

.down-arrow2 {
    display: inline-block;
    position: relative;
}
.down-arrow2::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #d9d9d9;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
}

/* 仅针对移动端设备 */
@media screen and (max-width: 768px) {
    select option {
        font-size: 18px !important; /* 移动端字体大小 */
    }
}
</style>
