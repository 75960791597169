const langList = {
    Yin: {
        lang: "Yin",
        hello: "Apa kabar",
        today: "Hari ini",
        dayword: "Sepatah kata sehari",
        holiday: "Hari libur",
        holidaySub: "Liburan masih jauh..",
        confirm: "Yakin",
        day: "hari",
        days: "hari",
        weeksRow: [
            { id: 0, name: "Min" },
            { id: 1, name: "Sen" },
            { id: 2, name: "Sel" },
            { id: 3, name: "Rab" },
            { id: 4, name: "Kam" },
            { id: 5, name: "Jum" },
            { id: 6, name: "Sab" },
        ],
        monthName: [
            { id: 1, name: "Januari" },
            { id: 2, name: "Februari" },
            { id: 3, name: "Maret" },
            { id: 4, name: "April" },
            { id: 5, name: "Mei" },
            { id: 6, name: "Juni" },
            { id: 7, name: "Juli" },
            { id: 8, name: "Agustus" },
            { id: 9, name: "September" },
            { id: 10, name: "Oktober" },
            { id: 11, name: "November" },
            { id: 12, name: "Desember" },
        ],
        monthEn: [
            { id: 1, name: "Januari" },
            { id: 2, name: "Februari" },
            { id: 3, name: "Maret" },
            { id: 4, name: "April" },
            { id: 5, name: "Mei" },
            { id: 6, name: "Juni" },
            { id: 7, name: "Juli" },
            { id: 8, name: "Agustus" },
            { id: 9, name: "September" },
            { id: 10, name: "Oktober" },
            { id: 11, name: "November" },
            { id: 12, name: "Desember" },
        ]
    },
    Xi: {
        lang: "Xi",
        hello: "¿Cómo estás?",
        today: "Hoy",
        dayword: "Frase del día",
        holiday: "Días festivos",
        holidaySub: "Las vacaciones aún están lejos...",
        confirm: "Aceptar",
        day: "día",
        days: "días",
        weeksRow: [
            { id: 0, name: "Dom" },
            { id: 1, name: "Lun" },
            { id: 2, name: "Mar" },
            { id: 3, name: "Mié" },
            { id: 4, name: "Jue" },
            { id: 5, name: "Vie" },
            { id: 6, name: "Sáb" },
        ],
        monthName: [
            { id: 1, name: "Enero" },
            { id: 2, name: "Febrero" },
            { id: 3, name: "Marzo" },
            { id: 4, name: "April" },
            { id: 5, name: "Mayo" },
            { id: 6, name: "Junio" },
            { id: 7, name: "Julio" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Septiembre" },
            { id: 10, name: "Octubre" },
            { id: 11, name: "Noviembre" },
            { id: 12, name: "Diciembre" },
        ],
        monthEn: [
            { id: 1, name: "Enero" },
            { id: 2, name: "Febrero" },
            { id: 3, name: "Marzo" },
            { id: 4, name: "April" },
            { id: 5, name: "Mayo" },
            { id: 6, name: "Junio" },
            { id: 7, name: "Julio" },
            { id: 8, name: "Agosto" },
            { id: 9, name: "Septiembre" },
            { id: 10, name: "Octubre" },
            { id: 11, name: "Noviembre" },
            { id: 12, name: "Diciembre" },
        ]
    },
}
export const getLang = () => {
    let obj = langList['Yin']
    let customLang = ''
    let customColor = ""
    let customArr = import.meta.env && import.meta.env.VITE_CUSTOM_ARR.split(",")
    customArr.forEach(item => {
        if (item.includes("VITE_CAN_COLOR")) {
            customColor = item.split("=")?.[1]
            obj.customColor = customColor
        }

        if (item.includes("VITE_CUSTOM_LANG")) {
            customLang = item.split("=")?.[1]
        }
    });
    if (customLang) {
        obj = langList[customLang]
    }
    console.log("star", obj)
    return obj

}